import * as React from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { useTranslation } from "react-i18next";

import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import dayjs from "dayjs";
import HorizontalRule from "@mui/icons-material/HorizontalRule";
import utc from "dayjs/plugin/utc";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTheme } from "@emotion/react";
import PropTypes, { array } from "prop-types";

dayjs.extend(utc);

const operatorOption = (type, axis) => {
  switch (type) {
    case "String":
      return [
        { label: "=", value: 7 },
        { label: "!=", value: 8 },
        { label: "in", value: 14 },
        { label: "like", value: 13 },
      ];
    case "Number":
      return [
        { label: ">", value: 9 },
        { label: "<", value: 10 },
        { label: "=", value: 7 },
        { label: "<=", value: 11 },
        { label: ">=", value: 12 },
        { label: "between", value: 15 },
        { label: "in", value: 14 },
        { label: "!=", value: 8 },
      ];
    case "Date":
      return [
        { label: ">", value: 9 },
        { label: "<", value: 10 },
        // { label: "=", value: 7 },
        { label: "between", value: 15 },
        { label: "<=", value: 11 },
        { label: ">=", value: 12 },
      ];
    default:
      return [
        { label: "=", value: 7 },
        { label: "in", value: 14 },
        { label: "like", value: 13 },
      ];
  }
};

export const DashboardCriteriaComboBox = React.forwardRef((props, ref) => {
  const {
    parentFilterCriteria,
    rowsPerPage,
    dropdownDataObj,
    axis,
    GetAxisData,
  } = props;
  const { t } = useTranslation();
  const [availablePage, setAvailablePage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState({});
  const [filterCriteria, setFilterCriteria] = React.useState(
    parentFilterCriteria ?? JSON.stringify({ [""]: { [""]: [""] } })
  );
  const [criteriaRows, setCriteriaRows] = React.useState({
    "": [""],
  });
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const timezone = dayjs.tz.guess();

  const handleCriteriaChange = (value, xy, index) => {
    let arr = criteriaRows[xy];
    let input = null;
    let input2 = null;
    let type = GetAxisData("DataType", xy, null);
    switch (type) {
      case "Date":
        if (value != null) {
          if (Object.keys(JSON.parse(filterCriteria)[xy])[0] == 15) {
            input = dayjs(value).startOf("date");
            input2 = dayjs(value).endOf("date");
            if (index == 0) {
              input = dayjs(value).startOf("day");

              arr[index] = input;
            } else if (index == 1) {
              input = dayjs(value).endOf("day");
            }
          } else {
            input =
              Object.keys(JSON.parse(filterCriteria)[xy])[0] == 9 ||
              Object.keys(JSON.parse(filterCriteria)[xy])[0] == 12
                ? dayjs(value).endOf("date")
                : Object.keys(JSON.parse(filterCriteria)[xy])[0] == 10 ||
                  Object.keys(JSON.parse(filterCriteria)[xy])[0] == 11
                ? dayjs(value).startOf("date")
                : dayjs(value);
          }
        } else {
          input = "";
        }
        break;
      case "String":
        input = value;
        break;
      case "Number":
        input = parseInt(value);
        break;
    }
    arr[index] = input;
    let rows = criteriaRows;
    rows[xy] = arr;
    setCriteriaRows(rows);

    let obj = {
      [Object.keys(JSON.parse(filterCriteria)[xy])[0] == 7 && type === "Date"
        ? 15
        : Object.keys(JSON.parse(filterCriteria)[xy])[0]]: arr,
    };

    let filterCriteriaObj = JSON.parse(filterCriteria);
    filterCriteriaObj[xy] = obj;
    setFilterCriteria(JSON.stringify(filterCriteriaObj));
  };

  const handleAddRow = (xy) => {
    let arr = criteriaRows[xy];
    arr = [...arr, ""];
    setCriteriaRows({
      ...criteriaRows,
      [xy]: arr,
    });
    if (criteriaRows[xy].length >= availablePage[xy] * rowsPerPage) {
      setAvailablePage({
        ...availablePage,
        [xy]: availablePage[xy] + 1,
      });
    }
  };

  const handleDeleteRow = (index, xy) => {
    if (criteriaRows[xy].length > 1) {
      const tempRow = [...criteriaRows[xy]];
      tempRow.splice(index, 1);
      // setXCriteriaRows(tempRow);
      setCriteriaRows({
        ...criteriaRows,
        [xy]: tempRow,
      });
    }
  };

  const handleFilterCriteriaOperatorChange = (value, key) => {
    let criteriaObj = {};
    if (value != "none") {
      key = key === 999 ? 15 : key;
      if (JSON.parse(filterCriteria)[key] != "") {
        criteriaObj[value] = Object.values(JSON.parse(filterCriteria)[key])[0];
      }
      setCriteriaRows({
        ...criteriaRows,
        [key]: value === 15 ? ["", ""] : [""],
      });
      let filterCriteriaObj = JSON.parse(filterCriteria);
      filterCriteriaObj[key] = criteriaObj;
      setFilterCriteria(JSON.stringify(filterCriteriaObj));
    }
  };

  const handleFilterCriteriaKeyChange = (value, key, index) => {
    let keyArr = Object.keys(JSON.parse(filterCriteria));
    let newObj = {};
    let criteriaRowObj = {};
    if (value != "none") {
      keyArr.splice(index, 1, value);
      keyArr.push("");
    } else {
      if (index > 1 || (index === 0 && keyArr.length > 1)) {
        keyArr.splice(index, 1);
      } else {
        keyArr[index] = "";
      }
    }
    keyArr.forEach((k) => {
      newObj[k] = JSON.parse(filterCriteria)[k] ?? { [""]: [""] };
      criteriaRowObj[k] = criteriaRows[k] ?? [""];
    });

    console.log(criteriaRows);
    setFilterCriteria(JSON.stringify(newObj));
    setCriteriaRows(criteriaRowObj);
    setCurrentPage({
      ...currentPage,
      [value]: 1,
    });
    setAvailablePage({
      ...availablePage,
      [value]: 1,
    });
  };

  const CriteriaField = (columnName, ope, type) => {
    let typeForSwitch = type;
    if (
      columnName.toLowerCase() === "decision" ||
      columnName.toLowerCase() === "milestone" ||
      columnName.toLowerCase() === "case initiator"
    ) {
      typeForSwitch = "ExtraData";
    }
    let operator = parseInt(ope);
    const textOperatorType = () => {
      switch (operator) {
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
          return textField(type, 0);
        case 15:
          return (
            <Grid container item direction={"column"}>
              <Grid item>{textField(type, 0)}</Grid>
              <Grid item>
                <HorizontalRule />
              </Grid>
              <Grid item>{textField(type, 1)}</Grid>
            </Grid>
          );
        case 14:
          return (
            <>
              {(criteriaRows[columnName] ?? []).map((item, i) => (
                <Grid container key={i} spacing={1}>
                  <Grid item>{textField(type, i)}</Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        handleAddRow(columnName);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => handleDeleteRow(i, columnName)}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </>
          );
        default:
          return textField(type, 0);
      }
    };
    const dropdownOperatorType = () => {
      switch (operator) {
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
          return <Grid item>{dropdownField(type, 0)}</Grid>;
        case 15:
          return (
            <Grid container item xs={"content"} direction={"column"}>
              <Grid item>{dropdownField(type, 0)}</Grid>
              <Grid item>
                <HorizontalRule />
              </Grid>
              <Grid item>{dropdownField(type, 1)}</Grid>
            </Grid>
          );
        case 14:
          return (
            <>
              {(criteriaRows[columnName] ?? []).map((item, i) => (
                <Grid container xs={"auto"} key={i} spacing={1}>
                  <Grid item>{dropdownField(type, i)}</Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        handleAddRow(columnName);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => handleDeleteRow(i, columnName)}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </>
          );
        default:
          return <Grid item>{dropdownField(type, 0)}</Grid>;
      }
    };
    const dayOperatorType = () => {
      // let operator = parseInt( Object.keys(JSON.parse(filterCriteria)[columnName])[0]);
      // if (criteriaRows?.length ?? 0 > 1) {
      //   let startDate = dayjs(criteriaRows[index]);
      //   let endDate = dayjs(criteriaRows[index]);

      //   console.log(startDate.diff(endDate, "hour"));

      //   if (endDate.diff(startDate, "hour") == 23) {
      //     operator = 7;
      //   }
      // }
      switch (operator) {
        case 7:
        case 9:
        case 10:
        case 11:
        case 12:
          return dateField(type, 0);
        case 15:
          return (
            <Grid container item xs={"content"} direction={"column"}>
              <Grid item>{dateField(type, 0)}</Grid>
              <Grid item>
                <HorizontalRule />
              </Grid>
              <Grid item>{dateField(type, 1)}</Grid>
            </Grid>
          );
        default:
          return dateField(type, 0);
      }
    };
    const textField = (type, index) => {
      return (
        <TextField
          fullWidth
          label={t("dashboard.criteria")}
          type={type}
          onChange={(event) => {
            handleCriteriaChange(event.target.value, columnName, index);
          }}
          key={type + index}
          value={
            Object.keys(criteriaRows).includes(columnName)
              ? criteriaRows[columnName][index]
              : ""
          }
        />
      );
    };
    const dateField = (type, index) => {
      return (
        <Grid container item alignSelf={"center"} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "100%" }}
              label={t("dashboard.criteria")}
              inputFormat="YYYY-MM-DD"
              onChange={(event) => {
                handleCriteriaChange(
                  event?.$d ?? false ? dayjs(event.$d).startOf("date") : null,
                  columnName,
                  index
                );
              }}
              views={["year", "month", "day"]}
              value={
                Object.keys(criteriaRows).includes(columnName)
                  ? criteriaRows[columnName][index] == ""
                    ? null
                    : dayjs.utc(criteriaRows[columnName][index]).tz(timezone)
                  : null
              }
              slotProps={{
                field: {
                  clearable: true,
                },
                clearButton: {
                  onClick: (e) => {
                    handleCriteriaChange(null, columnName, index);
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      );
    };
    const dropdownField = (type, index) => {
      return (
        <Grid container item alignSelf={"center"}>
          <FormControl sx={{ width: isSmallDevice ? "100%" : "150px" }}>
            <InputLabel id="dateType">
              {columnName.toLowerCase() === "case initiator"
                ? t(
                    "administrative_console.report_page.system_column.case_initiator"
                  )
                : t(
                    `administrative_console.report_page.system_column.${columnName.toLowerCase()}`
                  )}
            </InputLabel>
            <Select
              fullWidth
              id={"extraData" + index}
              value={
                Object.keys(criteriaRows).includes(columnName)
                  ? criteriaRows[columnName][index]
                  : null
              }
              input={
                <OutlinedInput
                  label={
                    columnName.toLowerCase() === "case initiator"
                      ? t(
                          "administrative_console.report_page.system_column.case_initiator"
                        )
                      : t(
                          `administrative_console.report_page.system_column.${columnName.toLowerCase()}`
                        )
                  }
                  value={
                    Object.keys(criteriaRows).includes(columnName)
                      ? criteriaRows[columnName][index]
                      : null
                  }
                />
              }
              onChange={(e) =>
                handleCriteriaChange(e.target.value, columnName, index)
              }
            >
              {(dropdownDataObj[columnName.toLowerCase()] ?? []).map(
                (option) => (
                  <MenuItem
                    key={option.label}
                    value={option.value}
                    disabled={
                      Object.keys(criteriaRows).includes(columnName)
                        ? criteriaRows[columnName].includes(option)
                        : false
                    }
                  >
                    {option.label}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
      );
    };

    switch (typeForSwitch) {
      case "String":
      case "Number":
        return textOperatorType();
      case "ExtraData":
        return dropdownOperatorType();
      case "Date":
        return dayOperatorType();
      default:
        return textOperatorType();
    }
  };

  React.useImperativeHandle(ref, () => ({
    getFilterCriteriaSetting: () => {
      //convert date to utc time
      let fc = JSON.parse(filterCriteria);
      Object.keys(fc).map((k) => {
        if (GetAxisData("DataType", k, null) === "Date") {
          let criObj = fc[k];
          let objWithUTC = {};
          let dateArr = Object.values(criObj)[0];
          dateArr = dateArr.map((d) =>
            dayjs(d).utc().format("YYYY-MM-DD HH:mm:ss")
          );
          objWithUTC[Object.keys(criObj)[0]] = dateArr;
          fc[k] = objWithUTC;
        }
      });

      return JSON.stringify(fc);
    },
  }));

  React.useEffect(() => {
    if (parentFilterCriteria) {
      let keyArr = Object.keys(JSON.parse(parentFilterCriteria));
      let cri = JSON.parse(parentFilterCriteria);
      cri[""] = { [""]: [""] };
      setFilterCriteria(JSON.stringify(cri));
      let obj = { [""]: [""] };
      let pageObj = { [""]: 1 };
      keyArr.map((k) => {
        if (k !== "") {
          obj[k] = Object.values(JSON.parse(parentFilterCriteria)[k])[0];
        }
        pageObj[k] = 1;
      });
      setCriteriaRows({ ...obj });
      setCurrentPage(pageObj);
      setAvailablePage(pageObj);
    }
  }, [parentFilterCriteria]);

  return (
    <Grid item>
      {Object.keys(JSON.parse(filterCriteria)).map((k, i) => (
        <Grid container item>
          <Grid item xs={12} sm={4}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="axis">
                {t("administrative_console.report_page.dialog_report")}
              </InputLabel>
              <Select
                id={"axis"}
                value={k}
                onChange={(event) => {
                  handleFilterCriteriaKeyChange(
                    event.target.value,
                    "ReportColumn",
                    i
                  );
                }}
                input={
                  <OutlinedInput
                    label={t(
                      "administrative_console.report_page.dialog_report"
                    )}
                  />
                }
              >
                {axis.map((option) => {
                  let allow = GetAxisData(
                    "AllowCriteria",
                    option.value,
                    option.parent
                  );
                  if (allow || option.value == "none") {
                    return (
                      <MenuItem
                        key={option.label}
                        value={option.value}
                        disabled={Object.keys(
                          JSON.parse(filterCriteria)
                        ).includes(option.value)}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="operator">{t("dashboard.operator")}</InputLabel>
              <Select
                id={"operator"}
                disabled={!k}
                value={Object.keys(JSON.parse(filterCriteria)[k])[0]}
                onChange={(event) => {
                  handleFilterCriteriaOperatorChange(event.target.value, k);
                }}
                input={<OutlinedInput label={t("dashboard.operator")} />}
              >
                {operatorOption(GetAxisData("DataType", k), k).map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5} maxHeight={"300px"} overflow={"auto"}>
            {CriteriaField(
              k,
              Object.keys(JSON.parse(filterCriteria)[k])[0],
              GetAxisData("DataType", k, null)
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
});

DashboardCriteriaComboBox.propTypes = {
  parentFilterCriteria: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number,
  type: PropTypes.string.isRequired,
  extraDataObj: PropTypes.object,
  axis: PropTypes.array.isRequired,
  GetAxisData: PropTypes.func.isRequired,
};

export const ReportCriteriaComboBox = React.forwardRef((props, ref) => {
  const { parentCriteria, columnName, type, dropdownDataObj, onChangeAction } =
    props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const [filterCriteria, setFilterCriteria] = React.useState(
    parentCriteria ?? JSON.stringify({ [""]: JSON.stringify({ [""]: [""] }) })
  );
  const [criteriaRows, setCriteriaRows] = React.useState(
    Object.keys(JSON.parse(parentCriteria)).includes(columnName)
      ? Object.values(JSON.parse(JSON.parse(parentCriteria)[columnName]))[0]
      : [""]
  );
  const [operator, setOperator] = React.useState(
    Object.keys(JSON.parse(parentCriteria)).includes(columnName)
      ? Object.keys(JSON.parse(JSON.parse(parentCriteria)[columnName]))[0]
      : ""
  );

  const handleCriteriaChange = (value, xy, index) => {
    let arr = criteriaRows;
    let input = null;
    let input2 = null;

    switch (type) {
      case "Date":
        if (value != null) {
          if (
            Object.keys(JSON.parse(JSON.parse(filterCriteria)[xy]))[0] == 7 ||
            Object.keys(JSON.parse(JSON.parse(filterCriteria)[xy]))[0] == 15
          ) {
            input = dayjs(value, "YYYY-MM-DD HH:mm:ss").startOf("date");

            if (index > 0) {
              input2 = dayjs(value, "YYYY-MM-DD HH:mm:ss").endOf("date");
            }
          } else {
            input =
              Object.keys(JSON.parse(JSON.parse(filterCriteria)[xy]))[0] == 9 ||
              Object.keys(JSON.parse(JSON.parse(filterCriteria)[xy]))[0] == 12
                ? dayjs(value, "YYYY-MM-DD HH:mm:ss")
                    .endOf("date")
                    .format("YYYY-MM-DD HH:mm:ss")
                : Object.keys(JSON.parse(JSON.parse(filterCriteria)[xy]))[0] ==
                    10 ||
                  Object.keys(JSON.parse(JSON.parse(filterCriteria)[xy]))[0] ==
                    11
                ? dayjs(value, "YYYY-MM-DD HH:mm:ss")
                    .startOf("date")
                    .format("YYYY-MM-DD HH:mm:ss")
                : dayjs(value, "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  );
          }
        } else {
          input = "";
        }
        break;
      case "String":
        input = value;
        break;
      case "Number":
        input = value == "" ? "" : parseInt(value);
        break;
    }
    arr[index] = input;
    if (input2) {
      arr[1] = input2;
    }
    console.log(arr);
    setCriteriaRows(() => [...arr]);
    onChangeAction();
  };

  const handleAddRow = (xy) => {
    let arr = criteriaRows;
    arr = [...arr, ""];
    setCriteriaRows(() => [...arr]);
  };

  const handleDeleteRow = (index, xy) => {
    let tempRow = criteriaRows;
    if (tempRow.length > 1) {
      tempRow.splice(index, 1);
    } else {
      tempRow[0] = "";
    }
    setCriteriaRows(() => [...tempRow]);
  };

  const handleOperatorOnChange = (value) => {
    let criteriaRow = criteriaRows;
    criteriaRow = value.value === 15 ? ["", ""] : [""];
    setCriteriaRows(() => [...criteriaRow]);
    let column = JSON.parse(filterCriteria);
    let c = JSON.parse(column[columnName]);
    column[columnName] = JSON.stringify({
      [value.value]: Object.values(c)[0],
    });
    setOperator(value.value);
    onChangeAction();
  };

  const CriteriaField = (operatorEnum, type) => {
    let typeForSwitch = type;
    if (
      columnName.toLowerCase() === "decision" ||
      columnName.toLowerCase() === "milestone" ||
      columnName.toLowerCase() === "case initiator"
    ) {
      typeForSwitch = "ExtraData";
    }

    const textOperatorType = () => {
      switch (parseInt(operatorEnum)) {
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
          return textField(type, 0);
        case 15:
          return (
            <Grid container item direction={"column"}>
              <Grid item>{textField(type, 0)}</Grid>
              <Grid item>
                <HorizontalRule />
              </Grid>
              <Grid item>{textField(type, 1)}</Grid>
            </Grid>
          );
        case 14:
          return (
            <Grid container maxHeight={500} overflow={"auto"}>
              {(criteriaRows ?? []).map((item, i) => (
                <Grid container item key={i} pb={1} alignItems={"center"}>
                  <Grid item>{textField(type, i)}</Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        handleAddRow(columnName);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => handleDeleteRow(i, columnName)}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          );
        default:
          return textField(type, 0);
      }
    };
    const dropdownOperatorType = () => {
      switch (parseInt(operatorEnum)) {
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
          return <Grid item>{dropdownField(type, 0)}</Grid>;
        case 15:
          return (
            <Grid container item xs={"content"} direction={"column"}>
              <Grid item>{dropdownField(type, 0)}</Grid>
              <Grid item>
                <HorizontalRule />
              </Grid>
              <Grid item>{dropdownField(type, 1)}</Grid>
            </Grid>
          );
        case 14:
          return (
            <>
              {(criteriaRows ?? []).map((item, i) => (
                <Grid
                  container
                  xs={12}
                  key={i}
                  spacing={1}
                  alignItems={"center"}
                  mt={1}
                >
                  <Grid item>{dropdownField(type, i)}</Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        handleAddRow(columnName);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => handleDeleteRow(i, columnName)}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </>
          );
        default:
          return <Grid item>{dropdownField(type, 0)}</Grid>;
      }
    };
    const dayOperatorType = () => {
      // let operator = parseInt(operatorEnum);
      // if (criteriaRows?.length ?? 0 > 1) {
      //   let startDate = dayjs(criteriaRows[index]);
      //   let endDate = dayjs(criteriaRows[index]);

      //   console.log(startDate.diff(endDate, "hour"));

      //   if (endDate.diff(startDate, "hour") == 23) {
      //     operator = 7;
      //   }
      // }
      switch (parseInt(operatorEnum)) {
        case 7:
        case 9:
        case 10:
        case 11:
        case 12:
          return dateField(type, 0);
        case 15:
          return (
            <Grid container item xs={"content"} direction={"column"}>
              <Grid item>{dateField(type, 0)}</Grid>
              <Grid item>
                <HorizontalRule />
              </Grid>
              <Grid item>{dateField(type, 1)}</Grid>
            </Grid>
          );
        default:
          return dateField(type, 0);
      }
    };
    const textField = (type, index) => {
      return (
        <TextField
          fullWidth
          label={t("dashboard.criteria")}
          type={type}
          onChange={(event) => {
            handleCriteriaChange(event.target.value, columnName, index);
          }}
          required={index === 0}
          error={
            index === 0 &&
            (criteriaRows[index] ? criteriaRows[index] : null) === null
          }
          key={type + index}
          value={criteriaRows[index] ? criteriaRows[index] : null}
        />
      );
    };
    const dateField = (type, index) => {
      return (
        <Grid container item alignSelf={"center"} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "100%" }}
              label={t("dashboard.criteria")}
              inputFormat="YYYY-MM-DD"
              onChange={(event) => {
                handleCriteriaChange(
                  event?.$d ?? false
                    ? dayjs(event.$d)
                        .startOf("date")
                        .format("YYYY-MM-DD HH:mm:ss")
                    : null,
                  columnName,
                  index
                );
              }}
              required={index === 0}
              views={["year", "month", "day"]}
              value={
                criteriaRows[index] == ""
                  ? null
                  : dayjs(criteriaRows[index], "YYYY-MM-DD HH:mm:ss")
              }
              slotProps={{
                textField: {
                  required: true,
                  error:
                    index === 0 &&
                    (criteriaRows[index] == ""
                      ? null
                      : dayjs(criteriaRows[index], "YYYY-MM-DD HH:mm:ss")) ===
                      null,
                },
                field: {
                  clearable: true,
                },
                clearButton: {
                  onClick: (e) => {
                    handleCriteriaChange(null, columnName, index);
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      );
    };
    const dropdownField = (type, index) => {
      return (
        <FormControl
          sx={{ width: isSmallDevice ? "100%" : "250px" }}
          required={index == 0}
        >
          <InputLabel id="dateType">
            {columnName.toLowerCase() === "case initiator"
              ? t(
                  "administrative_console.report_page.system_column.case_initiator"
                )
              : t(
                  `administrative_console.report_page.system_column.${columnName.toLowerCase()}`
                )}
          </InputLabel>
          <Select
            id={"extraData" + index}
            defaultValue={criteriaRows[index] ? criteriaRows[index] : null}
            error={(criteriaRows[index] ? criteriaRows[index] : null) === null}
            input={
              <OutlinedInput
                label={
                  columnName.toLowerCase() === "case initiator"
                    ? t(
                        "administrative_console.report_page.system_column.case_initiator"
                      )
                    : t(
                        `administrative_console.report_page.system_column.${columnName.toLowerCase()}`
                      )
                }
              />
            }
            onChange={(e) =>
              handleCriteriaChange(e.target.value, columnName, index)
            }
          >
            {(dropdownDataObj[columnName.toLowerCase()] ?? []).map((option) => (
              <MenuItem
                key={option.label}
                value={option.value}
                disabled={criteriaRows.includes(option)}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    };

    switch (typeForSwitch) {
      case "String":
      case "Number":
        return textOperatorType();
      case "ExtraData":
        return dropdownOperatorType();
      case "Date":
        return dayOperatorType();
      default:
        return textOperatorType();
    }
  };

  React.useImperativeHandle(ref, () => ({
    getFilterCriteriaSetting: () => {
      let cri = criteriaRows;
      cri = cri.filter((c) => c != "");
      let criObj = { [operator]: cri };
      let parentCriObj = JSON.parse(parentCriteria);
      parentCriObj[columnName] = JSON.stringify(criObj);
      return JSON.stringify(parentCriObj);
    },
    getError: () => {
      return operator == "" && criteriaRows[0] == "";
    },
  }));

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          loadingText={"Loading..."}
          id="combo-box-demo"
          defaultValue={
            operatorOption(type).find((o) => o.value === parseInt(operator))
              ?.label ?? ""
          }
          disableClearable
          onChange={(e, v) => {
            handleOperatorOnChange(v);
          }}
          options={operatorOption(type)}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label={t("report.criteria_dialog.operator")}
              error={
                (operatorOption(type).find(
                  (o) => o.value === parseInt(operator)
                )?.label ?? "") == ""
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        {CriteriaField(operator, type)}
      </Grid>
    </Grid>
  );
});

ReportCriteriaComboBox.propTypes = {
  columnName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number,
  extraDataObj: PropTypes.object,
};
