import * as React from "react";
import { Avatar, Badge, Stack, Tooltip } from "@mui/material";

import { useProfile } from "../providers/ProfileProvider";
import { stringAvatar } from "./Utils";

const FCAvatar = (props) => {
  const {
    slot = {},
    userId,
    userName,
    isRestricted = false,
    withUserName = false,
  } = props;
  const [profile, setProfile, getCachedAvatar] = useProfile();

  slot.photoAvatar = { ...slot.photoAvatar };
  slot.stringAvatar = { fontSize: "14px", ...slot.stringAvatar };

  return (
    <Stack direction={"row"} alignItems={"center"} gap={1}>
      <Tooltip title={userName}>
        <Badge
          badgeContent={isRestricted ? "R" : null}
          color={"primary"}
          overlap={"circular"}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          {getCachedAvatar(userId) ? (
            <Avatar
              src={getCachedAvatar(userId)}
              sx={{ ...slot.photoAvatar }}
            />
          ) : (
            <Avatar
              {...stringAvatar(userName, {
                ...slot.stringAvatar,
              })}
            />
          )}
        </Badge>
      </Tooltip>
      {withUserName ? userName : null}
    </Stack>
  );
};

export default FCAvatar;
