import * as React from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import VerifiedIcon from "@mui/icons-material/VerifiedUser";

import urlJoin from "url-join";
import { Dropzone, FileMosaic } from "@files-ui/react";
import { useTranslation } from "react-i18next";

import {
  CallApiWithContext,
  CallApiWithDownloadFile,
  CallApiWithUploadFile,
} from "../../helpers/ApiHelper";
import { IsTerminalState } from "../../Constants";
import { resServerBaseUrl } from "../../Config";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { LoadingButton } from "@mui/lab";

const AttachmentControl = (props) => {
  const {
    caseInfo,
    caseIdentity,
    security,
    existingAttachments = [],
    showAlertMessage,
    closeAlertMessage,
    isFirstItem = false,
    onPreviewClick = null,
  } = props;
  const { name: attachmentControlName, permission } = security;
  const authenticationContext = useAuthentication();
  const { t } = useTranslation();

  const [attachments, setAttachments] = React.useState(existingAttachments);
  const [clickable, setClickable] = React.useState(true);
  const [customSetting, setCustomSetting] = React.useState({});
  const [fileInput, setFileInput] = React.useState(null);
  const [isLoadingDownloadAttachment, setIsLoadingDownloadAttachment] =
    React.useState(false);
  const [isLoadingDeleteAttachment, setIsLoadingDeleteAttachment] =
    React.useState(false);
  const [userInfo, setUserInfo] = React.useState({});

  const listItemStyles = {
    header: { backgroundColor: "rgba(200, 200, 200, 0.4)", userSelect: "none" },
    attachment: {
      p: "4px 8px",
      "& div": { width: "100%" },
      "&:last": { pb: "8px" },
    },
  };

  const updateFiles = (incomingFiles, controlName) => {
    setFileInput(incomingFiles);

    const isRepeat =
      attachments?.some((a) =>
        incomingFiles.some((n) => n.file.name === a.fileName)
      ) === true;

    if (isRepeat) {
      setFileInput(null);
      showAlertMessage({
        title: t("profile.proxy_and_delegation.error"),
        content: t("formruntime.gcpdf_viewer.file_exist"),
        buttons: [
          {
            text: t("administrative_console.group_page.dialog.close"),
            action: closeAlertMessage,
          },
        ],
      });
    } else {
      const url = urlJoin(resServerBaseUrl, "/Import/UploadAttachment");

      incomingFiles.forEach((f) => {
        const formDataFile = new FormData();

        formDataFile.append("file", f.file);
        formDataFile.append("CaseSID", caseIdentity?.caseSID);
        formDataFile.append("TempName", caseIdentity?.tempName);
        formDataFile.append("CaseTplUUID", caseInfo?.tplUUID);
        formDataFile.append("PlanItemSID", caseIdentity?.planItemSID);
        formDataFile.append("Name", controlName);

        CallApiWithUploadFile(url, authenticationContext, formDataFile)
          .then((response) => {
            if (typeof response == "undefined") {
              showAlertMessage({
                title: t("profile.proxy_and_delegation.error"),
                content: t("formruntime.gcpdf_viewer.fail_upload_attachment"),
                buttons: [
                  {
                    text: t("administrative_console.group_page.dialog.close"),
                    action: () => {
                      closeAlertMessage();
                    },
                  },
                ],
              });
            } else if (response.message === "Inactive") {
              const url = urlJoin(resServerBaseUrl, "/User/GetUserProfile");

              CallApiWithContext(url, authenticationContext).then((json) => {
                if (json != null) {
                  var customSetting = JSON.parse(json.customSetting);
                  setCustomSetting(customSetting);
                  setUserInfo(json);
                  if (
                    customSetting.KEEPAVSERVERBYPASS === true &&
                    customSetting.AVSERVERBYPASS === false
                  ) {
                    showAlertMessage({
                      title: t("profile.proxy_and_delegation.error"),
                      content: t(
                        "formruntime.gcpdf_viewer.fail_upload_attachment"
                      ),
                      buttons: [
                        {
                          text: t(
                            "administrative_console.group_page.dialog.close"
                          ),
                          action: () => {
                            closeAlertMessage();
                          },
                        },
                      ],
                    });
                  } else if (
                    customSetting.KEEPAVSERVERBYPASS === true &&
                    customSetting.AVSERVERBYPASS === true
                  ) {
                    updateFilesSkippedVirusCheck(formDataFile);
                  } else {
                    showAlertMessage({
                      title: t(
                        "administrative_console.import_page.confirm_bypass_server"
                      ),
                      content: (
                        <>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={handleRememberMySettingChange(
                                    "KEEPAVSERVERBYPASS"
                                  )}
                                />
                              }
                              label={t(
                                "administrative_console.import_page.remember"
                              )}
                            />
                          </FormGroup>
                        </>
                      ),
                      buttons: [
                        {
                          /* if yes, continue process submission */
                          text: t("formruntime.yes"),
                          action: () => {
                            closeAlertMessage();
                            handleServerBypassConfirm(formDataFile);
                          },
                        },
                        {
                          /* if no, cancel the submission. */
                          text: t("formruntime.no"),
                          action: () => {
                            closeAlertMessage();
                            handleServerBypassDenied();
                          },
                          variant: "outlined",
                          color: "error",
                        },
                      ],
                    });
                  }
                }
              });
            } else if (response) {
              setAttachments((prev) => {
                const atchArray = prev || [];

                atchArray.push({
                  fileName: formDataFile.get("file").name,
                  caseSid: caseIdentity.caseSID,
                  attachmentControl: controlName,
                  isNew: true,
                  isChecked: true,
                });

                return atchArray;
              });
            }
            setFileInput(null);
          })
          .catch((error) => {
            showAlertMessage({
              title: t("profile.proxy_and_delegation.error"),
              content: error,
              buttons: [
                {
                  text: t("administrative_console.group_page.dialog.close"),
                  action: closeAlertMessage,
                },
              ],
            });
            // setOpenAddAttachmentDialog(false);
            // setIsLoadingAddAttachment(false);
            console.log(error);
          });
      });
    }

    //even your own upload implementation
  };
  const updateFilesSkippedVirusCheck = (formDataFile) => {
    const url = urlJoin(
      resServerBaseUrl,
      "/Import/UploadAttachmentSkippedVirusCheck"
    );

    CallApiWithUploadFile(url, authenticationContext, formDataFile)
      .then((response) => {
        if (response) {
          setAttachments((prev) => [
            ...prev,
            {
              name: formDataFile.get("file").name,
              isNew: true,
              isChecked: false,
            },
          ]);
        }
        setFileInput(null);
      })
      .catch((error) => {
        showAlertMessage({
          title: t("profile.proxy_and_delegation.error"),
          content: error,
          buttons: [
            {
              text: t("administrative_console.group_page.dialog.close"),
              action: closeAlertMessage,
            },
          ],
        });
        console.log(error);
      });
  };
  const updateCustomSetting = () => {
    var saveData = {
      ID: userInfo.id,
      Login: userInfo.login,
      CustomSetting: JSON.stringify(customSetting),
    };
    const updateUserURL = urlJoin(
      resServerBaseUrl,
      "User/UpdateUserCustomSetting"
    );
    CallApiWithContext(
      updateUserURL,
      authenticationContext,
      JSON.stringify(saveData)
    )
      .then((response) => {
        console.log(response.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAttachmentClick = (event, attachment) => {
    event.stopPropagation();

    if (permission?.canPreview) {
      if (onPreviewClick) {
        onPreviewClick(attachmentControlName, attachment);
      }
    }
  };
  const handleDownloadAttachmentButtonOnClick = (name, index) => {
    if (
      permission?.canDownload ||
      (caseIdentity.caseSID != null && attachments[index].isNew)
    ) {
      setIsLoadingDownloadAttachment(true);

      const file = attachments[index];
      const data = {
        CaseSID: file.caseSid,
        CaseTplUUID: caseInfo?.tplUUID,
        PlanItemSID: caseIdentity.planItemSID,
        TempName: caseIdentity.tempName,
        FileName: file.fileName,
        Name: name,
        IsNew: file.isNew,
      };
      const url = urlJoin(resServerBaseUrl, "/Import/DownloadAttachment");

      CallApiWithDownloadFile(
        url,
        authenticationContext,
        JSON.stringify(data),
        file.fileName
      )
        .then((response) => {
          if (response) {
          } else {
            showAlertMessage({
              title: t("profile.proxy_and_delegation.error"),
              content: t("formruntime.gcpdf_viewer.fail_download_attachment"),
              buttons: [
                {
                  text: t("administrative_console.group_page.dialog.close"),
                  action: () => {
                    closeAlertMessage();
                  },
                },
              ],
            });
          }
        })
        .catch((error) => {
          let errorMsg = error;

          if (error.response.status === 403) {
            errorMsg = t("formruntime.no_download_permission");
          }

          showAlertMessage({
            title: t("profile.proxy_and_delegation.error"),
            content: errorMsg,
            buttons: [
              {
                text: t("administrative_console.group_page.dialog.close"),
                action: () => {
                  closeAlertMessage();
                },
              },
            ],
          });
        })
        .finally(() => {
          setIsLoadingDownloadAttachment(false);
        });
    } else {
      showAlertMessage({
        title: t("profile.proxy_and_delegation.error"),
        content: t("formruntime.gcpdf_viewer.no_permission_to_download"),
        buttons: [
          {
            text: t("administrative_console.group_page.dialog.close"),
            action: () => {
              closeAlertMessage();
            },
          },
        ],
      });
    }
  };
  const handleDeleteAttachement = (name, index) => {
    const file = attachments[name][index];

    let data = {
      CaseSID: caseIdentity.caseSID,
      CaseTplUUID: caseInfo?.tplUUID,
      PlanItemSID: caseIdentity.planItemSID,
      TempName: caseIdentity.tempName,
      FileName: file.fileName,
      IsNew: file.isNew,
      Name: name,
    };
    const url = urlJoin(resServerBaseUrl, "/Import/DeleteAttachment");

    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((response) => {
        if (response.message) {
          setAttachments((prev) => ({
            ...prev,
            [name]: prev[name]?.filter((f) => f.fileName !== file.fileName),
          }));
        } else if (!response) {
          showAlertMessage({
            title: t("profile.proxy_and_delegation.error"),
            content: t("formruntime.gcpdf_viewer.fail_to_delete_attachment"),
            buttons: [
              {
                text: t("administrative_console.group_page.dialog.close"),
                action: closeAlertMessage,
              },
            ],
          });
        }
        setIsLoadingDeleteAttachment(false);
      })
      .catch((error) => {
        showAlertMessage({
          title: t("profile.proxy_and_delegation.error"),
          content: error,
          buttons: [
            {
              text: t("administrative_console.group_page.dialog.close"),
              action: closeAlertMessage,
            },
          ],
        });
        console.log(error);
      });
  };
  const handleOpenDeleteAttachmentDialog = (name, index) => {
    const file = attachments[index];

    if (permission?.canDelete || (caseIdentity.caseSID != null && file.isNew)) {
      showAlertMessage({
        title: t("formruntime.gcpdf_viewer.confirm_delete_attachment"),
        content: file.fileName,
        buttons: [
          {
            text: t("formruntime.yes"),
            action: () => {
              closeAlertMessage();
              handleDeleteAttachement(name, index);
            },
          },
          {
            text: t("formruntime.no"),
            action: () => {
              closeAlertMessage();
            },
            variant: "outlined",
            color: "error",
          },
        ],
      });
    } else {
      showAlertMessage({
        title: t("profile.proxy_and_delegation.error"),
        content: t("formruntime.gcpdf_viewer.no_permission_to_delete"),
        buttons: [
          {
            text: t("administrative_console.group_page.dialog.close"),
            action: closeAlertMessage,
          },
        ],
      });
    }
  };
  const handleServerBypassConfirm = (formDataFile) => {
    customSetting.AVSERVERBYPASS = true;
    updateCustomSetting();
    updateFilesSkippedVirusCheck(formDataFile);
  };
  const handleServerBypassDenied = () => {
    customSetting.AVSERVERBYPASS = false;
    showAlertMessage({
      title: t("profile.proxy_and_delegation.error"),
      content: t("formruntime.gcpdf_viewer.fail_upload_attachment"),
      buttons: [
        {
          text: t("administrative_console.group_page.dialog.close"),
          action: closeAlertMessage,
        },
      ],
    });
    updateCustomSetting();
  };
  const handleRememberMySettingChange = (prop) => (event) => {
    setCustomSetting({ ...customSetting, [prop]: event.target.checked });
  };

  return (
    <>
      {!permission?.canPreview &&
      !permission?.canUpload &&
      !permission?.canDownload &&
      !permission?.canDelete ? null : !IsTerminalState(caseInfo?.state) &&
        permission?.canUpload ? (
        <ListItem disablePadding key={`${security.name ?? "attachment"}`}>
          <div
            style={{
              width: "100%",
              padding: `${isFirstItem ? "4px" : "0px"} 8px 8px 8px`,
            }}
          >
            <Typography
              component={"div"}
              variant="caption"
              textAlign={"left"}
              width="100%"
              marginLeft={"8px"}
            >
              {attachmentControlName}
            </Typography>

            <Dropzone
              accept=".zip,application/zip,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.cmmn,.log,text/plain,application/json,image/jpeg"
              onChange={(e) => updateFiles(e, attachmentControlName)}
              minHeight={"50px"}
              header={false}
              footer={false}
              clickable={clickable}
              autoClean
              value={fileInput ?? []}
              headerConfig={{
                deleteFiles: fileInput?.length > 0,
              }}
            >
              {fileInput?.map((f) => (
                <FileMosaic
                  style={{
                    height: "80px",
                    width: "80px",
                    overflow: "hidden",
                  }}
                  key={`file_${f.id}`}
                  {...f}
                  uploadStatus={"uploading"}
                />
              ))}
              {!security?.permission.canUpload &&
              !security?.permission.canDownload &&
              !security?.permission.canDelete ? null : attachments?.length >
                  0 || fileInput?.length > 0 ? (
                <>
                  <ListItem sx={{ m: 0 }} disablePadding>
                    <ListItemText disableTypography>
                      <Typography
                        component={"div"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        {t("formruntime.drop_files")}
                        <CloudUploadOutlinedIcon
                          sx={{ mx: 1 }}
                          fontSize="medium"
                        />
                        {t("formruntime.to_upload")}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  {attachments?.map((item, index) => {
                    if (item !== null) {
                      return (
                        <Tooltip
                          key={`attachment_${index}`}
                          title={item.name}
                          placement="top"
                        >
                          <ListItem sx={{ m: "0" }} disablePadding>
                            <ListItemButton disableGutters sx={{ p: "0px" }}>
                              <AttachFileIcon />
                              <ListItemText
                                onClick={(e) => handleAttachmentClick(e, item)}
                                primaryTypographyProps={{
                                  variant: "subtitle2",
                                  style: {
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  },
                                }}
                                primary={
                                  <Tooltip title={item.fileName}>
                                    {item.fileName}
                                  </Tooltip>
                                }
                              />
                              {permission?.canDownload ? (
                                isLoadingDownloadAttachment ? (
                                  <LoadingButton
                                    size="small"
                                    loading
                                    style={{
                                      padding: "0px",
                                    }}
                                  ></LoadingButton>
                                ) : (
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDownloadAttachmentButtonOnClick(
                                        attachmentControlName,
                                        index
                                      );
                                    }}
                                    size="small"
                                  >
                                    <DownloadIcon fontSize="inherit" />
                                  </IconButton>
                                )
                              ) : null}
                              {item.isNew ||
                              (permission?.canDelete &&
                                item.caseSid === caseIdentity.caseSID) ? (
                                isLoadingDeleteAttachment ? (
                                  <LoadingButton
                                    size="small"
                                    loading
                                    disabled={!permission?.canDelete}
                                    style={{
                                      padding: "0px",
                                    }}
                                  ></LoadingButton>
                                ) : (
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleOpenDeleteAttachmentDialog(
                                        attachmentControlName,
                                        index
                                      );
                                    }}
                                    size="small"
                                  >
                                    <DeleteIcon fontSize="inherit" />
                                  </IconButton>
                                )
                              ) : null}
                              {item.isChecked ? (
                                <VerifiedIcon fontSize="small" />
                              ) : null}
                              <a href=""></a>
                            </ListItemButton>
                          </ListItem>
                        </Tooltip>
                      );
                    }

                    return null;
                  })}
                </>
              ) : security?.permission.canUpload ? (
                <div
                  style={{
                    display: "inline-flex",
                    fontSize: "14px",
                    alignItems: "center",
                  }}
                >
                  {t("formruntime.drop_files")}
                  <CloudUploadOutlinedIcon sx={{ mx: 1 }} fontSize="medium" />
                  {t("formruntime.to_upload")}
                </div>
              ) : null}
            </Dropzone>
          </div>
        </ListItem>
      ) : (
        <>
          <ListItem
            disablePadding
            sx={listItemStyles.attachment}
            key={`${attachmentControlName ?? "attachment"}`}
          >
            <div>
              <Typography
                component={"div"}
                variant="caption"
                textAlign={"left"}
                width="100%"
                marginLeft={"8px"}
              >
                {attachmentControlName}
              </Typography>

              <div
                style={{
                  border: "solid 1px #ccc",
                  minHeight: "50px",
                  borderRadius: "8px",
                  flex: 1,
                  color: "rgb(100, 108, 127)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                {attachments?.filter((a) => a !== null).length > 0 ? (
                  attachments.map((item, index) => {
                    if (item !== null) {
                      return (
                        <Tooltip
                          key={`attachment_${index}`}
                          title={item.name}
                          placement="top"
                        >
                          <ListItem disablePadding sx={{ py: "5px" }}>
                            <ListItemButton disableGutters sx={{ p: 0 }}>
                              <AttachFileIcon />
                              <ListItemText
                                primaryTypographyProps={{
                                  variant: "subtitle2",
                                  style: {
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  },
                                }}
                                primary={
                                  <Tooltip title={item.fileName}>
                                    {item.fileName}
                                  </Tooltip>
                                }
                              />
                              {permission?.canDownload ? (
                                isLoadingDownloadAttachment ? (
                                  <LoadingButton
                                    size="small"
                                    loading
                                    style={{
                                      padding: "0px",
                                    }}
                                  ></LoadingButton>
                                ) : (
                                  <IconButton
                                    onMouseEnter={() => setClickable(false)}
                                    onMouseLeave={() => setClickable(true)}
                                    onClick={() =>
                                      handleDownloadAttachmentButtonOnClick(
                                        attachmentControlName,
                                        index
                                      )
                                    }
                                    size="small"
                                  >
                                    <DownloadIcon fontSize="inherit" />
                                  </IconButton>
                                )
                              ) : null}
                            </ListItemButton>
                          </ListItem>
                        </Tooltip>
                      );
                    }

                    return null;
                  })
                ) : (
                  <ListItemText
                    style={{
                      color: "#aaa",
                      textAlign: "center",
                    }}
                  >
                    {t("formruntime.empty")}
                  </ListItemText>
                )}
              </div>
            </div>
          </ListItem>
        </>
      )}
    </>
  );
};

export default AttachmentControl;
