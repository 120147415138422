import React from "react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { authServerBaseUrl, resServerBaseUrl } from "../../Config";
import { CallApiWithoutContext } from "../../helpers/ApiHelper";
import { Box } from "@mui/system";
import urlJoin from "url-join";

const CompleteTask = () => {
  const [msg, setMsg] = React.useState("Loading...");
  const location = useLocation();
  React.useEffect(() => {
    const sid = location.search.split("=")[1];
    const decision = location.hash.split("#")[1];
    const data = {
      SID: sid,
      Decision: decision,
    };
    const url = urlJoin(authServerBaseUrl, "/completeTaskByUrl");
    CallApiWithoutContext(url, data)
      .then((response) => {
        setMsg(response.message);
      })
      .catch((error) => {
        setMsg(error.message);
      });
  }, []);

  return <Box>{msg}</Box>;
};

export default CompleteTask;
