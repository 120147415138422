export const DRAWER_WIDTH = 256;

export const DEFINITION_TYPE = {
  Case: 0,
  Stage: 1,
  Task: 2,
  PlanFragment: 3,
  Milestone: 4,
  PlanItem: 5,
  Criterion: 6,
  EventListener: 7,
  CaseFileItem: 8,
  PlanItemUser: 9,
};

export const ELEMENT_STATUS = {
  Initial: 0,
  Active: 1,
  Available: 2,
  Closed: 3,
  Completed: 4,
  Failed: 8,
  Suspended: 9,
  Terminated: 10,
};

export const CHART_TYPE = {
  Bar: 0,
  Pie: 1,
  List: 2,
};

export const MODULE_PERMISSIONS = {
  Inbox: 2 ** 0,
  Outbox: 2 ** 1,
  Messages: 2 ** 2,
  Workspace: 2 ** 3,
  Report: 2 ** 4,
  Designer: 2 ** 5,
  DMS: 2 ** 6,
  Dashboard: 2 ** 7,
  Reserved1: 2 ** 8,
  Reserved2: 2 ** 9,
  Reserved3: 2 ** 10,
  Reserved4: 2 ** 11,
  Reserved5: 2 ** 12,
  Reserved6: 2 ** 13,
  Reserved7: 2 ** 14,
  AdminConsole: 2 ** 15,
  Email_Setting: 2 ** 16,
  Import_Setting: 2 ** 17,
  Proxy_Setting: 2 ** 18,
  Report_Setting: 2 ** 19,
  User_Setting: 2 ** 20,
  Group_Setting: 2 ** 21,
  Connection_Setting: 2 ** 22,
  Password_Policy: 2 ** 23,
  License: 2 ** 24,
  Reserved9: 2 ** 25,
  Reserved10: 2 ** 26,
  Reserved11: 2 ** 27,
  Profile: 2 ** 28,
  Profile_Proxy: 2 ** 29,
  Profile_Custom_Property: 2 ** 30,
  Reserved12: 2 ** 31,
};

export const MessageType = {
  Success: 1,
  Error: 2,
  Warning: 3,
};

export const IsTerminalState = (status) => {
  return (
    status === ELEMENT_STATUS.Completed || status === ELEMENT_STATUS.Terminated
  );
};

export const CaseTplPermission = {
  None: 0,
  View: 1,
  Initiate: 2,
  Manage: 4,
};
