import * as React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DrawIcon from "@mui/icons-material/Draw";
import TaskIcon from "@mui/icons-material/Task";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import {
  Collapse,
  CircularProgress,
  Skeleton,
  LinearProgress,
  useMediaQuery,
  IconButton,
  Tooltip,
  ListItem,
} from "@mui/material";
import {
  Dashboard,
  Equalizer,
  ExpandLess,
  ExpandMore,
  FolderOpen,
  Inbox,
  Telegram,
  Workspaces,
} from "@mui/icons-material";
import MessageIcon from "@mui/icons-material/Message";
import SettingsIcon from "@mui/icons-material/Settings";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import PublishIcon from "@mui/icons-material/Publish";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { CallApiWithContext } from "../helpers/ApiHelper";
import { resServerBaseUrl } from "../Config";
import Logo from "../content/img/FlexCASE logowithtext 223x43.png";
import Logo1 from "../content/img/ageathome.svg";
import "./Navigator.css";
import { useAuthentication } from "../providers/AuthenticationProvider";
import urlJoin from "url-join";
import SharedDialog from "../shared/SharedDialog";
import { createTheme, styled } from "@mui/material/styles";
import { useTheme } from "@emotion/react";
import { useProfile } from "../providers/ProfileProvider";
import { MODULE_PERMISSIONS } from "../Constants";

//// Try add back this for saving old ver navigator
// const categories = [
//   {
//     id: 'inbox',
//     label: 'left_menu.inbox',
//     icon: <Inbox />
//   },
//   {
//     id: 'outbox',
//     label: 'left_menu.outbox',
//     icon: <Telegram />
//   },
//   {
//     id: 'workspace',
//     label: 'left_menu.workspace',
//     icon: <Workspaces />,
//     children: [
//       {
//         id: 'Case',
//         label: 'left_menu.case',
//         icon: <FolderOpen />,
//         children: []
//       }
//     ]
//   },
//   {
//     id: 'report',
//     label: 'left_menu.report',
//     icon: <AssessmentIcon />,
//   }
// ];

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: "100%",
//     "& .MuiAccordion-region:hover": {
//       cursor: "default"
//     }
//   },
//   panel: {
//     cursor: "default"
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     fontWeight: theme.typography.fontWeightRegular
//   }
// }));
let breakPoint = createTheme({});

const heading = {
  // boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  //pl: breakPoint.breakpoints.up("md") ? "0px" : "28px",
  pt: 1,
  // px: 1,
  // width: "100%",
};

const collapsedcaseItem = {
  pl: 7,
  // color: "rgba(255, 255, 255, 0.7)",
  // bgcolor: "#101F33",
  // "&:hover, &:focus": {
  //   bgcolor: "rgba(255, 255, 255, 0.08)",
  // },
};
const LoadingBarColor = {
  // width: "100px",
  // height: "21px",
  // bgcolor: "rgba(255, 255, 255, 0.7)",
};

const collapsedItemLoadingColor = {
  // width: "20px",
  // height: "20px",
  // bgcolor: "rgba(255, 255, 255, 0.7)",
};

const collapsedItem = {
  pl: 5,
  // color: "rgba(255, 255, 255, 0.7)",
  // bgcolor: "#101F33",
  // "&:hover, &:focus": {
  //   bgcolor: "rgba(255, 255, 255, 0.08)",
  // },
};

const item = {
  pl: 3,
  // color: "rgba(255, 255, 255, 0.7)",
  // bgcolor: "#101F33",
  // "&:hover, &:focus": {
  //   bgcolor: "rgba(255, 255, 255, 0.08)",
  // },
};

export const HoverIcon = styled(Box)(({ theme, active }) => ({
  opacity: active ? 0.2 : 0,
  display: "flex",
  alignitems: "center",
  "&:hover": { opacity: 1 },
}));

export default function Navigator(props) {
  const { messageCount, open: drawerOpen } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const nav = useNavigate();

  // const accessTokenContext = useAccessToken();
  const authenticationContext = useAuthentication();
  const [profile] = useProfile();

  const [expandedComponents, setExpandedComponents] = React.useState([{}]);
  const [categories, setCategories] = React.useState(null);
  //Trial Close after click
  const [propsCloseCounter, setPropsCloseCounter] = React.useState(false);
  //End trial
  const [isLoading, setIsLoading] = React.useState(true);
  const [expandClicked, setExpandClicked] = React.useState(false);
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const SkeletonItems = 3;
  const [isSelected, setIsSelected] = React.useState("");
  const [categorylock, setCategorylock] = React.useState(false);
  const [catchCategory, setCatchCategory] = React.useState("");
  const [mouseOverMenu, setMouseOverMenu] = React.useState();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const curTheme = localStorage.getItem("theme");
  const location = useLocation();
  const locationState = React.useMemo(() => {
    return location.pathname.split("/").slice(1);
  }, [location.pathname]);

  const handleAlertDialogClose = () => {
    setAlertDialog(false);
  };

  const handleExpandByChild = (id, parentId) => {
    setCategorylock(true);
    handleExpand(id, parentId);
  };

  const handleExpand = (id, parentId) => {
    setIsSelected(id);
    var tempExpandedComponents = [...expandedComponents]; // make a separate copy of a state array
    if (parentId != null) {
      var parentComponentIndex = tempExpandedComponents
        .map((obj) => obj.id)
        .indexOf(parentId);
      var childCompoenentIndex =
        tempExpandedComponents[parentComponentIndex].child > 0
          ? null
          : tempExpandedComponents[parentComponentIndex].child;
      if (childCompoenentIndex != null && childCompoenentIndex.length > 0) {
        if (
          tempExpandedComponents[parentComponentIndex].child.indexOf(id) < 0
        ) {
          tempExpandedComponents[parentComponentIndex].child[0] = id;
        } else {
          tempExpandedComponents[parentComponentIndex].child.splice(
            tempExpandedComponents[parentComponentIndex].child.indexOf(id),
            1
          );
        }
        setExpandedComponents(tempExpandedComponents);
      } else {
        var childarray = {
          id: tempExpandedComponents[parentComponentIndex].id,
          child: [id],
        };
        tempExpandedComponents[parentComponentIndex] = childarray;
        setExpandedComponents(tempExpandedComponents);
      }
    } else {
      var componentIndex = tempExpandedComponents
        .map((obj) => obj.id)
        .indexOf(id);
      if (componentIndex >= 0) {
        tempExpandedComponents.splice(componentIndex, 1);
        setExpandedComponents(tempExpandedComponents);
      } else {
        setExpandedComponents([...expandedComponents, { id: id, child: [] }]);
      }
    }
  };

  //Trial close the drawer here
  const handleCloseDrawer = () => {
    // setDrawerOpen(false);
    setPropsCloseCounter(true);
  };
  const handleCloseDrawerWithID = (id) => {
    // setDrawerOpen(false);
    setPropsCloseCounter(true);
    setIsSelected(id);
  };
  const handlePublishTplClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    nav("/publish-template");
  };

  React.useEffect(() => {
    /* expand and highlight the workspace template when path was changed */
    console.log(locationState);

    if (locationState.length > 0 && categories) {
      const selectedItem = locationState[0];

      if (selectedItem === "workspace") {
        const index = locationState.indexOf("workspace");
        const tplUUID = locationState[index + 1];

        if (index > -1 && locationState.length > index + 1) {
          setIsSelected(tplUUID);

          const workspace = categories?.find(
            ({ id }, idx) => id === "workspace"
          );

          if (workspace?.children) {
            const cat = workspace.children.find(
              (c) => c.children?.some((cc) => cc.uuid == tplUUID) === true
            );

            if (cat) {
              const expandedComponent = expandedComponents.find(
                ({ id }, idx) => id == "workspace"
              );
              const selectedTpl = cat.id;

              if (expandedComponent) {
                expandedComponent.child = [selectedTpl];
                setExpandedComponents([...expandedComponents]);
              } else {
                setExpandedComponents((prev) => [
                  ...prev,
                  { id: "workspace", child: [selectedTpl] },
                ]);
              }
            }
          }
        }
      } else {
        const cat = categories?.find(({ id }, idx) => id === selectedItem);

        if (cat) {
          setIsSelected(cat.id);
        }
      }
    }
  }, [locationState, categories]);
  React.useEffect(() => {
    if (props.open === false && propsCloseCounter === true) {
      // setDrawerOpen(true);
      setPropsCloseCounter(false);
    } else {
      // setDrawerOpen(props.open);
    }
  }, [props.open]);
  React.useEffect(() => {
    if (catchCategory?.length > 0) {
      console.log(catchCategory);

      if (categories?.length > 0) {
        var tempCateg = [...categories];
        setCategories(tempCateg);
      }
    }
  }, [catchCategory, t]);
  React.useEffect(() => {
    if (profile?.modulePermissions && profile?.security) {
      const url = urlJoin(resServerBaseUrl, "/User/GetAccountTypeSecurity");
      const data = {
        userUUID: profile.uuid,
        accountType: profile.accountType,
      };

      CallApiWithContext(url, authenticationContext, JSON.stringify(data))
        .then((res) => {
          const arr = [];

          if (
            res["Inbox"] !== undefined &&
            (profile.modulePermissions & MODULE_PERMISSIONS.Inbox) > 0
          ) {
            arr.push({
              id: "inbox",
              label: "left_menu.inbox",
              icon: <Inbox />,
            });
          }
          if (
            res["Outbox"] !== undefined &&
            (profile.modulePermissions & MODULE_PERMISSIONS.Outbox) > 0
          ) {
            arr.push({
              id: "outbox",
              label: "left_menu.outbox",
              icon: <Telegram />,
            });
          }
          if (
            res["Messages"] !== undefined &&
            (profile.modulePermissions & MODULE_PERMISSIONS.Messages) > 0
          ) {
            arr.push({
              id: "messages",
              label: "left_menu.messages",
              icon: <MessageIcon />,
              showIndicator: true,
            });
          }
          if (
            res["Workspace"] !== undefined &&
            (profile.modulePermissions & MODULE_PERMISSIONS.Workspace) > 0
          ) {
            const url = urlJoin(resServerBaseUrl, "/Case/GetCaseTemplates");
            const data = JSON.stringify({
              IsAdministrator: true,
              Language: localStorage.getItem("i18nextLng"),
            });
            CallApiWithContext(url, authenticationContext, data)
              .then((response) => {
                if (response) {
                  setCatchCategory(response);

                  const menuItem = arr.find((item) => item.id === "workspace");

                  response.forEach((element) => {
                    const filteredTemplates = element.templateInfos;

                    if (filteredTemplates && filteredTemplates.length > 0) {
                      if (
                        menuItem.children.filter((x) => x.id === element.name)
                          .length <= 0
                      ) {
                        menuItem.children.push({
                          id: element.name,
                          icon: <FolderOpen key={element.name} />,
                          children: filteredTemplates,
                        });
                      } else if (element.name === "Case")
                        menuItem.children[0].children = filteredTemplates;
                    }
                  });
                  setIsLoading(false);
                }
              })
              .catch((error) => {
                setIsLoading(false);
                console.log(error);
              });
            arr.push({
              id: "workspace",
              label: "left_menu.workspace",
              icon: <Workspaces />,
              children: [
                {
                  id: "Case",
                  label: "left_menu.case",
                  icon: <FolderOpen />,
                  children: [],
                },
              ],
              endAdornment:
                res["Import_Setting"] !== undefined &&
                (profile.modulePermissions &
                  MODULE_PERMISSIONS.Import_Setting) >
                  0 ? (
                  <Tooltip
                    title={t("left_menu.publish_template")}
                    sx={{ display: "flex", alignItems: "center" }}
                    onClick={handlePublishTplClick}
                  >
                    <PublishIcon />
                  </Tooltip>
                ) : null,
            });
          } else {
            setAlertDialog(true);
            setAlertContent("No permission to get workspace");
          }
          if (
            res["Report"] !== undefined &&
            (profile.modulePermissions & MODULE_PERMISSIONS.Report) > 0
          ) {
            arr.push({
              id: "report",
              label: "left_menu.report",
              icon: <AssessmentIcon />,
            });
          }
          if (
            res["Designer"] !== undefined &&
            (profile.modulePermissions & MODULE_PERMISSIONS.Designer) > 0
          ) {
            arr.push({
              id: "designer",
              label: "left_menu.designer",
              icon: <DrawIcon />,
              children: [
                {
                  id: "designer-case",
                  label: "left_menu.case_designer",
                  icon: <DrawIcon />,
                },
                {
                  id: "designer-form",
                  label: "left_menu.form_designer",
                  icon: <DrawIcon />,
                },
              ],
            });
          }
          if (
            res["DocumentManagement"] !== undefined &&
            (profile.modulePermissions & MODULE_PERMISSIONS.DMS) > 0
          ) {
            arr.push({
              id: "documanlink",
              label: "left_menu.documanlink",
              icon: <TaskIcon />,
              url: `http://lite.flexworkflow.com:50001/dms`,
            });
          }
          if (
            res["DashBoard"] !== undefined &&
            (profile.modulePermissions & MODULE_PERMISSIONS.Dashboard) > 0
          ) {
            arr.push({
              id: "dashboard",
              label: "left_menu.dashboard",
              icon: <Dashboard />,
            });
          }
          setCategories(arr);
        })
        .catch(console.log);
    }
  }, [t, profile]); //authenticationContext

  return (
    <React.Suspense fallback="loading">
      <Drawer
        //variant="permanent"
        //{...props}
        open={drawerOpen}
        PaperProps={props.PaperProps}
        sx={props.sx}
        variant={props.variant}
        onClose={props.onClose}
        onKeyDown={props.onKeyDown}
      >
        <PerfectScrollbar style={{ "& .ps__rail-y": { zIndex: 2 } }}>
          <List disablePadding>
            <Box
              sx={{
                width: "100%",
                bgcolor: theme.palette.primary.dark,
                position: "sticky",
                top: "0px",
                zIndex: 1,
              }}
            >
              <Box
                component="img"
                src={curTheme === "orange" ? Logo1 : Logo}
                sx={{
                  ...heading,
                }}
                height={"53px"}
                pl={isMdUp ? "0px" : "28px"}
              />
            </Box>
            {/* <Icon sx={{ ...heading, fontSize: 22 }}>
              {t('flex_workflow')}
            </Icon> */}

            {categories != null ? (
              categories.map(
                ({
                  id,
                  children,
                  icon,
                  label,
                  active,
                  endAdornment,
                  url,
                  showIndicator,
                }) =>
                  children && children.length > 0 ? (
                    <>
                      <ListItemButton
                        key={id}
                        onClick={() => {
                          setExpandClicked(!expandClicked);
                          handleExpand(id, null);
                        }}
                        sx={item}
                        selected={id === isSelected ? true : false}
                        onMouseEnter={() => setMouseOverMenu(id)}
                        onMouseLeave={() => setMouseOverMenu(undefined)}
                      >
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText primary={t(label)} />
                        {endAdornment ? (
                          <HoverIcon active={id === mouseOverMenu}>
                            {endAdornment}
                          </HoverIcon>
                        ) : null}
                        {expandedComponents
                          .map((obj) => obj.id)
                          .includes(id) ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={expandedComponents
                          .map((obj) => obj.id)
                          .includes(id)}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {isLoading
                            ? Array.from(new Array(SkeletonItems)).map(
                                (_, index) => (
                                  <Collapse
                                    in={expandClicked} //isLoading &&    expandclicked
                                    timeout="auto"
                                    unmountOnExit
                                    key={`item_${index}`}
                                  >
                                    <ListItemButton sx={collapsedItem}>
                                      <ListItemIcon>
                                        <Skeleton
                                          variant={"circular"}
                                          sx={collapsedItemLoadingColor}
                                        />
                                      </ListItemIcon>
                                      <ListItemText>
                                        <Skeleton
                                          sx={LoadingBarColor} //loadingbarColor
                                        />
                                      </ListItemText>
                                    </ListItemButton>
                                  </Collapse>
                                )
                              )
                            : children
                                .filter(
                                  (child) =>
                                    !child.children || child.children.length > 0
                                )
                                .map(
                                  ({
                                    id: childId,
                                    icon: childIcon,
                                    active: childActive,
                                    label: childLabel,
                                    children: child,
                                  }) => (
                                    <>
                                      {child ? (
                                        <>
                                          <ListItemButton
                                            key={childId}
                                            onClick={() =>
                                              handleExpandByChild(childId, id)
                                            }
                                            sx={collapsedItem}
                                            selected={
                                              childId === isSelected
                                                ? true
                                                : false
                                            }
                                          >
                                            <ListItemIcon>
                                              {childIcon}
                                            </ListItemIcon>
                                            <ListItemText primary={childId} />
                                            {expandedComponents[
                                              expandedComponents
                                                .map((obj) => obj.id)
                                                .indexOf(id)
                                            ] &&
                                            expandedComponents[
                                              expandedComponents
                                                .map((obj) => obj.id)
                                                .indexOf(id)
                                            ].child.includes(childId) ? (
                                              <ExpandLess />
                                            ) : (
                                              <ExpandMore />
                                            )}
                                          </ListItemButton>
                                          <Collapse
                                            in={
                                              expandedComponents[
                                                expandedComponents
                                                  .map((obj) => obj.id)
                                                  .indexOf(id)
                                              ] &&
                                              expandedComponents[
                                                expandedComponents
                                                  .map((obj) => obj.id)
                                                  .indexOf(id)
                                              ].child.includes(childId)
                                            }
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <List
                                              component="div"
                                              disablePadding
                                            >
                                              {child?.map(
                                                ({ name: caseName, uuid }) => (
                                                  <ListItemButton
                                                    component={Link}
                                                    to={`/workspace/${uuid}`}
                                                    onClick={() =>
                                                      handleCloseDrawerWithID(
                                                        uuid
                                                      )
                                                    }
                                                    sx={collapsedcaseItem}
                                                    selected={
                                                      uuid === isSelected
                                                    }
                                                    onMouseEnter={() =>
                                                      setMouseOverMenu(uuid)
                                                    }
                                                    onMouseLeave={() =>
                                                      setMouseOverMenu(
                                                        undefined
                                                      )
                                                    }
                                                  >
                                                    <ListItemText
                                                      primary={caseName}
                                                    ></ListItemText>
                                                    <HoverIcon
                                                      active={
                                                        uuid === mouseOverMenu
                                                      }
                                                    >
                                                      <StarOutlineIcon color="warning" />
                                                    </HoverIcon>
                                                  </ListItemButton>
                                                )
                                              )}
                                            </List>
                                          </Collapse>
                                        </>
                                      ) : (
                                        <ListItemButton
                                          key={childId}
                                          component={Link}
                                          to={`/${childId
                                            .split("-")
                                            .join("/")}`}
                                          onClick={() =>
                                            handleCloseDrawerWithID(childId)
                                          }
                                          sx={collapsedItem}
                                          selected={childId === isSelected}
                                        >
                                          <ListItemIcon>
                                            {childIcon}
                                          </ListItemIcon>
                                          <ListItemText>
                                            {t(childLabel)}
                                          </ListItemText>
                                        </ListItemButton>
                                      )}
                                    </>
                                  )
                                )}
                        </List>
                      </Collapse>
                    </>
                  ) : (
                    <ListItemButton
                      key={id}
                      component={Link}
                      to={url ? url : `/${id}`}
                      target={url ? "_blank" : ""}
                      rel={url ? "noopener noreferrer" : ""}
                      onClick={() => handleCloseDrawerWithID(id)}
                      sx={item}
                      selected={id === isSelected}
                    >
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText>{t(label)}</ListItemText>
                      {showIndicator ? (
                        messageCount > 0 ? (
                          <div
                            style={{
                              borderRadius: "40px",
                              backgroundColor: "gold",
                              width: "8px",
                              height: "8px",
                            }}
                          />
                        ) : null
                      ) : null}
                    </ListItemButton>
                  )
              )
            ) : (
              <div
                style={{
                  maskImage:
                    "linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 80%)",
                }}
              >
                {Array.from(new Array(5)).map((item, index) => (
                  <ListItemButton key={`ske_${index}`}>
                    <ListItemIcon>
                      <Skeleton
                        variant={"circular"}
                        width={"24px"}
                        height={"24px"}
                        sx={{ bgcolor: "rgba(255,255,255,0.3)" }}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Skeleton
                        sx={{ bgcolor: "rgba(255,255,255,0.3)" }}
                        width={"100%"}
                        height={"16px"}
                        variant={"rounded"}
                      />
                    </ListItemText>
                  </ListItemButton>
                ))}
              </div>
            )}
          </List>
        </PerfectScrollbar>
      </Drawer>
      <SharedDialog
        isOpen={alertDialog}
        onClose={handleAlertDialogClose}
        content={alertContent}
        buttons={[
          {
            text: t("administrative_console.report_page.confirm"),
            action: handleAlertDialogClose,
          },
        ]}
      ></SharedDialog>
    </React.Suspense>
  );
}
