import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import Inbox from "./components/Inbox/Inbox";
import Outbox from "./components/Outbox/Outbox";
import Settings from "./components/Settings/Settings";
import FormRuntime from "./components/FormRuntime/FormRuntime_new";
import CaseList from "./components/CaseList/CaseList";
import Profile from "./components/Profile/Profile";
import Report from "./components/Report/Report";
import AdministrativeConsole from "./components/Profile/AdminConsole/AdministrativeConsole";
import { useLocation } from "react-router-dom";
import CaseDesigner from "./components/Designer/Case/CaseDesigner";
import FormDesigner from "./components/Designer/Form/FormDesigner";
import Dashboard from "./components/Dashboard/Dashboard";
import PageNotFound from "./components/PageNotFound";
import ManageCase from "./components/CaseList/ManageCase";
import PublishTemplate from "./components/CaseList/PublishTemplate";
import Messages from "./components/Messages/Messages";

export default function RouteConfig(props) {
  const setTitle = props.settitle;
  const isMdUp = props.isMdUp;
  const setFormIdentity = props.setFormIdentity;
  const location = useLocation();
  let from;
  if (location) {
    from = location.state;
    from = from ? from["from"] : null;
  }
  return (
    <Routes>
      <Route exact path="/" element={<Navigate replace to="/inbox" />} />
      <Route
        path="/inbox"
        element={<Inbox settitle={setTitle} isMdUp={isMdUp} />}
      >
        <Route
          path=":pages"
          element={<Inbox settitle={setTitle} isMdUp={isMdUp} />}
        />
      </Route>
      <Route path="/outbox" element={<Outbox settitle={setTitle} />}>
        <Route path=":pages" element={<Outbox settitle={setTitle} />} />
      </Route>
      <Route path="/messages" element={<Messages setTitle={setTitle} />} />
      <Route path="/report" element={<Report settitle={setTitle} />} />
      <Route path="/settings" element={<Settings settitle={setTitle} />} />
      <Route
        path="/profile"
        element={<Profile settitle={setTitle} Security={from} />}
      />
      <Route
        path="/admin-console"
        element={<AdministrativeConsole settitle={setTitle} Security={from} />}
      >
        <Route path=":page/" />
      </Route>
      <Route
        path="/publish-template"
        element={<PublishTemplate setTitle={setTitle} />}
      />
      {/* open current task or submitted task */}
      <Route
        path="/case/:CaseSID/:PlanItemSID/"
        element={
          <FormRuntime settitle={setTitle} setFormIdentity={setFormIdentity} />
        }
      />
      {/* trigger new case with specified task */}
      <Route
        path="/case/:CaseSID/:PlanItemSID/triggered/"
        element={
          <FormRuntime settitle={setTitle} setFormIdentity={setFormIdentity} />
        }
      />
      {/* trigger new case with specified case */}
      {/* <Route
        path="/case/:CaseSID/:uuid/triggered/"
        element={
          <FormRuntime
            settitle={setTitle}
            setFormIdentity={setFormIdentity}
            IsOutbox={true}
          />
        }
      /> */}
      {/* trigger new case by case template uuid */}
      <Route
        path="/case/:uuid/new/"
        element={
          <FormRuntime settitle={setTitle} setFormIdentity={setFormIdentity} />
        }
      />
      {/* open inbox or latest task by case sid from workspace */}
      <Route
        path="/case/:CaseSID/"
        element={
          <FormRuntime settitle={setTitle} setFormIdentity={setFormIdentity} />
        }
      />
      {/* trigger optional task by case sid and plan item tpl sid */}
      <Route
        path="/case/:CaseSID/:PlanItemTplSID/optional/"
        element={
          <FormRuntime settitle={setTitle} setFormIdentity={setFormIdentity} />
        }
      />
      <Route
        path="/workspace/:uuid"
        element={
          <CaseList settitle={setTitle} setFormIdentity={setFormIdentity} />
        }
      >
        <Route
          l
          path="/workspace/:uuid/manage"
          element={<ManageCase setTitle={setTitle} />}
        />
        <Route
          path=":pages"
          element={
            <CaseList settitle={setTitle} setFormIdentity={setFormIdentity} />
          }
        />
      </Route>
      <Route
        path="/designer/case/"
        element={<CaseDesigner setTitle={setTitle} />}
      />
      <Route
        path="/designer/form/"
        element={<FormDesigner setTitle={setTitle} />}
      />
      <Route path="/dms" element={<Navigate to="/dms" />} />
      <Route path="/dashboard" element={<Dashboard settitle={setTitle} />} />
      <Route path="*" element={<PageNotFound setTitle={setTitle} />} />
    </Routes>
  );
}
