import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const PrimaryPositionTable = (props) => {
  const {
    PrimaryPositionObj,
    onClickFunction,
    AvailablePosLoading,
    AvailableArr,
  } = props;

  const { t } = useTranslation();

  return (
    <FormControl sx={{ textAlign: "start", overflow: "auto", height: "200px" }}>
      {AvailablePosLoading ? (
        <FormLabel id={"loading"}>
          {t("profile.proxy_and_delegation.loading_text")}
        </FormLabel>
      ) : AvailableArr.length > 0 ? (
        AvailableArr.map((c) => {
          return (
            <>
              <FormLabel id={c.Name} focused={false}>
                {c.Name}
              </FormLabel>
              <RadioGroup
                aria-labelledby={c.Name}
                value={PrimaryPositionObj[c.SID] ?? null}
                name={c.Name}
                onChange={(e) => onClickFunction(e, c.SID)}
              >
                {c.Positions.map((p) => (
                  <FormControlLabel
                    value={p.UUID}
                    control={<Radio />}
                    label={p.Name}
                  />
                ))}
              </RadioGroup>
            </>
          );
        })
      ) : (
        <FormLabel id={"no_position"}>
          {t("profile.no_available_positions")}
        </FormLabel>
      )}
    </FormControl>
  );
};

PrimaryPositionTable.propTypes = {
  PrimaryPositionArr: PropTypes.object,
  onClickFunction: PropTypes.func,
  AvailablePosLoading: PropTypes.bool,
  AvailableArr: PropTypes.array,
};

export default PrimaryPositionTable;
