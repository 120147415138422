import React, { useContext, createContext, useState } from "react";
import { CallApiWithContext } from "../helpers/ApiHelper";
import { useAuthentication } from "./AuthenticationProvider";
import urlJoin from "url-join";
import { resServerBaseUrl } from "../Config";

const ProfileProvider = (props) => {
  const [profile, setProfile] = useState(null); //image temp
  const [uiSettings, setUISettings] = useState({ drawOpen: false });
  const [cachedAvatars, setCachedAvatars] = useState([]);
  const authenticationContext = useAuthentication();

  const getCachedAvatar = (userId) => {
    const avatar = cachedAvatars.find((p) => p.userId === userId);

    return avatar?.avatar ? `data:image/jpeg;base64,${avatar.avatar}` : null;
  };

  const getAvatars = () => {
    if (authenticationContext[0]) {
      const url = urlJoin(resServerBaseUrl, "User/GetUserAvatars");
      const data = {};

      CallApiWithContext(url, authenticationContext, JSON.stringify(data))
        .then((res) => {
          if (res) {
            setCachedAvatars(res);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  React.useEffect(() => {
    getAvatars();
  }, [authenticationContext]);

  return (
    <Context.Provider
      value={[profile, setProfile, getCachedAvatar, uiSettings, setUISettings]}
      {...props}
    />
  );
};

const Context = createContext(null);

const useProfile = () => useContext(Context);

export { ProfileProvider, useProfile };
