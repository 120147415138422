import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Skeleton } from "@mui/material";
import { Paper } from "@mui/material";
import { IconButton } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useTheme } from "@mui/material/styles";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import urlJoin from "url-join";
import useSize from "@react-hook/size";
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { CallApiWithContext } from "../../helpers/ApiHelper";
import { resServerBaseUrl } from "../../Config";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { ConvertUTCDateToLocal } from "../../shared/Utils";
import SharedDialog from "../../shared/SharedDialog";

dayjs.extend(utc);
dayjs.extend(tz);

export const ProxyDelegateType = {
  Proxy: 0,
  Delegation: 1,
};

const ProxyDelegationEditor = (props) => {
  const authenticationContext = useAuthentication();
  const { type, originalUserUUID } = props;

  const refresh = () => {
    if (!isLoading) {
      setIsLoading(true);
    }
    if (originalUserUUID != "") {
      const FindWFNameByID = [];
      const url = urlJoin(resServerBaseUrl, "/Case/GetCaseTemplatesByUserUUID");
      const user = JSON.stringify({ UserUUID: originalUserUUID });
      CallApiWithContext(url, authenticationContext, user)
        .then((res) => {
          for (let i = 0; i < res.length; i++) {
            FindWFNameByID.push(
              ...res[i].templateInfos.map((info) => ({
                ID: info.uuid,
                label: info.name,
              }))
            );
          }
          FindWFNameByID.push({
            ID: "00000000-0000-0000-0000-000000000000",
            label: "All Workflow Templates",
          });
          FindWFNameByID.sort((a, b) => {
            const labelA = a.label.toUpperCase();
            const labelB = b.label.toUpperCase();
            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }
            return 0;
          });
          setCaseTplOptions(FindWFNameByID);
        })
        .catch(() => {
          setNoRecord(true);
        })
        .finally(() => {
          const getUserProxyDelegationListURL = urlJoin(
            resServerBaseUrl,
            "/Case/GetUserProxyDelegationList"
          );
          let u = JSON.stringify({ UserUUID: originalUserUUID });
          CallApiWithContext(
            getUserProxyDelegationListURL,
            authenticationContext,
            u
          )
            .then((getListResponse) => {
              if (getListResponse) {
                const loadList = [];
                getListResponse.forEach((element) => {
                  if (element.type == type) {
                    element.tplName = FindWFNameByID.find(
                      (f) => f.ID === element.caseTplUUID
                    ).label;
                    loadList.push(createDataByElement(element));
                  }
                });
                setLoadTable(loadList);
                setNoRecord(loadList.length == 0);
              } else {
                setNoRecord(true);
              }
            })
            .catch((error) => {
              console.log(error);
              setNoRecord(true);
            })
            .finally(() => {
              setIsLoading(false);
            });
        });
    }
  };

  const [loadTable, setLoadTable] = React.useState([]);
  const { t } = useTranslation();
  const proxyHeaders = [
    t("profile.proxy_and_delegation.big_proxy_user"),
    t("profile.proxy_and_delegation.big_effective_from"),
    t("profile.proxy_and_delegation.big_effective_to"),
    t("profile.proxy_and_delegation.big_specified_workflow"),
    t("profile.proxy_and_delegation.big_actions"),
  ];
  const delegationHeaders = [
    t("profile.proxy_and_delegation.big_delegation_user"),
    t("profile.proxy_and_delegation.big_effective_from"),
    t("profile.proxy_and_delegation.big_effective_to"),
    t("profile.proxy_and_delegation.big_specified_workflow"),
    t("profile.proxy_and_delegation.big_actions"),
  ];
  const headers =
    type === ProxyDelegateType.Proxy ? proxyHeaders : delegationHeaders;
  const [noRecord, setNoRecord] = React.useState(false);
  const [disableSave, setDisableSave] = React.useState(true);
  const [isDateIncorrect, setIsDateIncorrect] = React.useState(true);
  const [isLoadingAddSave, setIsLoadingAddSave] = React.useState(false);
  const [isLoadingOpenDeletedialog, setIsLoadingOpenDeletedialog] =
    React.useState(false);
  const [isLoadingReallyDelete, setIsLoadingReallyDelete] =
    React.useState(false);
  const [isLoadingAutoComUserName, setIsLoadingAutoComUserName] =
    React.useState(false);
  const [isLoadingAutoComSPWF, setIsLoadingAutoComSPWF] = React.useState(false);
  const [isAdd, setIsAdd] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
    React.useState(false);
  const GUIDpattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const [proxyUserOptions, setProxyUserOptions] = React.useState([]);
  const [caseTplOptions, setCaseTplOptions] = React.useState([]);
  const [theEditingRowID, setTheEditingRowID] = React.useState(0);
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const handleAlertDialogClose = () => {
    setAlertDialog(false);
  };

  const [editedRows, setEditedRows] = React.useState({
    rowSID: "",
    proxyUser: "",
    proxyUserID: "",
    effectiveFromDate: "",
    // effectiveFromTime: "",
    effectiveToDate: "",
    // effectiveToTime: "",
    specifiedWorkflow: "",
    type: "",
    userId: "",
    caseTplUUID: "",
  });
  const [deleteRows, setDeleteRows] = React.useState({
    SID: "",
    OriginalUserID: "",
    UserID: "",
    VaildDateFrom: "",
    VaildDateTo: "",
    CaseTplUUID: "",
    Type: "",
  });

  const timezone = dayjs.tz.guess();

  const createDataByElement = (element) => {
    return {
      sid: element.sid,
      proxyUser: element.userName,
      effectiveFrom: dayjs
        .utc(element.vaildDateFrom, "YYYY-MM-DDTHH:mm:ss")
        .tz(timezone),
      effectiveTo: dayjs
        .utc(element.vaildDateTo, "YYYY-MM-DDTHH:mm:ss")
        .tz(timezone),
      specifiedWorkflow: element.tplName,
      type: element.type,
      userId: element.userID,
    };
  };
  const handleCloseDialog = (event) => {
    setOpenDialog(false);
  };
  const handleOpenConfirmDeleteDialog = (row) => {
    setIsLoadingOpenDeletedialog(true);
    let caseTpl = "";

    // const caseTplURL = urlJoin(resServerBaseUrl, "/Case/GetCaseTemplates")
    // CallApiWithContext(caseTplURL, authenticationContext, isAdmin)
    //     .then(response => {
    //         if (response) {

    // response.forEach(element => {
    //     caseTpl = element.templateInfos.find(info => {
    //         if (info.name === row.specifiedWorkflow) {
    //             return info;
    //         }
    //     })
    //     if (row.specifiedWorkflow === "All Workflow Templates") {
    //         caseTpl = { uuid: "00000000-0000-0000-0000-000000000000" };
    //     }
    // })

    caseTplOptions.forEach((element) => {
      if (element.label == row.specifiedWorkflow) {
        caseTpl = element.ID;
      }
    });

    setDeleteRows({
      SID: row.sid,
      OriginalUserID: originalUserUUID,
      UserID: row.userId,
      VaildDateFrom: row.effectiveFrom,
      VaildDateTo: row.effectiveTo,
      // CaseTplUUID: caseTpl.uuid,
      CaseTplUUID: caseTpl,
      Type: type,
    });

    setIsLoadingOpenDeletedialog(false);
    setOpenConfirmDeleteDialog(true);
    //     }
    // }).catch(error => {
    //     console.log(error);
    // });
  };
  const handleCloseConfirmDeleteDialog = (event) => {
    setOpenConfirmDeleteDialog(false);
  };
  const handleDataChange = (event, type) => {
    switch (type) {
      case "proxyUser":
        setEditedRows({
          ...editedRows,
          [type]: event?.label ?? "",
          proxyUserUUID: event?.uuid ?? "",
        });
        break;
      case "specifiedWorkflow":
        setEditedRows({
          ...editedRows,
          [type]: event?.label ?? "",
          caseTplUUID: event?.ID ?? "",
        });
        break;
      case "effectiveFromDate":
        setEditedRows({ ...editedRows, effectiveFromDate: event.$d });
        break;
      // case "effectiveFromTime":
      //   setEditedRows({ ...editedRows, effectiveFromTime: event.$d });
      //   break;
      case "effectiveToDate":
        setEditedRows({ ...editedRows, effectiveToDate: event.$d });
        break;
      // case "effectiveToTime":
      //   setEditedRows({ ...editedRows, effectiveToTime: event.$d });
      //   break;
    }
  };
  const handleAdd = (event) => {
    /* if (
      originalUserID == null ||
      originalUserID == "undefined" ||
      originalUserID == ""
    ) {
      setAlertDialog(true);
      setAlertContent(t("profile.proxy_and_delegation.please_select_user"));
      setAlertTitle(t("profile.proxy_and_delegation.error"));
      return;
    }*/
    //setIsLoadingAutoComUserName(true);
    // setIsLoadingAutoComSPWF(true);
    setIsAdd(true);
    setOpenDialog(true);
    setTheEditingRowID("");
    setEditedRows({
      ...editedRows,
      proxyUser: "",
      effectiveFromDate: dayjs(),
      // effectiveFromTime: new dayjs(Date("2022-01-01T00:00:00")),
      effectiveToDate: dayjs(),
      // effectiveToTime: new dayjs(Date("2022-01-01T23:59:00")),
      caseTplUUID: "00000000-0000-0000-0000-000000000000",
      specifiedWorkflow: "All Workflow Templates",
      type: type,
    });
  };
  const handleEdit = (row) => {
    setIsLoadingAutoComUserName(true);
    setIsLoadingAutoComSPWF(true);
    setIsAdd(false);
    setOpenDialog(true);
    setTheEditingRowID(row.rowId);
    setEditedRows({
      ...editedRows,
      sid: row.sid,
      proxyUser: row.proxyUser,
      proxyUserID: row.userId,
      effectiveFromDate: row.effectiveFrom,
      effectiveFromTime: row.effectiveFrom,
      effectiveToDate: row.effectiveTo,
      effectiveToTime: row.effectiveTo,
      specifiedWorkflow: row.specifiedWorkflow,
      caseTplUUID: caseTplOptions.find((c) => c.label === row.specifiedWorkflow)
        .ID,
      type: type,
    });
  };
  const handleDelete = () => {
    setIsLoadingReallyDelete(true);

    const urlDelData = urlJoin(
      resServerBaseUrl,
      "/Case/DeleteProxyDelegationUser"
    );
    CallApiWithContext(
      urlDelData,
      authenticationContext,
      JSON.stringify({ SID: deleteRows.SID })
    )
      .then((response) => {
        setOpenConfirmDeleteDialog(false);
        setIsLoadingReallyDelete(false);

        refresh();
      })
      .catch((error) => {
        console.log(error);
        setOpenConfirmDeleteDialog(false);
      })
      .finally(() => {
        setIsLoadingReallyDelete(false);
      });
  };
  const handleSave = (event) => {
    setIsLoadingAddSave(true);
    var saveData = {
      SID: editedRows.sid,
      OriginalUserUUID: originalUserUUID,
      UserUUID:
        editedRows?.proxyUserUUID ??
        proxyUserOptions?.find((o) => o.ID === editedRows.proxyUserID)?.uuid ??
        null,
      VaildDateFrom: dayjs
        .utc(editedRows.effectiveFromDate)
        .format("YYYY-MM-DDTHH:mm:ss"),
      VaildDateTo: dayjs
        .utc(editedRows.effectiveToDate)
        .format("YYYY-MM-DDTHH:mm:ss"),
      CaseTplUUID: editedRows.caseTplUUID,
      Type: editedRows.type,
    };
    if (isAdd) {
      const urlAddSave = urlJoin(
        resServerBaseUrl,
        "/Case/AddProxyDelegationUser"
      );
      CallApiWithContext(
        urlAddSave,
        authenticationContext,
        JSON.stringify(saveData)
      )
        .then((response) => {
          if (response.Status == "OK") {
            setIsLoadingAddSave(false);
            setOpenDialog(false);

            refresh();
          } else if (response.Status == "Deadlock error") {
            let wrongData = JSON.parse(response.OverlapData);
            setAlertDialog(true);
            setAlertContent(
              t("profile.proxy_and_delegation.delegation_overlaped") +
                ": " +
                "\n" +
                "\n" +
                t("profile.proxy_and_delegation.user") +
                ": " +
                proxyUserOptions.find((n) => {
                  return n.id == wrongData.crashedOriginalID;
                }).name +
                "\n" +
                t("profile.proxy_and_delegation.delegation_user") +
                ": " +
                proxyUserOptions.find((n) => {
                  return n.id == wrongData.crashedID;
                }).name +
                "\n" +
                t("profile.proxy_and_delegation.effective_from") +
                ": " +
                ConvertUTCDateToLocal(
                  new Date(wrongData.crashedVaildDateFrom)
                ) +
                "\n" +
                t("profile.proxy_and_delegation.effective_to") +
                ": " +
                ConvertUTCDateToLocal(new Date(wrongData.crashedVaildDateTo)) +
                "\n" +
                t("administrative_console.report_page.case_template") +
                ": " +
                caseTplOptions.find((c) => {
                  return c.ID == wrongData.crashedCaseTplUUID;
                }).label +
                "\n" +
                "\n" +
                t("profile.proxy_and_delegation.please_check_the_form")
            );
            setAlertTitle(t("profile.proxy_and_delegation.error"));
            setIsLoadingAddSave(false);
            setOpenDialog(true);
          } else {
            setIsLoadingAddSave(false);
            setAlertDialog(true);
            setAlertContent("Bad Request Error");
            setAlertTitle(t("profile.proxy_and_delegation.error"));
          }
        })
        .catch((error) => {
          setAlertDialog(true);
          setAlertContent(t("profile.proxy_and_delegation.error"));
          setAlertTitle(t("profile.proxy_and_delegation.error"));
          console.log(error);
          setIsLoadingAddSave(false);
        });
    } else {
      let editedData = Object.assign({}, saveData, {
        Id: theEditingRowID,
      });
      const urlEditSave = urlJoin(
        resServerBaseUrl,
        "/Case/UpdateProxyDelegationUser"
      );
      CallApiWithContext(
        urlEditSave,
        authenticationContext,
        JSON.stringify(editedData)
      )
        .then((response) => {
          if (response.Status == "OK") {
            setIsLoadingAddSave(false);
            setOpenDialog(false);

            refresh();
          } else if (response.Status == "Deadlock error") {
            let wrongData = JSON.parse(response.OverlapData);
            setAlertDialog(true);
            setAlertContent(
              t("profile.proxy_and_delegation.delegation_overlaped") +
                ": " +
                "\n" +
                "\n" +
                t("profile.proxy_and_delegation.user") +
                ": " +
                proxyUserOptions.find((n) => {
                  return n.id == wrongData.crashedOriginalID;
                }).name +
                "\n" +
                t("profile.proxy_and_delegation.delegation_user") +
                ": " +
                proxyUserOptions.find((n) => {
                  return n.id == wrongData.crashedID;
                }).name +
                "\n" +
                t("profile.proxy_and_delegation.effective_from") +
                ": " +
                ConvertUTCDateToLocal(
                  new Date(wrongData.crashedVaildDateFrom)
                ) +
                "\n" +
                t("profile.proxy_and_delegation.effective_to") +
                ": " +
                ConvertUTCDateToLocal(new Date(wrongData.crashedVaildDateTo)) +
                "\n" +
                t("administrative_console.report_page.case_template") +
                ": " +
                caseTplOptions.find((c) => {
                  return c.ID == wrongData.crashedCaseTplUUID;
                }).label +
                "\n" +
                "\n" +
                t("profile.proxy_and_delegation.please_check_the_form")
            );
            setAlertTitle(t("profile.proxy_and_delegation.error"));
            setIsLoadingAddSave(false);
            setOpenDialog(true);
          } else {
            throw new Error(t("profile.proxy_and_delegation.error"));
          }
        })
        .catch((error) => {
          setAlertDialog(true);
          setAlertContent(error);
          setAlertTitle(t("profile.proxy_and_delegation.error"));
          console.log(error);
          setIsLoadingAddSave(false);
        })
        .finally(() => {
          setIsLoadingAddSave(false);
        });
    }
  };

  React.useEffect(() => {
    if (
      editedRows.effectiveFromDate !== "" &&
      editedRows.effectiveToDate !== ""
      // &&
      // editedRows.effectiveFromTime !== "" &&
      // editedRows.effectiveToTime !== ""
    ) {
      if (
        dayjs(editedRows.effectiveToDate) >= dayjs(editedRows.effectiveFromDate)
      ) {
        setIsDateIncorrect(false);
        if (
          editedRows.proxyUser !== "" &&
          editedRows.specifiedWorkflow !== ""
        ) {
          setDisableSave(false);
        }
      } else {
        setIsDateIncorrect(true);
        setDisableSave(true);
      }
    } else {
      setIsDateIncorrect(false);
      setDisableSave(true);
    }
  }, [editedRows]);

  React.useEffect(() => {
    refresh();
  }, [originalUserUUID]);

  React.useEffect(() => {
    const userNameURL = urlJoin(resServerBaseUrl, "/User/GetUsersByUserUUID");
    if ((originalUserUUID ?? "") !== "") {
      const data = JSON.stringify({ UUID: originalUserUUID });
      CallApiWithContext(userNameURL, authenticationContext, data)
        .then((response) => {
          if (response) {
            let userNameArr = new Array();
            let getUserNameArr = new Array();
            getUserNameArr = Object.keys(response).map((key) => {
              return {
                id: response[key].id,
                name: response[key].login,
                uuid: response[key].uuid,
              };
            });
            getUserNameArr.forEach((element) => {
              let forPushUserIDName = {
                ID: element.id,
                label: element.name,
                uuid: element.uuid,
              };
              if (GUIDpattern.test(forPushUserIDName.label) === true) {
              } else {
                userNameArr.push(forPushUserIDName);
              }
            });
            userNameArr = userNameArr.filter(
              (L) =>
                L.label != "Robot" &&
                L.label != "00000000-0000-0000-0000-000000000000"
            );
            userNameArr.sort((a, b) => {
              const labelA = a.label.toUpperCase();
              const labelB = b.label.toUpperCase();
              if (labelA < labelB) {
                return -1;
              }
              if (labelA > labelB) {
                return 1;
              }
              return 0;
            });
            setProxyUserOptions(userNameArr);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoadingAutoComUserName(false);
        });
    } else {
      setIsLoadingAutoComUserName(false);
    }
  }, [originalUserUUID]);

  return (
    <Grid container xs={12} spacing={1}>
      <Grid item sx={{ textAlign: "right" }} xs={12}>
        {isLoading ? (
          <Skeleton
            variant="circular"
            sx={{
              display: "flex",
              justifySelf: "end",
              width: "25px",
              height: "25px",
            }}
          />
        ) : (
          <IconButton
            onClick={handleAdd}
            color="primary"
            disabled={!originalUserUUID}
          >
            <PersonAddAlt1Icon fontSize="small" />
          </IconButton>
        )}
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullScreen={isSmallDevice}
        >
          <DialogTitle>
            {isAdd
              ? t("profile.proxy_and_delegation.add")
              : t("profile.proxy_and_delegation.edit")}
            {type === ProxyDelegateType.Proxy
              ? t("profile.proxy_and_delegation.proxy_user")
              : t("profile.proxy_and_delegation.delegation_user")}
          </DialogTitle>
          <DialogContent style={{ overflow: "auto" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid
                container
                direction="column"
                spacing={2}
                p={1}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <DateTimePicker
                    sx={{ width: "350px" }}
                    label={t("profile.proxy_and_delegation.effective_from")}
                    ampm={false}
                    value={dayjs(editedRows.effectiveFromDate)}
                    renderInput={(params) => (
                      <TextField {...params} error={isDateIncorrect} />
                    )}
                    onChange={(event) =>
                      handleDataChange(event, "effectiveFromDate")
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateTimePicker
                    sx={{ width: "350px" }}
                    label={t("profile.proxy_and_delegation.effective_to")}
                    ampm={false}
                    value={dayjs(editedRows.effectiveToDate)}
                    renderInput={(params) => (
                      <TextField {...params} error={isDateIncorrect} />
                    )}
                    onChange={(event) =>
                      handleDataChange(event, "effectiveToDate")
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    sx={{ width: "350px" }}
                    loading={isLoadingAutoComUserName}
                    loadingText={"Loading..."}
                    id="proxyUser"
                    name="proxyUser"
                    defaultValue={editedRows.proxyUser}
                    onChange={(event, v) => handleDataChange(v, "proxyUser")}
                    options={proxyUserOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          type === ProxyDelegateType.Proxy
                            ? t("profile.proxy_and_delegation.proxy_user")
                            : t("profile.proxy_and_delegation.delegation_user")
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    sx={{ width: "350px" }}
                    loading={isLoadingAutoComSPWF}
                    loadingText={"Loading..."}
                    id="specifiedWorkflow"
                    name="specifiedWorkflow"
                    defaultValue={editedRows.specifiedWorkflow}
                    onChange={(event, v) =>
                      handleDataChange(v, "specifiedWorkflow")
                    }
                    options={caseTplOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t(
                          "profile.proxy_and_delegation.specified_workflow"
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant="contained"
              disabled={disableSave}
              loading={isLoadingAddSave}
              onClick={(event) => handleSave(event)}
            >
              {t("profile.proxy_and_delegation.save")}
            </LoadingButton>
            <Button onClick={handleCloseDialog} color="error">
              {t("profile.proxy_and_delegation.cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>

      <Grid item sx={{ width: "100%" }}>
        {isSmallDevice ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead
                sx={{
                  "& .MuiTableCell-root": {
                    padding: isSmallDevice ? "10px" : "16px",
                  },
                }}
              >
                <TableCell>{headers[0]}</TableCell>
                <TableCell>{headers[3]}</TableCell>
                <TableCell>{headers[4]}</TableCell>
              </TableHead>
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Skeleton
                        variant="text"
                        sx={{ width: "50%", height: "30px" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="text"
                        sx={{ width: "50%", height: "30px" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Grid container direction="row">
                        <Grid>
                          <Skeleton
                            variant="circular"
                            sx={{ width: "25px", height: "25px" }}
                          />
                        </Grid>
                        <Grid sx={{ width: "20px" }}></Grid>
                        <Grid>
                          <Skeleton
                            variant="circular"
                            sx={{ width: "25px", height: "25px" }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : noRecord ? (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {type === ProxyDelegateType.Proxy
                        ? t("profile.proxy_and_delegation.no_info_proxy")
                        : t("profile.proxy_and_delegation.no_info_delegation")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {loadTable.map((row, rowIndex) => {
                    var currentRowIndex = rowIndex;
                    return (
                      <TableRow
                        key={row.name}
                        sx={{
                          "& .MuiTableCell-root": {
                            padding: isSmallDevice ? "10px" : "16px",
                          },

                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.proxyUser}
                        </TableCell>
                        <TableCell>
                          {row.specifiedWorkflow ==
                          "Case Template not found!" ? (
                            <i>
                              {t(
                                "profile.proxy_and_delegation.casetpl_notfound"
                              )}
                            </i>
                          ) : (
                            row.specifiedWorkflow
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            id={currentRowIndex}
                            onClick={() => handleEdit(row)}
                            color="primary"
                          >
                            <BorderColorIcon fontSize="small" />
                          </IconButton>
                          {isLoadingOpenDeletedialog ? (
                            <LoadingButton loading />
                          ) : (
                            <IconButton
                              id={currentRowIndex}
                              onClick={() => handleOpenConfirmDeleteDialog(row)}
                              color="primary"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          )}
                        </TableCell>

                        <Dialog
                          open={openConfirmDeleteDialog}
                          onClose={handleCloseConfirmDeleteDialog}
                        >
                          <Grid>
                            <DialogTitle>
                              {t("profile.proxy_and_delegation.confirm_delete")}
                            </DialogTitle>
                          </Grid>
                          <Grid>
                            <DialogActions>
                              <LoadingButton
                                variant="contained"
                                loading={isLoadingReallyDelete}
                                onClick={(event) => handleDelete(row.sid)}
                              >
                                {t("profile.proxy_and_delegation.delete")}
                              </LoadingButton>
                              <Button
                                onClick={handleCloseConfirmDeleteDialog}
                                color="error"
                              >
                                {t("profile.proxy_and_delegation.cancel")}
                              </Button>
                            </DialogActions>
                          </Grid>
                        </Dialog>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headers.map((title) => (
                    <TableCell>{title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Skeleton
                        variant="text"
                        sx={{ width: "50%", height: "30px" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="text"
                        sx={{ width: "50%", height: "30px" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="text"
                        sx={{ width: "50%", height: "30px" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        variant="text"
                        sx={{ width: "50%", height: "30px" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Grid container direction="row">
                        <Grid>
                          <Skeleton
                            variant="circular"
                            sx={{ width: "25px", height: "25px" }}
                          />
                        </Grid>
                        <Grid sx={{ width: "20px" }}></Grid>
                        <Grid>
                          <Skeleton
                            variant="circular"
                            sx={{ width: "25px", height: "25px" }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : noRecord ? (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {type === ProxyDelegateType.Proxy
                        ? t("profile.proxy_and_delegation.no_info_proxy")
                        : t("profile.proxy_and_delegation.no_info_delegation")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {loadTable.map((row, rowIndex) => {
                    var currentRowIndex = rowIndex;
                    return (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ maxWidth: 150 }}
                        >
                          {row.proxyUser}
                        </TableCell>
                        <TableCell>
                          {dayjs(row.effectiveFrom).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </TableCell>
                        <TableCell>
                          {dayjs(row.effectiveTo).format("YYYY-MM-DD HH:mm:ss")}
                        </TableCell>
                        <TableCell>
                          {row.specifiedWorkflow ==
                          "Case Template not found!" ? (
                            <i>
                              {t(
                                "profile.proxy_and_delegation.casetpl_notfound"
                              )}
                            </i>
                          ) : (
                            row.specifiedWorkflow
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            id={currentRowIndex}
                            onClick={() => handleEdit(row)}
                            color="primary"
                          >
                            <BorderColorIcon fontSize="small" />
                          </IconButton>
                          {isLoadingOpenDeletedialog ? (
                            <LoadingButton loading />
                          ) : (
                            <IconButton
                              id={currentRowIndex}
                              onClick={() => handleOpenConfirmDeleteDialog(row)}
                              color="primary"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          )}
                        </TableCell>

                        <Dialog
                          open={openConfirmDeleteDialog}
                          onClose={handleCloseConfirmDeleteDialog}
                        >
                          <Grid>
                            <DialogTitle>
                              {t("profile.proxy_and_delegation.confirm_delete")}
                            </DialogTitle>
                          </Grid>
                          <Grid>
                            <DialogActions>
                              <LoadingButton
                                variant="contained"
                                loading={isLoadingReallyDelete}
                                onClick={(event) => handleDelete(row.sid)}
                              >
                                {t("profile.proxy_and_delegation.delete")}
                              </LoadingButton>
                              <Button
                                onClick={handleCloseConfirmDeleteDialog}
                                color="error"
                              >
                                {t("profile.proxy_and_delegation.cancel")}
                              </Button>
                            </DialogActions>
                          </Grid>
                        </Dialog>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      </Grid>
      <SharedDialog
        isOpen={alertDialog}
        onClose={handleAlertDialogClose}
        title={alertTitle}
        content={alertContent}
        buttons={[
          {
            text: t("administrative_console.report_page.confirm"),
            action: handleAlertDialogClose,
          },
        ]}
      ></SharedDialog>
    </Grid>
  );
};

export default ProxyDelegationEditor;
