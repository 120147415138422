import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { CallApiWithContext } from "../../helpers/ApiHelper";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { resServerBaseUrl } from "../../Config";
import urlJoin from "url-join";

import {
  AppBar,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Select,
  MenuItem,
  useMediaQuery,
  ListItemText,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import StartIcon from "@mui/icons-material/Start";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTheme } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

import SharedDialog from "../../shared/SharedDialog";
import { maxHeight } from "@mui/system";

const SearchBar = React.forwardRef((props, ref) => {
  const { loading: isLoading, disabled = false } = props;
  const { uuid, pages } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const nav = useNavigate();
  const location = useLocation();
  const authenticationContext = useAuthentication();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [searchByFromDate, setSearchByFromDate] = React.useState(null);
  const [searchByEndDate, setSearchByEndDate] = React.useState(
    dayjs(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        23,
        59,
        59
      )
    )
  );
  const [searchInfo, setSearchInfo] = React.useState("");
  const [searchByStatus, setSearchByStatus] = React.useState([]);
  const [SearchByMilestone, setSearchByMilestone] = React.useState([]);
  const [milestoneOption, setMilestoneOption] = React.useState([]);
  const [isShowAdvanceSearch, setIsShowAdvanceSearch] = React.useState(false);
  const [delay, setDelay] = React.useState(3000);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchor = React.useRef();
  const [loadingBtn, setLoadingBtn] = React.useState("");
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");
  const [error, setError] = React.useState(null);
  const [endDayError, setEndDayError] = React.useState(null);
  const [resetBtn, setResetBtn] = React.useState(false);
  const [isDeleteSearchCriteria, setIsDeleteSearchCriteria] =
    React.useState(false);
  const [isSearching, setIsSearching] = React.useState(false);
  const searchParams = new URLSearchParams(location.search);
  const [searchCriteria, setSearchCriteria] = React.useState([]);

  const useDebounce = (callback, delay) => {
    const latestCallback = React.useRef();
    const [lastCalledAt, setLastCalledAt] = React.useState(null);

    React.useEffect(() => {
      latestCallback.current = callback;
    }, [callback]);

    React.useEffect(() => {
      if (lastCalledAt) {
        const fire = () => {
          setLastCalledAt(null);
          latestCallback.current();
        };

        const fireAt = lastCalledAt + delay;
        const id = setTimeout(fire, fireAt - Date.now());
        return () => clearTimeout(id);
      }
    }, [lastCalledAt, delay]);

    return () => setLastCalledAt(Date.now());
  };
  React.useEffect(() => {
    var url = urlJoin(resServerBaseUrl, "/Report/GetSystemColumnExtraData");
    var data = JSON.stringify({ CaseTemplateUUID: uuid });

    CallApiWithContext(url, authenticationContext, data)
      .then((response) => {
        setMilestoneOption(response.Milestone);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [uuid]);

  const handler = (btn) => {
    if (isLoading && loadingBtn === btn) {
      return true;
    } else {
      return false;
    }
  };

  const handleAlertDialogClose = () => {
    setAlertDialog(false);
  };

  const errorHandle = (error) => {
    var message;
    switch (error) {
      case "invalidDate":
        message = "Your date is not valid";
        break;
      case "minDate":
        message = "Please select the correct date";
        break;
      case "maxDate":
        message = "Please select the correct date";
        break;
      default:
        break;
    }
    setAlertDialog(true);
    setAlertTitle("Warming");
    setAlertContent(message);
  };

  const handleSearchingBtnClick = () => {
    //alert('searchbtnclick');
    //var searchItem = { SearchBoxInfo: searchInfo, SearchByFromDate: searchByFromDate, SearchByEndDate: searchByEndDate };
    //ref(searchItem);
    //setKeyTime("fast");
    setLoadingBtn("save");
    setDelay(500);
    // if (!searchByFromDate) {
    //   setAlertDialog(true);
    //   setAlertTitle("Warning");
    //   setAlertContent("Please choose start day");
    //   return;
    // }
    if (!searchByEndDate) {
      setAlertDialog(true);
      setAlertTitle("Warning");
      setAlertContent("Please choose End day");
      return;
    }
    if (error) {
      errorHandle(error);
      return;
    }
    if (endDayError) {
      errorHandle(endDayError);
      return;
    }
    if (searchByFromDate <= searchByEndDate) {
      setIsSearching(true);
      handleClick();
      setIsShowAdvanceSearch(false);
    } else {
      setAlertDialog(true);
      setAlertContent("Start Date should be early then End Date");
    }
  };

  const handleAdvancedSearchArrowOnClick = (event) => {
    setAnchorEl(anchor.current);
    // setSearchByFromDate(null);
    if (isShowAdvanceSearch) {
      setIsShowAdvanceSearch(false);
    } else {
      setIsShowAdvanceSearch(true);
    }
  };

  const handleResetBtnClick = () => {
    setLoadingBtn("reset");
    setSearchInfo("");
    setSearchByFromDate(null);
    setSearchByEndDate(dayjs(new Date()));
    //var searchItem = { SearchBoxInfo: searchInfo, SearchByFromDate: searchByFromDate, SearchByEndDate: searchByEndDate };
    //ref(searchItem);
    setDelay(500);
    setResetBtn(true);
    handleClick();
    setIsShowAdvanceSearch(false);
  };

  const handleTextBoxInput = (event) => {
    setSearchInfo(event.target.value);
    setDelay(2000);
    searchParams.set("SearchBoxInfo", event.target.value);
    handleClick();
    setIsSearching(true);
  };

  const handleClearSearchInfo = () => {
    setSearchInfo("");
    setDelay(0);
    handleClick();
  };

  const refreshSearch = () => {
    var searchItem = {
      SearchBoxInfo: searchInfo,
      SearchByFromDate: searchByFromDate,
      SearchByEndDate: searchByEndDate,
      SearchByStatus: searchByStatus.toString(),
      SearchByMilestone: SearchByMilestone.toString(),
      SearchError: error,
      SearchEndError: endDayError,
      SearchReset: resetBtn,
    };
    handleUrlAfterSearch();
    ref(searchItem);
    if (resetBtn) {
      const keys = [...searchParams.keys()];
      for (const key of keys) {
        searchParams.delete(key);
      }
    }
    if (!isDeleteSearchCriteria) {
      pages
        ? nav({
            pathname: location.pathname.slice(
              0,
              location.pathname.lastIndexOf("/")
            ),
            search: searchParams.toString(),
          })
        : nav({ search: searchParams.toString() });
    }
    setIsDeleteSearchCriteria(false);
    setResetBtn(false);
  };

  const handleClick = useDebounce(refreshSearch, delay);

  /*
    React.useEffect(() => {
      var time=2000;
      if(keyTime=="slow"){
        time=2000;
      }
      else{
        time=500;
      }

      const delayDebonceFn = setTimeout(() => {
        if (!isFirstInitial) {
          var searchItem = { SearchBoxInfo: searchInfo, SearchByFromDate: searchByFromDate, SearchByEndDate: searchByEndDate };
          ref(searchItem);
        }
        else {
          setIsFirstInitial(false);
        }
      }, 2000);
      return () => clearTimeout(delayDebonceFn);
    }, [searchInfo,  searchByFromDate, searchByEndDate,rerender]);
    */
  //}, [searchInfo, isFirstInitial, ref, searchByFromDate, searchByEndDate]);
  const open = Boolean(isShowAdvanceSearch);
  const id = open ? "simple-popover" : undefined;

  const [hoveringItems, setHoveringItems] = React.useState([]);
  const loadSearchInfoAfterPageChange = () => {
    //Load data from searchParams while the data will be empty after move forward or backward
    setSearchByFromDate(
      searchParams.get("SearchByFromDate")
        ? dayjs(searchParams.get("SearchByFromDate"))
        : null
    );
    setSearchByEndDate(
      searchParams.get("SearchByEndDate")
        ? dayjs(searchParams.get("SearchByEndDate"))
        : dayjs(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              23,
              59,
              59
            )
          )
    );
    setSearchInfo(
      searchParams.get("SearchBoxInfo") ? searchParams.get("SearchBoxInfo") : ""
    );
    setSearchByStatus(
      searchParams.get("SearchByStatus")
        ? searchParams.get("SearchByStatus").split(",")
        : []
    );
    setSearchByMilestone(
      searchParams.get("SearchByMilestone")
        ? searchParams.get("SearchByMilestone").split(",")
        : []
    );
    setIsSearching(true);
  };
  const handleUrlAfterSearch = () => {
    //update search params in url after searching
    if (searchInfo) {
      searchParams.set("SearchBoxInfo", searchInfo);
    } else {
      searchParams.delete("SearchBoxInfo");
    }
    if (searchByFromDate != null) {
      searchParams.set("SearchByFromDate", searchByFromDate.toJSON());
    }
    if (
      searchByEndDate?.format("DD/MM/YYYY") !=
      dayjs(new Date()).format("DD/MM/YYYY")
    ) {
      searchParams.set("SearchByEndDate", searchByEndDate.toJSON());
    }
    if (searchByStatus.length > 0) {
      searchParams.set("SearchByStatus", searchByStatus);
    } else {
      searchParams.delete("SearchByStatus");
    }
    if (SearchByMilestone.length > 0) {
      searchParams.set("SearchByMilestone", SearchByMilestone);
    } else {
      searchParams.delete("SearchByMilestone");
    }
  };
  const deleteSearchCriteria = (key) => {
    setHoveringItems([]);
    //set search info by url to store the value after back or forward to next page
    if (!key.includes("SearchByStatus") && !key.includes("SearchByMilestone")) {
      switch (key) {
        case "SearchBoxInfo":
          setSearchInfo("");
          break;
        case "SearchByFromDate":
          setSearchByFromDate(null);
          break;
        case "SearchByEndDate":
          setSearchByEndDate(dayjs(new Date()));
          break;
      }

      searchParams.delete(key);
    } else if (key.includes("SearchByStatus")) {
      searchCriteria.map((value) => {
        if (value.key == key) {
          setSearchByStatus(
            searchByStatus.splice(key.toString().match(/(\d+)/)[0], 1)
          );
          if (searchByStatus.length > 0) {
            searchParams.set("SearchByStatus", searchByStatus);
          } else {
            searchParams.delete("SearchByStatus");
          }
        }
      });
    } else {
      searchCriteria.map((value) => {
        if (value.key == key) {
          setSearchByMilestone(
            SearchByMilestone.splice(key.toString().match(/(\d+)/)[0], 1)
          );
          if (SearchByMilestone.length > 0) {
            searchParams.set("SearchByMilestone", SearchByMilestone);
          } else {
            searchParams.delete("SearchByMilestone");
          }
        }
      });
    }
    nav({
      pathname:
        location.pathname.lastIndexOf("/") == pages
          ? location.pathname.slice(0, location.pathname.lastIndexOf("/"))
          : location.pathname,
      search: searchParams.toString(),
    });
    setIsDeleteSearchCriteria(true);
    setDelay(0);
    handleClick();
  };
  //Apply search tag after reload or change page
  React.useEffect(() => {
    const searchInfo = searchParams.get("SearchBoxInfo")
      ? searchParams.get("SearchBoxInfo")
      : "";
    const FromDate = searchParams.get("SearchByFromDate")
      ? dayjs(searchParams.get("SearchByFromDate"))
      : null;
    const EndDate = searchParams.get("SearchByEndDate")
      ? dayjs(searchParams.get("SearchByEndDate"))
      : dayjs(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            23,
            59,
            59
          )
        );
    const status = searchParams.get("SearchByStatus")
      ? searchParams.get("SearchByStatus").split(",")
      : [];
    const milestone = searchParams.get("SearchByMilestone")
      ? searchParams.get("SearchByMilestone").split(",")
      : [];

    loadSearchInfoAfterPageChange();
    const statusArray = [];
    status.map((value) => {
      switch (value) {
        case "1":
          statusArray.push(t("search_bar.active"));
          break;
        case "4":
          statusArray.push(t("search_bar.completed"));
          break;
        case "9":
          statusArray.push(t("search_bar.suspended"));
          break;
        case "10":
          statusArray.push(t("search_bar.terminated"));
          break;
      }
    });

    const searchTagInfo = [];
    if (FromDate != null) {
      searchTagInfo.push({
        key: "SearchByFromDate",
        value: FromDate.format("MM/DD/YYYY"),
      });
    }

    if (
      EndDate?.format("MM/DD/YYYY") !=
      dayjs(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          23,
          59,
          59
        )
      ).format("MM/DD/YYYY")
    ) {
      searchTagInfo.push({
        key: "SearchByEndDate",
        value: EndDate.format("L"),
      });
    }
    if (statusArray.length > 0) {
      statusArray.map((value, key) =>
        searchTagInfo.push({ key: `SearchByStatus[${key}]`, value: value })
      );
    }
    if (milestone.length > 0) {
      milestone.map((value, key) => {
        searchTagInfo.push({
          key: `SearchByMilestone[${key}]`,
          value: value.replace(/[|"]/g, ""),
        });
      });
    }

    if (!isDeleteSearchCriteria && !isSearching) {
      var searchItem = {
        SearchBoxInfo: searchInfo,
        SearchByFromDate: FromDate,
        SearchByEndDate: EndDate,
        SearchByStatus: status.toString(),
        SearchByMilestone: milestone.toString(),
      };
      ref(searchItem, pages);
      setIsDeleteSearchCriteria(false);
    }
    const keys = [...searchParams.keys()];
    setHoveringItems([]);
    setSearchCriteria(searchTagInfo);
    setIsSearching(false);
  }, [location]);

  function handleHover(key, isOut) {
    setHoveringItems((prevItems) => {
      if (isOut) {
        return prevItems.filter((item) => item !== key);
      }
      return [...prevItems, key];
    });
  }

  const searchTag = (searchCriteria) => {
    return searchCriteria
      ? searchCriteria.map((s) => {
          if (s.value) {
            return (
              <Grid
                sx={{
                  paddingRight: "8px",
                }}
              >
                <Grid
                  sx={{
                    backgroundColor:
                      theme.palette.mode === "dark" ? "#494949" : "#737373",
                    display: "flex",
                    borderRadius: 50,
                  }}
                  onMouseOver={() => handleHover(s.key, false)}
                  onMouseLeave={() => handleHover(s.key, true)}
                >
                  <IconButton
                    sx={{ padding: "2px" }}
                    onClick={() => {
                      deleteSearchCriteria(s.key);
                    }}
                  >
                    {hoveringItems.includes(s.key) || !isSmUp ? (
                      <HighlightOffIcon
                        sx={{
                          display: "block",
                          color: "#ffffff",
                        }}
                      />
                    ) : s.key == "SearchByFromDate" ? (
                      <StartIcon
                        sx={{
                          display: "block",
                          color: "#ffffff",
                        }}
                      />
                    ) : s.key == "SearchByEndDate" ? (
                      <StartIcon
                        sx={{
                          display: "block",
                          color: "#ffffff",
                          transform: "scaleX(-1)",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        sx={{
                          display: "block",
                          color: "#ffffff",
                        }}
                      />
                    )}
                  </IconButton>
                  <Grid
                    sx={{
                      alignContent: "center",
                      paddingRight: "5px",
                    }}
                  >
                    <span
                      style={{
                        display: "block",
                        maxWidth: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "#ffffff",
                        fontSize: "small",
                      }}
                    >
                      {s.value}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            );
          }

          return null;
        })
      : null;
  };

  const handleSearchStatusChange = (event) => {
    const {
      target: { value },
    } = event;
    setSearchByStatus(typeof value === "string" ? value.split(",") : value);
  };

  const handleSearchMilestoneChange = (event) => {
    const {
      target: { value },
    } = event;
    setSearchByMilestone(typeof value === "string" ? value.split(",") : value);
  };

  const status = [
    { Label: t("search_bar.active"), value: "1" },
    { Label: t("search_bar.completed"), value: "4" },
    { Label: t("search_bar.suspended"), value: "9" },
    { Label: t("search_bar.terminated"), value: "10" },
  ];

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        margin: "auto",
        backgroundColor: "#fff",
      }}
    >
      <Toolbar
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          flexDirection: "row",
          alignContent: "center",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
        disableGutters={true}
      >
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
        <Autocomplete
          multiple
          limitTags={2}
          freeSolo
          value={searchCriteria}
          options={searchCriteria}
          inputValue={searchInfo}
          disableClearable={searchInfo ? false : true}
          filterSelectedOptions
          getOptionLabel={(option) => option}
          fullWidth
          onInputChange={(event, value, reason) => {
            if (reason == "clear") {
              handleClearSearchInfo();
            }
          }}
          renderTags={(values) => searchTag(values)}
          renderInput={(params) => (
            <TextField
              fullWidth
              id="searchBar"
              disabled={disabled}
              {...params}
              sm={3}
              xs={3}
              variant="standard"
              onChange={handleTextBoxInput}
              placeholder={t("inbox_outbox.search")}
              ref={anchor}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                sx: { fontSize: "default" },
              }}
            />
          )}
        />
        <IconButton
          aria-describedby={id}
          disabled={disabled}
          onClick={() => {
            handleAdvancedSearchArrowOnClick();
          }}
        >
          {isShowAdvanceSearch ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>
        <Grid item>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
              setIsShowAdvanceSearch(false);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{
              width: "100%",
            }}
          >
            <Grid
              container
              direction="column"
              style={{
                padding: "10px",
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Grid
                item
                container
                // xs={7}
                md={12}
                lg={12}
                alignItems={"center"}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    md={6}
                    sm={6}
                    sx={{
                      paddingRight: isSmUp ? "5px" : "0px",
                    }}
                  >
                    <DesktopDatePicker
                      label={t("search_bar.from")}
                      value={searchByFromDate}
                      onChange={(newValue) => setSearchByFromDate(newValue)}
                      inputFormat="yyyy/MM/dd"
                      renderInput={(params) => <TextField {...params} />}
                      sx={{
                        width: isSmUp ? "238px" : { xs: "100%" },
                      }}
                      onError={(newError) => setError(newError)}
                      disableFuture={true}
                    />
                  </Grid>
                  {/* <Grid
                    item
                    lg={1}
                    md={1}
                    display={{ xs: "none", sm: "none", md: "flex" }}
                    justifyContent={"center"}
                  >
                    <HorizontalRuleIcon
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    />
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    md={6}
                    sm={6}
                    sx={{
                      marginTop: { xs: "10px", sm: "0px", md: "0px" },
                    }}
                  >
                    <DesktopDatePicker
                      label={t("search_bar.to")}
                      value={searchByEndDate}
                      onChange={(newValue) => setSearchByEndDate(newValue)}
                      inputFormat="yyyy/MM/dd"
                      renderInput={(params) => <TextField {...params} />}
                      sx={{
                        width: isSmUp ? "238px" : { xs: "100%" },
                        paddingLeft: isSmUp ? "5px" : "0px",
                      }}
                      onError={(newError) => setEndDayError(newError)}
                      disableFuture={true}
                    />
                  </Grid>
                </LocalizationProvider>
              </Grid>

              {location.pathname.split("/").at(1) == "workspace" ? (
                <Grid container alignItems={"center"} paddingTop={"10px"}>
                  <Grid xs={12} lg={6} md={6} sm={6}>
                    <FormControl
                      sx={{
                        width: isSmUp ? "238px" : "100%",
                      }}
                    >
                      <InputLabel id="searchby_status_label">
                        {t("search_bar.searchby_status")}
                      </InputLabel>

                      <Select
                        labelId="searchby_status_label"
                        multiple
                        value={searchByStatus}
                        onChange={handleSearchStatusChange}
                        renderValue={(selected) =>
                          selected
                            .map((value) => {
                              switch (value) {
                                case "1":
                                  return t("search_bar.active");
                                case "4":
                                  return t("search_bar.completed");
                                case "9":
                                  return t("search_bar.suspended");
                                case "10":
                                  return t("search_bar.terminated");
                              }
                            })
                            .join(", ")
                        }
                        input={
                          <OutlinedInput
                            label={t("search_bar.searchby_status")}
                          />
                        }
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 250,
                            },
                          },
                        }}
                        sx={{
                          paddingRight: isSmUp ? "5px" : "0px",
                        }}
                      >
                        {status.map((s) => (
                          <MenuItem key={s.Label} value={s.value}>
                            <Checkbox
                              checked={searchByStatus.includes(s.value)}
                            />
                            <ListItemText primary={s.Label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid lg={1} md={1}></Grid> */}
                  <Grid
                    xs={12}
                    lg={6}
                    md={6}
                    sm={6}
                    sx={{
                      marginTop: { xs: "10px", sm: "0px", md: "0px" },
                    }}
                  >
                    <FormControl
                      sx={{
                        width: isSmUp ? "238px" : "100%",
                        paddingLeft: isSmUp ? "5px" : "0px",
                      }}
                    >
                      <InputLabel
                        id="searchby_milestone_label"
                        sx={{ paddingLeft: isSmUp ? "5px" : "0px" }}
                      >
                        {t("search_bar.searchby_milestone")}
                      </InputLabel>
                      <Select
                        labelId="searchby_milestone_label"
                        multiple
                        value={SearchByMilestone}
                        onChange={handleSearchMilestoneChange}
                        renderValue={(selected) =>
                          selected.join(", ").replace(/["]/g, "")
                        }
                        input={
                          <OutlinedInput
                            label={t("search_bar.searchby_milestone")}
                          />
                        }
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 250,
                            },
                          },
                        }}
                      >
                        {milestoneOption?.map((s) => (
                          <MenuItem key={s} value={`"${s}"`}>
                            <Checkbox
                              checked={SearchByMilestone.includes(`"${s}"`)}
                            />
                            <ListItemText primary={s} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}

              <Grid item xs={12} md={12} lg={3} textAlign={"center"}>
                <Tooltip title={t("inbox_outbox.search")}>
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    disabled={isLoading}
                    loading={handler("save")}
                    className="ActionButton"
                    onClick={() => handleSearchingBtnClick()}
                    sx={{ width: "120px", height: "36px" }}
                  >
                    {isLoading && loadingBtn === "save"
                      ? ""
                      : t("inbox_outbox.search")}
                  </LoadingButton>
                </Tooltip>

                <Tooltip title={t("inbox_outbox.reset")}>
                  <LoadingButton
                    variant="Outlined"
                    disabled={isLoading}
                    loading={handler("reset")}
                    className="ActionButton"
                    onClick={() => handleResetBtnClick()}
                    sx={{
                      width: "120px",
                      height: "36px",
                      color: "#ff3535",
                      border: 1,
                    }}
                  >
                    {isLoading && loadingBtn === "reset"
                      ? ""
                      : t("inbox_outbox.reset")}
                  </LoadingButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Popover>
          <SharedDialog
            isOpen={alertDialog}
            onClose={handleAlertDialogClose}
            title={alertTitle}
            content={alertContent}
            buttons={[
              {
                // text: t("administrative_console.report_page.confirm"),
                action: handleAlertDialogClose,
              },
            ]}
          ></SharedDialog>
        </Grid>
      </Toolbar>
    </AppBar>
  );
});

export default SearchBar;
