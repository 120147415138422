import * as React from "react";
import { render, unmountComponentAtNode } from "react-dom";
import { useTheme } from "@mui/material";

import urlJoin from "url-join";
import { Buffer } from "buffer";
import { v4 as uuidv4 } from "uuid";
import PDFJSExpress from "@pdftron/pdfjs-express-viewer";
import ScaleLoader from "react-spinners/ScaleLoader";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";

import { resServerBaseUrl } from "../../Config";
import { CallApiWithContext } from "../../helpers/ApiHelper";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { useThemeColor } from "../../providers/ThemeColorProvider";

const DocumentPreview = (props) => {
  const { formIdentity, attachmentName, file, height = 600 } = props;
  const [isText, setIsText] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const previewRef = React.useRef();
  const authenticationContext = useAuthentication();
  const suffix = uuidv4();
  const theme = useTheme();
  const themeColorContext = useThemeColor();

  const downloadAttachment = () => {
    const data = {
      caseSID: formIdentity.caseSID,
      caseTplUUID: formIdentity.caseTplUUID,
      planItemSID: formIdentity.planItemSID,
      tempName: formIdentity.tempName,
      fileName: file.fileName,
      name: attachmentName,
      isNew: file.isNew,
    };
    const url = urlJoin(resServerBaseUrl, "/Import/PreviewAttachment");

    setIsLoading(true);

    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((response) => {
        if (response.succeed) {
          const byteCharacters = Buffer.from(response.data.content, "base64");
          const fileType = response.data.mimeType;

          const u8 = new Uint8Array(byteCharacters);
          const file = new Blob([u8.buffer], {
            type: fileType,
          });

          if (fileType.startsWith("image/")) {
            displayImage(file);
          } else if (fileType === "text/plain") {
            displayText(file);
          } else if (fileType === "text/html") {
            displayHTML(file);
          } else if (
            fileType === "application/pdf" ||
            fileType ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            displayPDF(file);
          } else {
            console.log("Unsupported file type.");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        displayError();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getPreviewElement = () => {
    return document.querySelector(`#attachment-preview-${suffix}`);
  };
  const hightlightWithLineNumbers = (input, grammer, type) => {
    highlight(input, grammer, type)
      .split("\n")
      .map((line, i) => `<span class="editorLineNumber">${i + 1}</span>${line}`)
      .join("\n");
  };

  const displayImage = (file) => {
    // file is blob / file
    const imageUrl = URL.createObjectURL(file);
    const imgEle = document.createElement("img");

    imgEle.src = imageUrl;

    previewRef.current.innerHTML = "";
    previewRef.current.appendChild(imgEle);
  };
  const displayText = (file) => {
    const reader = new FileReader();

    reader.onload = (evt) => {
      const preview = getPreviewElement();

      setIsText(true);
      setValue(evt.target.result);
    };

    reader.readAsText(file);
  };
  const displayHTML = (file) => {
    const reader = new FileReader();

    reader.onload = (evt) => {
      const viewer = getPreviewElement();
      viewer.innerHTML = evt.target.result;
    };
    reader.readAsText(file);
  };
  const displayPDF = (file) => {
    const container = document.createElement("div");

    container.id = `pdf-container-${suffix}`;
    container.style = `display: block; width: 100%; height: ${height}px`;

    previewRef.current.innerHTML = "";
    previewRef.current.appendChild(container);

    try {
      PDFJSExpress(
        {
          path: `${process.env.PUBLIC_URL}/pdfjsexpress`,
          licenseKey: `UHagj7ieW7SgtgEmAeyT`,
        },
        container
      ).then((instance) => {
        const { UI, Core } = instance;

        if (themeColorContext[0] === "dark") {
          instance.UI.setTheme("dark");
        }
        UI.loadDocument(file, { filename: "any.pdf" });

        Core.documentViewer.addEventListener("documentLoaded", () => {
          console.log("document loaded");
        });
      });
    } catch (e) {}
  };
  const displayError = () => {
    const preview = getPreviewElement();
    const divEle = document.createElement("div");

    divEle.style = `display: flex; align-items: center; justify-content: center; height: ${height}px`;
    divEle.innerText = "Load file error";

    if (preview) {
      preview.innerHTML = "";
      preview.appendChild(divEle);
    }
  };

  React.useEffect(() => {
    const pdfContainer = document.querySelector(`#pdf-container-${suffix}`);

    if (pdfContainer) {
      pdfContainer.style.height = `${height}px`;
    }
  }, [height]);
  React.useEffect(() => {
    if (file) {
      if (previewRef.current) {
        previewRef.current.innerHTML = "";
      }
      downloadAttachment();
    }
  }, [file]);

  return (
    <div id={`attachment-preview-${suffix}`} style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: `${height}px`,
          zIndex: 1000,
        }}
      >
        <ScaleLoader
          loading={isLoading}
          color={theme.palette.primary.main}
          size={150}
        />
      </div>
      <div
        ref={previewRef}
        style={{
          position: "absolute",
          inset: "0px",
          textAlign: "center",
        }}
      ></div>
    </div>
  );
};

export default DocumentPreview;
