import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Link as LinkTo, useLocation } from "react-router-dom";
import { Button, Menu, MenuItem, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Breadcrumbs } from "@mui/material";
import { CallApiWithContext } from "../helpers/ApiHelper";
import { useAuthentication } from "../providers/AuthenticationProvider";
import { useProfile } from "../providers/ProfileProvider";
import { resServerBaseUrl } from "../Config";
import urlJoin from "url-join";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import Divider from "@mui/material/Divider";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useThemeColor } from "../providers/ThemeColorProvider";
import i18n from "../localization/i18n.js";
import { MODULE_PERMISSIONS } from "../Constants.js";
import { Path } from "slate";
import { ArrowRight, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { flexbox } from "@mui/system";

function Breadcrumb(props) {
  const { tittle: title, formIdentity } = props;
  const { t } = useTranslation();
  const [breadCrumb, setBreadCrumb] = React.useState([]);
  const location = useLocation();
  const locationState = location.pathname.split("/").slice(1);

  locationState.shift();

  React.useEffect(() => {
    let breadCrumbAry = [];
    const isAdminConsole = location.pathname.startsWith("/admin-console");

    if (locationState.length > 1 || isAdminConsole) {
      if (isAdminConsole && locationState[0]) {
        if (breadCrumbAry.length < 1) {
          breadCrumbAry.push({
            Name: t("top_right_corner_menu.profile"),
            Path: "/profile",
          });
        }

        if (!breadCrumbAry.some((c) => location.pathname === c.Path)) {
          if (breadCrumbAry.length > 1) {
            breadCrumbAry = breadCrumbAry.slice(0, -1);
          }
          breadCrumbAry.push({
            Name: t(`administrative_console.${locationState[0]}`),
            Path: location.pathname,
          });
        }
        setBreadCrumb(breadCrumbAry);
      } else if (location.pathname.includes("/case")) {
        if (breadCrumbAry.length < 1) {
          if (localStorage.getItem("startPageURL").includes("/workspace")) {
            breadCrumbAry.push({
              Name: t("left_menu.workspace"),
              Path: localStorage.getItem("startPageURL"),
            });
          } else if (localStorage.getItem("startPageURL").includes("/outbox")) {
            breadCrumbAry.push({
              Name: t("left_menu.outbox"),
              Path: localStorage.getItem("startPageURL"),
            });
          } else if (localStorage.getItem("startPageURL").includes("/inbox")) {
            breadCrumbAry.push({
              Name: t("left_menu.inbox"),
              Path: localStorage.getItem("startPageURL"),
            });
          } else {
            //Default back to inbox if localStorage did not contain startPageURL or url did not match conditions
            breadCrumbAry.push({
              Name: t("left_menu.workspace"),
              Path: `/workspace/${formIdentity.tplUUID}`,
            });
          }
        }
        if (breadCrumbAry.length > 0) {
          let url = "";

          locationState.forEach((locationState, i) => {
            url += `/${locationState}`;
            if (breadCrumbAry.some((c) => location.pathname === c.Path)) {
              var index = breadCrumbAry.findIndex(
                (i) => location.pathname === i.Path
              );
              breadCrumbAry = breadCrumbAry.slice(0, index + 1);
            } else if (breadCrumbAry.length <= i && formIdentity?.name) {
              if (locationState) {
                if (locationState === "triggered") {
                  breadCrumbAry.push({
                    Name: formIdentity?.action,
                    Path: `/case${url}`,
                  });
                } else if (locationState === "optional") {
                  breadCrumbAry.push({
                    Name: formIdentity?.name,
                    Path: `/case${url}`,
                  });
                } else if (
                  i === 1 &&
                  localStorage.getItem("startPageURL").includes("/workspace")
                ) {
                  if (locationState === "new") {
                    breadCrumbAry.push({
                      Name: formIdentity.caseName,
                      Path: localStorage.getItem("startPageURL"),
                    });
                    breadCrumbAry.push({
                      Name: t("formruntime.new"),
                      Path: "",
                    });
                  } else {
                    breadCrumbAry.push({
                      Name:
                        locationState === "new"
                          ? t("formruntime.new")
                          : breadCrumbAry.length === 1
                          ? formIdentity?.caseName
                          : formIdentity?.name,
                      Path: `/workspace/${formIdentity.tplUUID}`,
                    });
                    breadCrumbAry.push({
                      Name: formIdentity?.caseNumber,
                      Path: "",
                    });
                  }
                } else {
                  breadCrumbAry.push({
                    Name:
                      breadCrumbAry.length === 1
                        ? formIdentity?.caseName
                        : formIdentity?.name,
                    Path: `/workspace/${formIdentity?.tplUUID}`,
                  });
                  breadCrumbAry.push({
                    Name: formIdentity?.caseNumber,
                    Path: "",
                  });
                }
              }
            }
          });
        }
        setBreadCrumb(breadCrumbAry);
      } else {
        setBreadCrumb([]);
      }
    } else {
      breadCrumbAry.push({ Name: title, Path: location.pathname });
      setBreadCrumb(breadCrumbAry);
      localStorage.removeItem("breadCrumbAry");
    }
  }, [title, formIdentity.isOutbox, location, formIdentity.action]);

  return (
    <Breadcrumbs
      separator={<ArrowForwardIosIcon fontSize="sm" />}
      aria-label="breadcrumb"
      //sx={{ color: "white" }}
    >
      {breadCrumb.length > 1
        ? breadCrumb.map(({ Name, Path }, key) =>
            key + 1 === breadCrumb.length ? (
              <span key={key}>{Name}</span>
            ) : (
              <LinkTo
                key={key}
                to={"." + Path}
                state={breadCrumb.length - 1 > 0 ? null : { title: Name }}
                style={{ color: "gray" }}
              >
                {Name}
              </LinkTo>
            )
          )
        : null}
    </Breadcrumbs>
  );
}

const menuSetting = {
  width: "20px",
  height: "20px",
  marginRight: "26px",
};

const Header = (props) => {
  const {
    tittle: title,
    formIdentity,
    isMdUp,
    drawerOpen = true,
    onDrawerToggle,
    onSignOut,
  } = props;
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profile, setProfile] = useProfile();
  const [licenseState, setLicenseState] = React.useState(1);
  const isOpenProfileMenu = Boolean(anchorEl);
  const [toolBarGridWidth, setToolBarGridWidth] = React.useState("0px");
  const [titleTypographyVariant, setTitleTypographyVariant] =
    React.useState("h5");
  const [spacingTwoIconBtnWidth, setSpacingTwoIconBtnWidth] =
    React.useState("0px");
  const [adminConsoleSecurity, setAdminConsoleSecurity] = React.useState(null);
  const [adminConsole, setAdminConsole] = React.useState(false);
  const [profileImg, setProfileImg] = React.useState(null);
  const [loadingColor, setLoadingColor] = React.useState(null);
  const themeColorContext = useThemeColor();
  const userName = React.useRef();

  const handleImageLoad = () => {
    const bgColor = {
      bgcolor: "grey",
    };
    setLoadingColor(bgColor);
  };

  const onProfileIconClicked = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseProfileMenu = () => {
    setAnchorEl(null);
  };
  const onCloseProfileMenuwithTitle = () => {
    setAnchorEl(null);
    //setTittle(t("top_right_corner_menu.profile"));
  };

  const getTitle = () => {
    switch (licenseState) {
      case 0:
        return "Invalid license";
      case 2:
        return "License Expired";
      default:
        return title;
    }
  };

  const languages = [
    { value: "zh-CN", label: "简体中文", enum: 0 },
    { value: "en", label: "English", enum: 1 },
    { value: "zh-HK", label: "繁體中文", enum: 2 },
  ];

  React.useEffect(() => {
    const url = urlJoin(resServerBaseUrl, "/User/GetUserProfile");

    setProfileImg("/unknown-logo.png");

    CallApiWithContext(url, authenticationContext, null)
      .then((json) => {
        if (json) {
          setProfile(json);
          setAdminConsoleSecurity(json.security);
          if (Object.keys(JSON.parse(json.attr)).includes("LANGUAGE")) {
            let lang = languages.find(
              (l) => l.enum === JSON.parse(json.attr)?.LANGUAGE
            );
            if (lang) {
              i18n.changeLanguage(lang.value);
            }
          }
          if (json.modulePermissions) {
            if (
              (json.security["AdminConsole"] === "Read_Write" &&
                json.modulePermissions & MODULE_PERMISSIONS.AdminConsole) > 0
            ) {
              setAdminConsole(true);
            }
          } else if (
            json.security["AdminConsole"] !== undefined &&
            json.security["AdminConsole"] !== "No_Access"
          ) {
            setAdminConsole(true);
          }

          if (json.profileImage) {
            setProfileImg(`data:image/jpeg;base64,${json.profileImage}`);
          }

          if (json.attr && JSON.parse(json.attr).FULLNAME) {
            userName.current = JSON.parse(json.attr).FULLNAME;
          } else {
            userName.current = json.login.split("@")[0];
          }

          if (typeof json.licenseState === "number") {
            setLicenseState(json.licenseState);
          }
        }
      })
      .catch((error) => console.log(error));
  }, [authenticationContext, setProfileImg]);

  React.useEffect(() => {
    setSpacingTwoIconBtnWidth("0%");

    if (isMdUp) {
      setToolBarGridWidth("100%");
      setTitleTypographyVariant("h5");
    } else {
      setToolBarGridWidth("100%");
      setTitleTypographyVariant("h6");
    }
  }, [isMdUp]);

  React.useEffect(() => {
    if (profile?.profileImage) {
      setProfileImg(`data:image/jpeg;base64,${profile.profileImage}`);
    } else {
      setProfileImg("/unknown-logo.png");
    }
  }, [profile]);

  return (
    <React.Fragment>
      <AppBar
        color={licenseState === 1 ? "primary" : "error"}
        position="sticky"
        elevation={0}
        id="header"
        sx={{ height: "60px" }}
      >
        <Toolbar
          id="Toolbar"
          className="HeaderToolbar"
          style={{
            padding: "0px",
            justifyContent: "space-between",
            height: "100px",
          }}
        >
          <Grid
            container
            style={{
              display: "flex",
              width: toolBarGridWidth,
              justifyContent: "flex-end",
            }}
          >
            {isMdUp ? (
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "gray",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "0px 4px 4px 0px",
                }}
              >
                <Button
                  sx={{ minWidth: "10px", p: 0 }}
                  onClick={onDrawerToggle}
                >
                  {drawerOpen ? <ChevronLeft /> : <ChevronRight />}
                </Button>
              </div>
            ) : (
              <Grid item xs="auto">
                <IconButton
                  id="MenuIconButton"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  edge="start"
                  style={{
                    margin: "0px",
                    // , paddingTop: "10px"
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            )}

            {getTitle()?.length < 10 ? (
              <Grid
                item
                xs
                //xs
                style={{
                  // display: "flex",
                  // position: "relative",
                  // justifyContent: "right",
                  // flexWrap: "wrap",
                  // alignItems: "center",
                  alignSelf: "center",
                  // textAlign: "center",
                  // marginLeft: "0px",
                }}
              >
                <Typography
                  id="TitleTypography"
                  color="inherit"
                  variant={titleTypographyVariant}
                  className="title"
                >
                  {getTitle()}
                </Typography>
              </Grid>
            ) : isMdUp ? (
              <Grid
                item
                xs
                //xs
                style={{
                  // display: "flex",
                  // position: "relative",
                  // justifyContent: "right",
                  // flexWrap: "wrap",
                  // alignItems: "center",
                  alignSelf: "center",
                  // textAlign: "center",
                  // whiteSpace: "nowrap",
                  // marginRight: "220px",
                  // marginLeft: "0px",
                  // flexGrow: 2,
                }}
              >
                <Typography
                  id="TitleTypography"
                  color="inherit"
                  variant={titleTypographyVariant}
                  className="title"
                >
                  {getTitle()}
                </Typography>
              </Grid>
            ) : (
              <Grid
                item
                xs
                //xs
                style={{
                  // display: "flex",
                  // position: "relative",
                  // justifyContent: "right",
                  // flexWrap: "wrap",
                  // alignItems: "center",
                  alignSelf: "center",
                  // textAlign: "center",
                  // whiteSpace: "nowrap",
                  // marginRight: "auto",
                  // marginLeft: "0px",
                  // flexGrow: 2,
                }}
              >
                <Typography
                  id="TitleTypography"
                  color="inherit"
                  variant={titleTypographyVariant}
                  className="title"
                >
                  {getTitle()}
                </Typography>
              </Grid>
            )}
            {isMdUp ? (
              <Grid
                item
                id="SpacingTailGrid"
                width={spacingTwoIconBtnWidth}
              ></Grid>
            ) : null}

            <Grid
              item
              xs={"auto"}
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <IconButton
                id="ProfileIconButton"
                color="inherit"
                onClick={onProfileIconClicked}
              >
                <Avatar
                  onLoad={handleImageLoad}
                  sx={loadingColor}
                  // sx={{ bgcolor: "grey" }}
                  src={profileImg}
                />
              </IconButton>
            </Grid>

            <Menu
              anchorEl={anchorEl}
              open={isOpenProfileMenu}
              onClose={onCloseProfileMenu}
            >
              <MenuItem
                component={LinkTo}
                to="/profile"
                state={{ profile: profile, from: null }}
                onClick={() => onCloseProfileMenu()}
              >
                <IconButton id="ProfileIconButton" color="inherit" size="large">
                  <Avatar src={profileImg} /> {/*profileImage*/}
                </IconButton>
                {/* {t("top_right_corner_menu.profile")} */}
                {userName?.current ?? (
                  <Skeleton variant="rectangular" width={100} height={25} />
                )}
              </MenuItem>
              <Divider />
              {adminConsole && (
                <MenuItem
                  component={LinkTo}
                  to="/admin-console"
                  onClick={() => onCloseProfileMenuwithTitle()}
                  style={{ fontSize: 12 }}
                  state={{ from: adminConsoleSecurity }}
                >
                  <AdminPanelSettingsIcon
                    id="adminIcon"
                    color="inherit"
                    sx={menuSetting}
                  />

                  {t("profile.administrative_console")}
                </MenuItem>
              )}
              <MenuItem
                component={LinkTo}
                to="/settings"
                onClick={() => onCloseProfileMenu()}
                style={{ fontSize: 12 }}
              >
                <SettingsIcon
                  id="settingIcon"
                  color="inherit"
                  sx={menuSetting}
                />
                {t("top_right_corner_menu.settings")}
              </MenuItem>
              <MenuItem onClick={onSignOut} style={{ fontSize: 12 }}>
                <LogoutIcon id="logoutIcon" color="inherit" sx={menuSetting} />
                {t("top_right_corner_menu.sign_out")}
              </MenuItem>
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid
        item
        sm={12}
        mg={12}
        lg={12}
        id="BreadCrumbGrid"
        style={{
          zIndex: "10",
          position: "sticky",
          top: "60px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          paddingLeft: "3px",
          // backgroundColor:
          //   themeColorContext[0] == "default" ? "#eaeff1" : "#1a1a1a",
        }}
        sx={{ bgcolor: `mainContainer.${themeColorContext[0]}` }}
        className="BreadCrumb"
      >
        <Breadcrumb
          id="BreadCrumb"
          tittle={title}
          formIdentity={formIdentity}
        />
      </Grid>
    </React.Fragment>
  );
};

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
  isMdUp: PropTypes.bool,
};

export default Header;
