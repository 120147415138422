import * as React from "react";
import {
  Box,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Unstable_Grid2";
import PropTypes from "prop-types";

import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BlockIcon from "@mui/icons-material/Block";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NoteIcon from "@mui/icons-material/Note";
import PauseIcon from "@mui/icons-material/Pause";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PushPinIcon from "@mui/icons-material/PushPin";
import ReplayIcon from "@mui/icons-material/Replay";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import urlJoin from "url-join";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useComponentSize, useWindowSize } from "react-use-size";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import "react-reflex/styles.css";

import AccessibleUsers from "./AccessibleUsers";
import ActivityLog from "./ActivityLog";
import AttachmentControl from "./AttachmentControl";
import C1PdfViewer from "../PdfExport/GcPdfViewer_new";
import DocumentPreview from "./DocumentPreview";
import Flow from "./Flow";
import LoadingSpinner from "../../shared/LoadingSpinner";
import JsonFormViewer from "../JsonForm/JsonFormViewer";
import StageProgressBar from "./ProgressBar";
import SharedDialog from "../../shared/SharedDialog";
import { resServerBaseUrl } from "../../Config";
import { CallApiWithContext } from "../../helpers/ApiHelper";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { useThemeColor } from "../../providers/ThemeColorProvider";
import { useProfile } from "../../providers/ProfileProvider";
import { useUISettings } from "../../providers/UISettingsProvider";
import { NoPermissionPage } from "../CaseList/CaseList";
import {
  DEFINITION_TYPE,
  DRAWER_WIDTH,
  ELEMENT_STATUS,
  IsTerminalState,
} from "../../Constants";

const listItemStyles = {
  header: { backgroundColor: "rgba(200, 200, 200, 0.4)", userSelect: "none" },
  attachment: {
    p: "4px 8px",
    "& div": { width: "100%" },
    "&:last": { pb: "8px" },
  },
};

const FormRuntime = (props) => {
  const location = useLocation();
  const theme = useTheme();
  const nav = useNavigate();
  const { t } = useTranslation();
  const [profile] = useProfile();
  const [uiSettings] = useUISettings();

  const isDesktopMode = useMediaQuery(theme.breakpoints.up("ml"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const authenticationContext = useAuthentication();

  const { IsOutbox, settitle: setTitle, setFormIdentity } = props;
  const { TaskID, TplID, uuid, PlanItemSID, CaseSID, PlanItemTplSID } =
    useParams();
  const [tempName, setTempName] = React.useState();
  const [noPermission, setNoPermission] = React.useState(false);
  const action = ["new", "triggered"].includes(
    location.pathname
      .split("/")
      .filter((s) => !!s)
      .pop()
  )
    ? location.pathname
        .split("/")
        .filter((s) => !!s)
        .pop()
    : "";
  const formRef = React.useRef();
  const {
    ref: formViewerRef,
    height: viewerHeight,
    width: viewerWidth,
  } = useComponentSize();
  const { height: windowHeight } = useWindowSize();

  const [isLoading, setIsLoading] = React.useState(false);
  const [forbidden, setForbidden] = React.useState(false);
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const [alertDialog, setAlertDialog] = React.useState({
    title: "",
    content: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => setShowAlertDialog(false),
      },
    ],
  });

  const [isOpenFlowChart, setIsOpenFlowChart] = React.useState(false);

  /* activity log */
  const [isOpenActivityLog, setIsOpenActivityLog] = React.useState(false);
  const [activityLog, setActivityLog] = React.useState(null);
  const [isLoadingHistoryLog, setIsLoadingHistoryLog] = React.useState(false);
  const [currentStageLog, setCurrentStageLog] = React.useState(null);
  const [isLoadingCurrentStageLog, setIsLoadingCurrentStageLog] =
    React.useState(false);
  const [historyLogType, setHistoryLogType] = React.useState("info");
  const [fullScreen, setFullScreen] = React.useState(false);
  const [formFullscreen, setFormFullscreen] = React.useState(
    localStorage.getItem("form-fullwidth") === "true"
  );
  const [showCasebar, setShowCasebar] = React.useState(
    localStorage.getItem("show-case-bar") == undefined ||
      localStorage.getItem("show-case-bar") == true
  );
  const [caseMenuAnchorEl, setCaseMenuAnchorEl] = React.useState();
  const openCaseMenuAnchorEl = Boolean(caseMenuAnchorEl);
  const activityLogRef = React.useRef();
  const isActivityLogLoaded = React.useRef(false);
  const maxAvatarCount = isMdUp ? 5 : 3;
  let resizeActivityLogTimeout = undefined;

  /* right hand side drawer */
  const [isRightDrawerOpen, setIsRightDrawerOpen] = React.useState(false);
  /* attachment */
  const [attachments, setAttachments] = React.useState({});
  const [showPreviewDialog, setShowPreviewDialog] = React.useState(false);
  const [previewAttachment, setPreviewAttachment] = React.useState();
  const [isDockedPreview, setIsDockedPreview] = React.useState(false);
  const [previewAttachmentControlName, setPreviewAttachmentControlName] =
    React.useState();

  /* case info */
  const [caseInfo, setCaseInfo] = React.useState();
  const [openingSubCase, setOpeningSubCase] = React.useState(false);
  const [allTaskInfo, setAllTaskInfo] = React.useState();
  const [moreTaskInfo, setMoreTaskInfo] = React.useState();
  const [isLoadingAllTaskInfo, setIsLoadingAllTaskInfo] = React.useState(false);
  const [isReadOnly, setIsReadOnly] = React.useState(false);
  const [isOutbox, setIsOutbox] = React.useState();
  const [stages, setStages] = React.useState([]);
  const [planItemSID, setPlanItemSID] = React.useState([]);
  const [activityLogDrawerHeight, setActivityLogDrawerHeight] =
    React.useState(300);
  const themeColorContext = useThemeColor();

  const currentTask = React.useMemo(() => {
    if (PlanItemTplSID && caseInfo?.moreTasks?.length > 0) {
      const task = caseInfo.moreTasks.find(
        (t) => t.planItemTemplateSid === PlanItemTplSID
      );

      if (task) return task;
    } else if (caseInfo?.tasks?.length > 0) {
      const taskID = isNaN(TaskID) ? 0 : parseInt(TaskID);

      if (taskID) {
        return caseInfo.tasks.find((t) => t.planItem?.id === taskID);
      }

      return caseInfo.tasks[0];
    }

    return null;
  }, [caseInfo]);
  const optionalStages = React.useMemo(() => {
    return caseInfo?.stages?.filter((s) => s[0].discretionary);
  }, [caseInfo]);
  const optionalTasks = React.useMemo(() => {
    return caseInfo?.moreTasks;
  }, [caseInfo]);
  const attachmentSecurities = React.useMemo(() => {
    if (caseInfo?.caseAttachmentSecurities?.length > 0) {
      return caseInfo.caseAttachmentSecurities.map((s) => {
        if (s?.permission !== null) {
          const permission = JSON.parse(s.permission);
          const resolvePermission = (key) => {
            return (
              permission[key] === "Y" ||
              (permission.Full === "Y" && permission[key] !== "N")
            );
          };

          return {
            name: s.name,
            permission: {
              canUpload: resolvePermission("Add"),
              canDownload: resolvePermission("Download"),
              canDelete: resolvePermission("Delete"),
              canPreview: resolvePermission("Preview"),
              canPrint: resolvePermission("Print"),
            },
          };
        } else {
          return null;
        }
      });
    }

    return [];
  }, [caseInfo]);
  const actionButtons = React.useMemo(() => {
    if (!isReadOnly) {
      if (currentTask?.userActions?.length > 0) {
        return [...currentTask.userActions];
      } else if (currentTask != null) {
        return [{ value: "Submit", text: t("formruntime.submit") }];
      }
      return [];
    }
    return [];
  }, [isReadOnly, currentTask]);
  const isTriggerCase = React.useMemo(() => {
    return action === "new" || action === "triggered";
  }, [action]);
  const isCopyAsNew = React.useMemo(() => {
    return action === "triggered";
  }, [action]);
  const showActivityLogSplitter = React.useMemo(() => {
    return isMdUp && isOpenActivityLog;
  }, [isMdUp, isOpenActivityLog]);

  const showAlertMessage = (messageObject) => {
    setAlertDialog((prev) => ({
      ...prev,
      ...messageObject,
    }));
    setShowAlertDialog(true);
  };
  const closeAlertMessage = () => {
    setShowAlertDialog(false);
  };

  const handleToggleActivityLog = () => {
    setIsOpenActivityLog((prev) => !prev);
  };
  const handleToggleRightDrawer = () => {
    setIsRightDrawerOpen((prev) => !prev);
  };

  const submitTask = (data) => {
    const handleConfirmSubmitClick = () => {
      formRef.current
        .submitTask(data)
        .then((res) => {
          /* if submit success, prompt submission succeeded and back to inbox */
          showAlertMessage({
            title: t("formruntime.task_submitted"),
            content: t("formruntime.task_submitted_context"),
            buttons: [
              {
                text: t("administrative_console.group_page.dialog.close"),
                action: () => {
                  nav(localStorage.getItem("startPageURL"));
                },
              },
            ],
          });
        })
        .catch((error) => {
          console.log(error);
          /* otherwise, prompt submission failed message */
          showAlertMessage({
            title: t("formruntime.submission_failed"),
            content: error,
            buttons: [
              {
                text: t("administrative_console.group_page.dialog.close"),
                action: () => {
                  closeAlertMessage();
                  formRef.current.onFormClose();
                },
              },
            ],
          });
        });
    };
    /* prompt confirm submit message */
    showAlertMessage({
      title: t("formruntime.submit_task"),
      content: t("formruntime.submit_task_context"),
      buttons: [
        {
          /* if yes, continue process submission */
          text: t("formruntime.yes"),
          action: () => {
            closeAlertMessage();
            handleConfirmSubmitClick();
          },
        },
        {
          /* if no, cancel the submission. */
          text: t("formruntime.no"),
          action: closeAlertMessage,
          variant: "outlined",
          color: "error",
        },
      ],
    });
  };

  const handleActionButtonClick = (actionButton) => {
    if (formRef.current) {
      /* inform form viewer action button clicked */
      formRef.current
        .actionButtonClick(
          actionButton.value ? actionButton.value : actionButton
        )
        .then((res) => {
          console.log(`User action: ${res.action}`);

          if (res.canProcess) {
            if (actionButton === "_Trigger_") {
              nav(`/case/${CaseSID}/${PlanItemSID || planItemSID}/triggered`);
              setIsReadOnly(false);
              setIsOutbox(true);
            } else if (
              actionButton === "_Close_" ||
              actionButton === "Cancel"
            ) {
              formRef.current.onFormClose();
              //Default back to workspace if localStorage did not contain startPageURL
              nav(
                localStorage.getItem("startPageURL")
                  ? localStorage.getItem("startPageURL")
                  : `/workspace/${caseInfo?.tplUUID}`
              );
            } else {
              submitTask({
                CaseSID: CaseSID,
                CaseTplUUID: tempName,
                Metadata: caseInfo?.metaData,
                Action: action,
                CaseName: caseInfo?.name,
                TempName: tempName,
                CompleteTaskName: currentTask?.name,
                BtnAction: actionButton.value
                  ? actionButton.value
                  : actionButton,
                TaskID: TaskID || currentTask?.planItemID,
                TaskSID: currentTask?.planItemSID,
                PlanItemTplSID:
                  currentTask?.planItemTemplateSid || PlanItemTplSID,
                StageID: -1,
              });
            }
          }
        })
        .catch((error) => {
          showAlertMessage({
            title: t("profile.proxy_and_delegation.error"),
            content: error.toString(),
            buttons: [
              {
                text: t("administrative_console.group_page.dialog.close"),
                action: closeAlertMessage,
              },
            ],
          });
        });
    } else if (actionButton === "_Close_" || actionButton === "Cancel") {
      nav(
        localStorage.getItem("startPageURL")
          ? localStorage.getItem("startPageURL")
          : `/workspace/${caseInfo?.tplUUID}`
      );
    }
  };
  const handleCompleteStageClick = (stage) => {
    showAlertMessage({
      title: t("formruntime.complete_stage.title"),
      content: t("formruntime.complete_stage.content", {
        name: stage.planItem.name,
      }),
      buttons: [
        {
          text: t("common.yes"),
          action: () => {
            const url = urlJoin(resServerBaseUrl, "/Case/CompletePlanItem");
            const formData = JSON.stringify({
              PlanItemSID: stage.planItem.planItemSID,
            });

            CallApiWithContext(url, authenticationContext, formData)
              .then((res) => {
                nav(
                  localStorage.getItem("startPageURL")
                    ? localStorage.getItem("startPageURL")
                    : `/workspace/${caseInfo?.tplUUID}`
                );
              })
              .catch((error) => console.log(error));
            closeAlertMessage();
          },
        },
        {
          text: t("common.no"),
          action: closeAlertMessage,
          variant: "outlined",
          color: "error",
        },
      ],
    });
  };
  const handleOptionalTaskClick = (optionalTask, index) => {
    if (optionalTask.type === DEFINITION_TYPE.EventListener) {
      showAlertMessage({
        title: "Raise event",
        content: "Are you sure to trigger this task?",
        buttons: [
          {
            text: "Yes",
            action: () => {
              const url = urlJoin(resServerBaseUrl, "/Case/OccurPlanItem");
              const formData = JSON.stringify({
                PlanItemSID: optionalTask.planItemSID,
              });

              CallApiWithContext(url, authenticationContext, formData)
                .then((res) => {
                  if (res.isSucceeded) {
                    // refresh current page.
                    window.location.reload(false);
                  }
                })
                .catch((error) => console.log(error));
              closeAlertMessage();
            },
          },
          {
            text: "No",
            action: closeAlertMessage,
            variant: "outlined",
            color: "error",
          },
        ],
      });
    } else if (optionalTask.type === DEFINITION_TYPE.Task) {
      // open optional task form.
      if (optionalTask.discretionary) {
        switch (optionalTask.currentState) {
          case ELEMENT_STATUS.Initial:
            nav(
              `${process.env.PUBLIC_URL}/case/${CaseSID}/${optionalTask.planItemTemplateSid}/optional/`
            );
            //window.location.href = `${process.env.PUBLIC_URL}/case/${CaseSID}/${optionalTask.planItemTemplateSid}/optional/`;
            break;
          case ELEMENT_STATUS.Active:
            nav(
              `${process.env.PUBLIC_URL}/case/${CaseSID}/${optionalTask.planItemSid}/`
            );
            //window.location.href = `${process.env.PUBLIC_URL}/case/${CaseSID}/${optionalTask.planItemSid}/`;
            break;
          default:
            break;
        }
      }
    } else if (optionalTask.type === DEFINITION_TYPE.Stage) {
      showAlertMessage({
        title: "Complete stage",
        content: `Are you sure to complete the stage, ${optionalTask.planItem.name}?`,
        buttons: [
          {
            text: "Yes",
            action: () => {
              const url = urlJoin(resServerBaseUrl, "/Case/CompletePlanItem");
              const formData = JSON.stringify({
                PlanItemSID: optionalTask.planItemSID,
              });

              CallApiWithContext(url, authenticationContext, formData)
                .then((res) => {
                  // refresh current page.
                  window.location.reload(false);
                })
                .catch((error) => console.log(error));
              closeAlertMessage();
            },
          },
          {
            text: "No",
            action: closeAlertMessage,
            variant: "outlined",
            color: "error",
          },
        ],
      });
    }
  };

  /* activity log */
  const getActivityLogs = () => {
    if (
      caseInfo &&
      !isActivityLogLoaded.current &&
      (isOpenActivityLog || isOpenFlowChart)
    ) {
      isActivityLogLoaded.current = true;

      setIsLoadingCurrentStageLog(true);
      setIsLoadingHistoryLog(true);

      const activityLogUrl = urlJoin(resServerBaseUrl, "/Case/GetActivityLog");
      const currentStageLogUrl = urlJoin(
        resServerBaseUrl,
        "/Case/GetCurrentStageLog"
      );
      const data = JSON.stringify({
        CaseSID: CaseSID,
        Language: localStorage.getItem("i18nextLng"),
      });

      CallApiWithContext(activityLogUrl, authenticationContext, data)
        .then((res) => {
          if (res && !res.message) {
            setActivityLog(res);
          } else {
            setActivityLog(null);
          }
        })
        .catch((error) => {
          console.log(error);
          // setError(true);
        })
        .finally(() => {
          setIsLoadingHistoryLog(false);
        });

      CallApiWithContext(currentStageLogUrl, authenticationContext, data)
        .then((res) => {
          if (res && !res.message) {
            setCurrentStageLog(res);
          } else {
            setCurrentStageLog(null);
          }
        })
        .catch((error) => {
          console.log(error);
          // setError(true);
        })
        .finally(() => {
          setIsLoadingCurrentStageLog(false);
        });
    }
  };
  const handleActivityLogDrawerGripPointerDown = (e) => {
    e.preventDefault();
    e.stopPropagation();

    e.target.addEventListener(
      "pointermove",
      handleActivityDrawerPointerMove,
      true
    );
    e.target.addEventListener(
      "pointerup",
      handleActivityDrawerGripPointerUp,
      true
    );
    e.target.setPointerCapture(e.pointerId);
  };
  const handleActivityDrawerGripPointerUp = (e) => {
    e.target.releasePointerCapture(e.pointerId);
    e.target.removeEventListener(
      "pointerup",
      handleActivityDrawerGripPointerUp,
      true
    );
    e.target.removeEventListener(
      "pointermove",
      handleActivityDrawerPointerMove,
      true
    );
  };
  const handleActivityDrawerPointerMove = (e) => {
    const newHeight = document.body.offsetHeight - e.clientY;

    e.stopPropagation();
    e.preventDefault();

    resizeActivityLogHeight(newHeight, 0);
  };
  const handleOnLogTypeChange = () => {
    resizeActivityLogHeight(activityLogDrawerHeight);
  };
  const handleActivityLogUpdate = () => {
    isActivityLogLoaded.current = false;
    getActivityLogs();
  };
  const handleCaseMoreVertClick = (e) => {
    setCaseMenuAnchorEl(e.currentTarget);
  };
  const handleReactivateCaseClick = () => {
    setCaseMenuAnchorEl(null);
  };
  const handleSuspendCaseClick = () => {
    setCaseMenuAnchorEl(null);
    showAlertMessage({
      title: t("formruntime.suspend_case.title"),
      content: t("formruntime.suspend_case.content"),
      buttons: [
        {
          text: t("common.yes"),
          action: () => {
            closeAlertMessage();
            suspendCase();
          },
        },
        {
          text: t("common.no"),
          variant: "outlined",
          color: "error",
          action: closeAlertMessage,
        },
      ],
    });
  };
  const handleResumeCaseClick = () => {
    setCaseMenuAnchorEl(null);
    showAlertMessage({
      title: t("formruntime.resume_case.title"),
      content: t("formruntime.resume_case.content"),
      buttons: [
        {
          text: t("common.yes"),
          action: () => {
            closeAlertMessage();
            resumeCase();
          },
        },
        {
          text: t("common.no"),
          variant: "outlined",
          color: "error",
          action: closeAlertMessage,
        },
      ],
    });
  };
  const handleTerminateCaseClick = () => {
    setCaseMenuAnchorEl(null);
    showAlertMessage({
      title: t("formruntime.terminate_case.title"),
      content: t("formruntime.terminate_case.content"),
      buttons: [
        {
          text: t("common.yes"),
          action: () => {
            closeAlertMessage();
            terminateCase();
          },
        },
        {
          text: t("common.no"),
          variant: "outlined",
          color: "error",
          action: closeAlertMessage,
        },
      ],
    });
  };

  /* get case info */
  const getCaseInfo = () => {
    if (isTriggerCase) {
      setIsLoading(true);
      setAllTaskInfo(null);
      const url = urlJoin(resServerBaseUrl, "/Case/GetCaseTemplateByUUID");
      const data = JSON.stringify({
        uuid: uuid || caseInfo?.tplUUID,
        language: localStorage.getItem("i18nextLng"),
      });

      CallApiWithContext(url, authenticationContext, data)
        .then((res) => {
          if (res?.caseInfo) {
            setCaseInfo(res.caseInfo);
            setTempName(res.tempUUID);
            setStages(res.caseInfo.stages);
          } else if (res?.message) {
            showAlertMessage({
              title: t("profile.proxy_and_delegation.error"),
              content: res.message,
              buttons: [
                {
                  text: t("administrative_console.report_page.confirm"),
                  action: () => setShowAlertDialog(false),
                },
              ],
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setNoPermission(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // ignore the reload when replace the url due to open from workspace without plan item sid.
      if (PlanItemSID === planItemSID) {
        return;
      }
      setIsLoading(true);
      setAllTaskInfo(null);
      initFormRuntime();
      // get running case
      const url = urlJoin(resServerBaseUrl, "/Case/GetRunningCaseByCaseSID");
      const data = JSON.stringify({
        CaseSID: CaseSID,
        PlanItemSID: PlanItemSID,
        PlanItemTplSID: PlanItemTplSID,
        Language: localStorage.getItem("i18nextLng"),
      });

      CallApiWithContext(url, authenticationContext, data)
        .then((res) => {
          if (res?.caseInfo) {
            const caseInfo = res.caseInfo;

            setCaseInfo(caseInfo);
            setIsReadOnly(res.isReadOnly);
            setIsOutbox(res.isReadOnly);
            setTempName(res.tempUUID);
            setStages(caseInfo?.stages);
            setOpeningSubCase(res.openSubCase);

            if (!PlanItemSID) {
              setPlanItemSID(res.planItemSID);
            }
            if (
              !PlanItemTplSID &&
              caseInfo?.currentPlanItemSid &&
              PlanItemSID !== caseInfo?.currentPlanItemSid
            ) {
              nav(
                `${process.env.PUBLIC_URL}/case/${res.caseSID}/${caseInfo.currentPlanItemSid}/`,
                { replace: true, state: null }
              );
              // window.history.replaceState(
              //   null,
              //   "FlexCase",
              //   `${process.env.PUBLIC_URL}/case/${res.caseSID}/${caseInfo.currentPlanItemSid}/`
              // );
            }

            if (caseInfo?.caseAttachmentNames) {
              const curAttachments = {};

              Object.keys(caseInfo.caseAttachmentNames).forEach((k) => {
                const newFiles = caseInfo.caseAttachmentNames[k];
                const curFiles = curAttachments[k] || [];

                newFiles?.forEach((f) => curFiles.push({ ...f, isNew: false }));
                curAttachments[k] = curFiles;
              });

              setAttachments(curAttachments);
            }
          } else if (res.errors && res.status === 400) {
            setTitle(t("case_list.no_permission_title"));
            setForbidden(true);
          }
        })
        .catch((res) => {
          if (res.status === 403) {
            setTitle(t("case_list.no_permission_title"));
            setForbidden(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const terminateCase = () => {
    const url = urlJoin(resServerBaseUrl, "/Case/TerminateCase");
    const data = { caseSid: CaseSID };

    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((res) => {
        showAlertMessage({
          title: t("formruntime.terminate_case_success.title"),
          content: t("formruntime.terminate_case_success.content"),
          buttons: [
            {
              text: t("common.ok"),
              action: () => {
                getCaseInfo();
                closeAlertMessage();
              },
            },
          ],
        });
      })
      .catch((err) => {
        if (err.status === 403) {
          showAlertMessage({
            title: t("formruntime.terminate_case_fail.title"),
            content: t("formruntime.terminate_case_fail.content"),
            buttons: [
              {
                text: t("common.ok"),
                action: closeAlertMessage,
              },
            ],
          });
        }
      });
  };
  const suspendCase = () => {
    const url = urlJoin(resServerBaseUrl, "/Case/SuspendCase");
    const data = { caseSid: CaseSID };

    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((res) => {
        showAlertMessage({
          title: t("formruntime.suspend_case_success.title"),
          content: t("formruntime.suspend_case_success.content"),
          buttons: [
            {
              text: t("common.ok"),
              action: () => {
                getCaseInfo();
                closeAlertMessage();
              },
            },
          ],
        });
      })
      .catch((err) => {
        if (err.status === 403) {
          showAlertMessage({
            title: t("formruntime.suspend_case_fail.title"),
            content: t("formruntime.suspend_case_fail.content"),
            buttons: [
              {
                text: t("common.ok"),
                action: closeAlertMessage,
              },
            ],
          });
        } else {
          showAlertMessage({
            title: t("formruntime.suspend_case_fail.title"),
            content: err.statusText,
            buttons: [
              {
                text: t("common.ok"),
                action: closeAlertMessage,
              },
            ],
          });
        }
      });
  };
  const resumeCase = () => {
    const url = urlJoin(resServerBaseUrl, "/Case/ResumeCase");
    const data = { caseSid: CaseSID };

    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((res) => {
        showAlertMessage({
          title: t("formruntime.resume_case_success.title"),
          content: t("formruntime.resume_case_success.content"),
          buttons: [
            {
              text: t("common.ok"),
              action: () => {
                getCaseInfo();
                closeAlertMessage();
              },
            },
          ],
        });
      })
      .catch((err) => {
        if (err.status === 403) {
          showAlertMessage({
            title: t("formruntime.resume_case_fail.title"),
            content: t("formruntime.resume_case_fail.content"),
            buttons: [
              {
                text: t("common.ok"),
                action: closeAlertMessage,
              },
            ],
          });
        } else {
          showAlertMessage({
            title: t("formruntime.resume_case_fail.title"),
            content: err.statusText,
            buttons: [
              {
                text: t("common.ok"),
                action: closeAlertMessage,
              },
            ],
          });
        }
      });
  };

  /* attachment preview */
  const handleOpenAttachmentPreview = (controlName, file) => {
    setPreviewAttachmentControlName(controlName);
    setPreviewAttachment(file);
    setShowPreviewDialog(true);
  };

  const handleCloseAttachmentPreview = () => {
    setShowPreviewDialog(false);
  };

  /* flow chart */
  const handleOpenFlowChart = () => {
    const allTaskInfoUrl = urlJoin(resServerBaseUrl, "/Case/GetAllTaskInfo");
    const data = JSON.stringify({
      CaseSID: CaseSID,
      copyAsNew: isCopyAsNew,
      PlanItemSID: PlanItemSID,
      IsOutbox: isOutbox || IsOutbox,
    });
    setIsOpenFlowChart(true);
    if (!allTaskInfo) {
      setIsLoadingAllTaskInfo(true);
      CallApiWithContext(allTaskInfoUrl, authenticationContext, data)
        .then((res) => {
          if (res) {
            setAllTaskInfo(res.allTaskInfo);
            setMoreTaskInfo(res.moreTaskInfo);
          } else {
            setAllTaskInfo(null);
            setMoreTaskInfo(null);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoadingAllTaskInfo(false);
        });
    }
  };
  const handleCloseFlowChart = () => {
    setIsOpenFlowChart(false);
  };

  /* init form event */
  const initFormRuntime = () => {
    setCaseInfo(undefined);
  };
  const resizeActivityLogHeight = (newHeight, timeout = 100) => {
    if (isOpenActivityLog) {
      if (resizeActivityLogTimeout) {
        clearTimeout(resizeActivityLogTimeout);
      }

      const resize = () => {
        const progressBar = document.querySelector("#progressBar");
        const maxHeight =
          window.innerHeight - progressBar.getBoundingClientRect().bottom - 5;
        const formHeight = maxHeight - newHeight;
        const bar = document
          .querySelector("#ActivityLogPaper")
          .querySelector("div");
        const logContent = document.querySelector("#logContent");

        if (bar && logContent) {
          const paper = logContent.offsetHeight + bar.offsetHeight;
          const minHeight = Math.min(paper, 150);

          if (newHeight <= paper) {
            if (newHeight > minHeight && formHeight > 0) {
              setActivityLogDrawerHeight(newHeight);
            } else if (newHeight < minHeight) {
              // set minimum height
              setActivityLogDrawerHeight(minHeight);
              setIsOpenActivityLog(false);
            } else {
              setActivityLogDrawerHeight(maxHeight);
            }
          } else {
            setActivityLogDrawerHeight(paper);
          }
        }
      };

      if (timeout === 0) {
        resize();
      } else {
        resizeActivityLogTimeout = window.setTimeout(() => {
          resizeActivityLogTimeout = undefined;
          resize();
        }, timeout);
      }
    }
  };

  /* flow chart dialog */
  const handleOpenFullScreen = () => {
    setFullScreen(true);
  };
  const handleExitFullScreen = () => {
    setFullScreen(false);
  };

  const renderForm = () => {
    return (
      <Grid
        component={"div"}
        id="formContainer"
        container
        item="true"
        justifyContent={"center"}
        alignItems={"flex-start"}
        xs
        sx={{
          flex: 1,
          height: viewerHeight,
          overflow: "auto",
        }}
      >
        {caseInfo?.formType === "JsonForm" ? (
          <JsonFormViewer
            ref={formRef}
            isNewWorkOrder={isTriggerCase}
            isReadOnly={isReadOnly}
            isOutbox={isOutbox || IsOutbox}
            caseTplUUID={caseInfo?.tplUUID}
            planItemSID={PlanItemSID || caseInfo?.currentPlanItemSid}
            planItemTplSID={PlanItemTplSID}
            caseSID={CaseSID}
            fullscreen={formFullscreen}
            alertMessage={[showAlertMessage, closeAlertMessage]}
          />
        ) : caseInfo ? (
          <C1PdfViewer
            ref={formRef}
            isOutbox={isOutbox || IsOutbox}
            caseTplID={caseInfo?.tplId}
            caseTplUUID={caseInfo?.tplUUID}
            readonly={isReadOnly}
            name={caseInfo?.name}
            viewerHeight={viewerHeight}
          />
        ) : null}
      </Grid>
    );
  };

  React.useEffect(() => {
    if (isOpenActivityLog) {
      resizeActivityLogHeight(activityLogDrawerHeight);
    }
  }, [windowHeight, historyLogType]);
  /* get activity log */
  React.useEffect(() => {
    getActivityLogs();
  }, [CaseSID, caseInfo, isOpenActivityLog, isOpenFlowChart]);
  /* get case info */
  React.useEffect(() => {
    getCaseInfo();
  }, [authenticationContext, isTriggerCase, CaseSID, PlanItemSID, uuid]);
  /* load task info */
  React.useEffect(() => {
    const title = currentTask?.name || caseInfo?.name;

    isActivityLogLoaded.current = false;
    setIsOpenActivityLog(false);
    setActivityLogDrawerHeight(300);
    setHistoryLogType("info");
    setTitle(title);

    if (currentTask) {
      setFormIdentity((prev) => ({
        ...prev,
        caseSID: CaseSID,
        taskUUID: PlanItemSID,
        tplUUID: caseInfo?.tplUUID,
        name: title,
        action: action,
        isOutbox: IsOutbox || isOutbox,
        caseName: caseInfo?.name,
        caseNumber: caseInfo?.caseNumber,
      }));
    } else {
      setFormIdentity((prev) => ({
        ...prev,
        caseSID: CaseSID,
        tplUUID: caseInfo?.tplUUID,
        name: caseInfo?.name,
        action: "",
        isOutbox: IsOutbox || isOutbox,
        caseName: caseInfo?.name,
        caseNumber: caseInfo?.caseNumber,
      }));
    }
  }, [caseInfo, currentTask, isTriggerCase]);
  /* flow chart dialog */
  React.useEffect(() => {
    if (!isSmUp) {
      setFullScreen(true);
    }
  }, [isSmUp]);

  return (
    <div style={{ height: "100%" }}>
      {noPermission ? (
        <NoPermissionPage />
      ) : (
        <>
          <Grid container direction="column" sx={{ height: "100%", zIndex: 1 }}>
            {/* case bar */}
            <Stack
              position={"relative"}
              direction={"row"}
              alignItems={"flex-end"}
              id="progressBar"
              zIndex={isSmUp ? 1201 : 1}
            >
              <Collapse in={showCasebar}>
                <Grid container alignItems={"flex-end"}>
                  <Grid
                    xs
                    className="barContainer"
                    overflow={"auto"}
                    width={"100%"}
                  >
                    <StageProgressBar
                      isLoading={stages === null || stages.length === 0}
                      stages={stages ?? []}
                      currentStage={caseInfo?.currentStage}
                      caseInfo={caseInfo}
                    />
                  </Grid>
                  <Grid xs={"auto"} sx={{ display: { md: "none" } }}>
                    <IconButton
                      color="inherit"
                      onClick={() => setIsRightDrawerOpen((prev) => !prev)}
                      sx={{
                        mr: 1,
                        display: { md: "none" },
                      }}
                    >
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Collapse>

              <Tooltip title={showCasebar ? "Hide case bar" : "Show case bar"}>
                <div
                  style={{
                    position: "absolute",
                    right: "8px",
                    top: "0px",
                    height: "32px",
                    widht: "32px",
                    fontSize: "16px",
                    padding: "4px 8px 8px 8px",
                    borderRadius: "0 0 50% 50%",
                    backgroundColor: theme.palette.grey[400],
                    color: theme.palette.primary.contrastText,
                    cursor: "pointer",
                  }}
                  onClick={() => setShowCasebar((prev) => !prev)}
                >
                  <>
                    {showCasebar ? (
                      <KeyboardArrowUpIcon fontSize="intherit" />
                    ) : (
                      <KeyboardArrowDownIcon fontSize="intherit" />
                    )}
                  </>
                </div>
              </Tooltip>
            </Stack>

            {/* form viewer and action drawer */}
            <Grid
              container
              xs
              id="formViewer"
              direction={"column"}
              sx={{ overflow: "hidden" }}
            >
              {/* form viewer container */}
              <div
                ref={formViewerRef}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflow: "hidden",
                  flex: 1,
                }}
              >
                {/* form runtime */}
                {forbidden ? (
                  <div style={{ flex: 1, height: viewerHeight }}>
                    <NoPermissionPage />
                  </div>
                ) : (
                  <ReflexContainer orientation="vertical">
                    <ReflexElement className="left-pane">
                      <div className="pane-content">{renderForm()}</div>
                    </ReflexElement>
                    {isDockedPreview ? (
                      <ReflexSplitter
                        style={{
                          width: "4px",
                          backgroundColor: theme.palette.primary.main,
                        }}
                      />
                    ) : null}
                    <ReflexElement
                      className="right-pane"
                      flex={isDockedPreview ? 0.4 : 0}
                      maxSize={isDockedPreview ? undefined : 0}
                    >
                      <div className="pane-content">
                        {isDockedPreview ? (
                          <>
                            <Stack
                              direction={"row"}
                              px={2}
                              alignItems={"center"}
                            >
                              <Typography
                                flex={1}
                                textAlign={"left"}
                                color={"gray"}
                              >
                                {previewAttachment?.fileName ?? "Preview"}
                              </Typography>
                              <Tooltip title="Unpin preview">
                                <IconButton
                                  onClick={() =>
                                    setIsDockedPreview((prev) => !prev)
                                  }
                                >
                                  <PushPinIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Close the preview">
                                <IconButton
                                  onClick={() =>
                                    setIsDockedPreview((prev) => !prev)
                                  }
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                            <DocumentPreview
                              formIdentity={{
                                caseSID: openingSubCase ? undefined : CaseSID,
                                caseTplUUID: caseInfo?.tplUUID,
                                tempName: tempName,
                                planItemSID: PlanItemSID,
                              }}
                              height={viewerHeight - 40}
                              attachmentName={previewAttachmentControlName}
                              file={previewAttachment}
                            />
                          </>
                        ) : null}
                      </div>
                    </ReflexElement>
                  </ReflexContainer>
                )}

                {/* right hand side drawer */}
                <Grid xs={"auto"}>
                  <Box
                    component="nav"
                    sx={{
                      width: isMdUp ? DRAWER_WIDTH : null,
                      height: "100%",
                      flexShrink: { sm: 0 },
                    }}
                  >
                    <Drawer
                      variant={isMdUp ? "permanent" : "temporary"}
                      open={isRightDrawerOpen}
                      onClose={handleToggleRightDrawer}
                      anchor={"right"}
                      sx={{
                        display: { md: "block" },
                        "& .MuiDrawer-paper": {
                          position: "relative",
                          width: isMdUp ? DRAWER_WIDTH : "100%",
                          bgcolor: (theme) =>
                            theme.palette.mode === "dark"
                              ? theme.palette.background.paper
                              : "#eaeff1",
                          color: (theme) => theme.palette.text.primary,
                        },
                      }}
                    >
                      <List
                        id="contentTable"
                        style={{ height: isMdUp ? viewerHeight : "100%" }}
                        sx={{
                          "&.MuiListItemButton-gutters": {
                            backgroundColor: "white",
                            color: "rgba(0, 0, 0, 0.87)",
                          },
                        }}
                        disablePadding
                      >
                        {/* close drawer button */}
                        {isMdUp ? null : (
                          <Tooltip title={t("formruntime.close_action_panel")}>
                            <IconButton
                              color="inherit"
                              onClick={() =>
                                setIsRightDrawerOpen((prev) => !prev)
                              }
                              sx={{
                                ml: 0.5,
                                display: { md: "none", sm: "inline-flex" },
                              }}
                            >
                              <KeyboardArrowRightIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        <ListItem
                          id="contentText"
                          style={listItemStyles.header}
                        >
                          <ListItemText primary={t("formruntime.actions")} />
                        </ListItem>

                        {/* action buttons */}
                        {caseInfo?.allowCompleteStage ? (
                          <ListItem disablePadding>
                            <ListItemButton
                              disableGutters
                              onClick={() =>
                                handleCompleteStageClick(
                                  caseInfo.allowCompleteStage
                                )
                              }
                            >
                              <ListItemIcon>
                                <TaskAltIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={t("formruntime.complete")}
                              />
                            </ListItemButton>
                          </ListItem>
                        ) : isReadOnly && !isTriggerCase ? (
                          <ListItem disablePadding>
                            <ListItemButton
                              disableGutters
                              onClick={() =>
                                handleActionButtonClick("_Trigger_")
                              }
                            >
                              <ListItemIcon>
                                <ContentCopyIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={t("formruntime.clone_this_case")}
                              />
                            </ListItemButton>
                          </ListItem>
                        ) : (
                          actionButtons.map((btn, index) => (
                            <ListItem
                              key={`action_buttons_${index}`}
                              disablePadding
                            >
                              <ListItemButton
                                onClick={() => handleActionButtonClick(btn)}
                                disableGutters
                              >
                                <ListItemIcon>
                                  <TaskAltIcon />
                                </ListItemIcon>
                                {btn === "Approve" ? (
                                  <ListItemText
                                    primary={t("formruntime.approve")}
                                  />
                                ) : null}
                                {btn === "Reject" ? (
                                  <ListItemText
                                    primary={t("formruntime.reject")}
                                  />
                                ) : null}
                                {btn === "Complete" ? (
                                  <ListItemText
                                    primary={t("formruntime.complete")}
                                  />
                                ) : null}
                                {btn === "Approve" ||
                                btn === "Reject" ||
                                btn === "Complete" ? null : (
                                  <ListItemText
                                    primary={btn.text ? btn.text : btn}
                                  />
                                )}
                              </ListItemButton>
                            </ListItem>
                          ))
                        )}

                        {actionButtons.includes("Cancel") ? null : (
                          <ListItem disablePadding>
                            <ListItemButton
                              onClick={() => handleActionButtonClick("_Close_")}
                              disableGutters
                            >
                              <ListItemIcon>
                                <CloseIcon />
                              </ListItemIcon>
                              <ListItemText primary={t("formruntime.cancel")} />
                            </ListItemButton>
                          </ListItem>
                        )}

                        <Divider />

                        {/* optional task list */}
                        {optionalTasks?.length > 0 ? (
                          <>
                            <ListItem
                              id="contentText"
                              style={listItemStyles.header}
                            >
                              <ListItemText
                                primary={t("formruntime.more_tasks")}
                              />
                            </ListItem>
                            {optionalTasks.map((item, index) => {
                              return (
                                <ListItem
                                  key={`optionalTask_${index}`}
                                  disablePadding
                                >
                                  <ListItemButton
                                    key={item.id}
                                    onClick={() =>
                                      handleOptionalTaskClick(item, index)
                                    }
                                    disableGutters
                                  >
                                    <ListItemIcon>
                                      <AssignmentIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={item.name} />
                                  </ListItemButton>
                                </ListItem>
                              );
                            })}
                            <Divider />
                          </>
                        ) : null}

                        {/* check whether any attachment control can view
                        if yes, show the attachment header */}
                        {attachmentSecurities.every(
                          (s) =>
                            !s?.permission.canPreview &&
                            !s?.permission.canUpload &&
                            !s?.permission.canDownload &&
                            !s?.permission.canDelete
                        ) ? null : (
                          <ListItem style={listItemStyles.header}>
                            <ListItemText
                              primary={t("formruntime.attachment")}
                            />
                          </ListItem>
                        )}
                        {/* if any of attachment control can view, 
                        display the attachment control(s). */}
                        {attachmentSecurities?.map((s, index) => (
                          <AttachmentControl
                            caseInfo={caseInfo}
                            caseIdentity={{
                              caseSID: openingSubCase ? undefined : CaseSID,
                              tempName: tempName,
                              planItemSID: PlanItemSID,
                              planItemID: TaskID,
                            }}
                            security={s}
                            existingAttachments={attachments[s.name]}
                            showAlertMessage={showAlertMessage}
                            closeAlertMessage={closeAlertMessage}
                            onPreviewClick={handleOpenAttachmentPreview}
                          />
                        ))}
                        {attachmentSecurities?.length > 0 ? <Divider /> : null}

                        {/* related case list */}
                        {caseInfo?.relatedCases?.length > 0 ? (
                          <>
                            <ListItem style={listItemStyles.header}>
                              <ListItemText
                                primary={t("formruntime.related_cases")}
                              />
                            </ListItem>
                            {caseInfo.relatedCases.map((c, index) => (
                              <ListItem
                                key={`related_case_${index}`}
                                disablePadding
                              >
                                <ListItemButton
                                  disableGutters
                                  component={Link}
                                  to={`${process.env.PUBLIC_URL}/case/${c.childCaseSID}`}
                                  color="inherit"
                                >
                                  <ListItemIcon>
                                    {c.state === ELEMENT_STATUS.Completed ? (
                                      <CheckIcon color="success" />
                                    ) : c.state ===
                                      ELEMENT_STATUS.Terminated ? (
                                      <CloseIcon color={"error"} />
                                    ) : c.locked ? (
                                      <BlockIcon color="error" />
                                    ) : (
                                      <BusinessCenterIcon />
                                    )}
                                  </ListItemIcon>
                                  <ListItemText>
                                    {c.childCaseNumber}
                                    {c.childCaseDisplayName ? (
                                      <>
                                        <br />
                                        <Typography variant={"caption"}>
                                          {c.childCaseDisplayName}
                                        </Typography>
                                      </>
                                    ) : null}
                                  </ListItemText>
                                </ListItemButton>
                              </ListItem>
                            ))}
                            <Divider />
                          </>
                        ) : null}
                      </List>
                    </Drawer>
                  </Box>
                </Grid>
              </div>
            </Grid>
            {/* activity log drawer */}
            <Grid
              xs={"auto"}
              id="drawerContainer"
              sx={{
                height: isOpenActivityLog
                  ? `${activityLogDrawerHeight - 3}px`
                  : "45px",
              }}
            >
              <Drawer
                id="ActivityLogDrawer"
                open={isOpenActivityLog}
                anchor={"bottom"}
                variant={"permanent"}
                PaperProps={{ sx: { overflowY: "hidden" } }}
              >
                <div
                  id="DrawerGrip"
                  onPointerDown={handleActivityLogDrawerGripPointerDown}
                  style={{
                    display: showActivityLogSplitter ? "block" : "none",
                    height: "5px",
                    cursor: "ns-resize",
                    padding: "0 0 0 4px",
                    position: "sticky",
                    backgroundColor:
                      theme.palette.mainContainer[theme.palette.mode],
                    top: 0,
                    right: 0,
                    left: 0,
                    zIndex: 100,
                  }}
                />
                <Paper
                  id="ActivityLogPaper"
                  sx={{
                    ml:
                      isDesktopMode && uiSettings.drawerOpen
                        ? `${DRAWER_WIDTH}px`
                        : 0,
                    maxHeight: activityLogDrawerHeight,
                    minHeight:
                      isMdUp || !isOpenActivityLog
                        ? 0
                        : document.body.offsetHeight,
                    borderRadius: 0,
                    overflow: "auto",
                  }}
                >
                  <Toolbar
                    sx={{
                      bgcolor: `secondaryContainer.${themeColorContext[0]}`,
                      position: "sticky",
                      top: 0,
                      zIndex: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        overflow: "hidden",
                      }}
                    >
                      <Tooltip
                        title={
                          formFullscreen
                            ? t("formruntime.view_in_page_mode")
                            : t("formruntime.view_in_fullwidth")
                        }
                      >
                        <IconButton
                          onClick={() => setFormFullscreen((prev) => !prev)}
                        >
                          {formFullscreen ? (
                            <NoteIcon sx={{ transform: "rotate(-90deg)" }} />
                          ) : (
                            <FullscreenIcon />
                          )}
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Open flow chart">
                        <IconButton onClick={handleOpenFlowChart}>
                          <AccountTreeOutlinedIcon />
                        </IconButton>
                      </Tooltip>

                      {CaseSID &&
                      profile?.accountType === 1 &&
                      !IsTerminalState(caseInfo?.state) ? (
                        <IconButton onClick={handleCaseMoreVertClick}>
                          <MoreVertIcon />
                        </IconButton>
                      ) : null}

                      <Dialog
                        open={isOpenFlowChart}
                        onClose={handleCloseFlowChart}
                        fullScreen={fullScreen}
                        sx={{
                          "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                              width: "100%",
                              maxWidth: fullScreen ? "100%" : "60%",
                              height: fullScreen ? "100%" : "60%",
                            },
                          },
                        }}
                      >
                        <IconButton
                          onClick={handleCloseFlowChart}
                          sx={{
                            position: "absolute",
                            right: 5,
                            top: 5,
                            zIndex: 1,
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                        {isSmUp ? (
                          fullScreen ? (
                            <IconButton
                              size="small"
                              onClick={handleExitFullScreen}
                              sx={{
                                position: "absolute",
                                right: 5,
                                bottom: 5,
                                zIndex: 1,
                              }}
                            >
                              <FullscreenExitIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              size="small"
                              onClick={handleOpenFullScreen}
                              sx={{
                                position: "absolute",
                                right: 5,
                                bottom: 5,
                                zIndex: 1,
                              }}
                            >
                              <FullscreenIcon />
                            </IconButton>
                          )
                        ) : null}
                        {caseInfo &&
                        !isLoadingAllTaskInfo &&
                        !isLoadingCurrentStageLog &&
                        !isLoadingHistoryLog ? (
                          <Flow
                            currentTask={currentTask}
                            caseInfo={caseInfo}
                            allTaskInfo={allTaskInfo}
                            activityLog={activityLog}
                            currentStageLog={currentStageLog}
                            moreTaskInfo={moreTaskInfo}
                            copyAsNew={isCopyAsNew}
                          />
                        ) : (
                          <Grid
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "center",
                              alignContent: "center",
                              height: "100%",
                            }}
                          >
                            <CircularProgress color="success" />
                          </Grid>
                        )}
                      </Dialog>

                      <Menu
                        anchorEl={caseMenuAnchorEl}
                        open={openCaseMenuAnchorEl}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "center",
                        }}
                        slotProps={{ paper: { style: { minWidth: "150px" } } }}
                        onClose={() => setCaseMenuAnchorEl(null)}
                        sx={{
                          "& .MuiTypography-body1": {
                            fontSize: "16px",
                          },
                          "& .MuiMenuItem-root > .MuiListItemIcon-root": {
                            minWidth: "auto",
                          },
                        }}
                      >
                        {caseInfo?.state === ELEMENT_STATUS.Failed && (
                          <MenuItem onClick={handleReactivateCaseClick}>
                            <ListItemIcon>
                              <ReplayIcon />
                            </ListItemIcon>
                            <ListItemText>
                              {t("formruntime.reactivate")}
                            </ListItemText>
                          </MenuItem>
                        )}
                        {caseInfo?.state === ELEMENT_STATUS.Active && (
                          <MenuItem onClick={handleSuspendCaseClick}>
                            <ListItemIcon>
                              <PauseIcon />
                            </ListItemIcon>
                            <ListItemText>
                              {t("formruntime.suspend")}
                            </ListItemText>
                          </MenuItem>
                        )}
                        {caseInfo?.state === ELEMENT_STATUS.Suspended && (
                          <MenuItem onClick={handleResumeCaseClick}>
                            <ListItemIcon>
                              <PlayCircleIcon />
                            </ListItemIcon>
                            <ListItemText>
                              {t("formruntime.resume")}
                            </ListItemText>
                          </MenuItem>
                        )}
                        <Divider />
                        <MenuItem
                          onClick={handleTerminateCaseClick}
                          sx={{ color: theme.palette.error.main }}
                        >
                          <ListItemIcon>
                            <CloseIcon />
                          </ListItemIcon>
                          <ListItemText>
                            {t("formruntime.terminate")}
                          </ListItemText>
                        </MenuItem>
                      </Menu>
                    </Box>
                    {caseInfo?.accessibleUsers ? (
                      <AccessibleUsers
                        accessibleUsers={caseInfo?.accessibleUsers}
                        maxAvatarCount={maxAvatarCount}
                        fullScreen={!isMdUp}
                        parentWidth={viewerWidth}
                      />
                    ) : null}
                    <Box component={"span"}>
                      <IconButton
                        color="inherit"
                        onClick={handleToggleActivityLog}
                      >
                        {isOpenActivityLog ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <KeyboardArrowUpIcon />
                        )}
                      </IconButton>
                      <Typography component={"span"}>
                        {t("formruntime.gcpdf_viewer.activity_log")}
                      </Typography>
                    </Box>
                  </Toolbar>

                  <ActivityLog
                    ref={activityLogRef}
                    open={isOpenActivityLog}
                    activityLog={activityLog}
                    isLoadingHistoryLog={isLoadingHistoryLog}
                    currentStageLog={currentStageLog}
                    isLoadingCurrentStageLog={isLoadingCurrentStageLog}
                    caseInfo={caseInfo}
                    caseSID={CaseSID}
                    showAlertMessage={showAlertMessage}
                    closeAlertMessage={closeAlertMessage}
                    onLogTypeChange={handleOnLogTypeChange}
                    onActivityLogUpdate={handleActivityLogUpdate}
                  />
                </Paper>
              </Drawer>
            </Grid>
          </Grid>
          <LoadingSpinner isOpen={isLoading} />
          {isDockedPreview ? null : (
            <Dialog
              open={showPreviewDialog}
              onClose={handleCloseAttachmentPreview}
            >
              <DialogTitle>
                {previewAttachment?.fileName ?? "Preview"}
              </DialogTitle>
              <Stack
                display={"inline-block"}
                sx={{ position: "absolute", right: 8, top: 8 }}
              >
                <Tooltip title="Pin the preview">
                  <IconButton onClick={() => setIsDockedPreview(true)}>
                    <PushPinIcon sx={{ transform: "rotate(45deg)" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Close preview dialog">
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setShowPreviewDialog(false);
                      setPreviewAttachment(null);
                    }}
                    sx={(theme) => ({
                      color: theme.palette.grey[500],
                    })}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <DialogContent sx={{ width: "600px", pt: 0 }}>
                <DocumentPreview
                  formIdentity={{
                    caseSID: openingSubCase ? undefined : CaseSID,
                    caseTplUUID: caseInfo?.tplUUID,
                    tempName: tempName,
                    planItemSID: PlanItemSID,
                  }}
                  attachmentName={previewAttachmentControlName}
                  file={previewAttachment}
                />
              </DialogContent>
            </Dialog>
          )}
          <SharedDialog
            isOpen={showAlertDialog}
            onClose={() => setShowAlertDialog(false)}
            title={alertDialog.title}
            content={alertDialog.content}
            buttons={alertDialog.buttons}
          />
        </>
      )}
    </div>
  );
};

FormRuntime.propTypes = {
  isOutbox: PropTypes.bool,
  settitle: PropTypes.func.isRequired,
  setFormIdentity: PropTypes.func.isRequired,
};

export default FormRuntime;
