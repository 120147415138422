import React, { useContext, createContext, useState } from "react";

const UISettingsProvider = (props) => {
  const [uiSettings, setUISettings] = useState({ drawOpen: false });

  return <Context.Provider value={[uiSettings, setUISettings]} {...props} />;
};

const Context = createContext(null);

const useUISettings = () => useContext(Context);

export { UISettingsProvider, useUISettings };
