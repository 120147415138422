import * as React from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { useTranslation } from "react-i18next";
import {
  Box,
  ButtonBase,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import { ArrowCircleUp, Refresh, Settings, Square } from "@mui/icons-material";
import urlJoin from "url-join";
import { resServerBaseUrl } from "../../../Config";
import { CallApiWithContext } from "../../../helpers/ApiHelper";
import { useAuthentication } from "../../../providers/AuthenticationProvider";
import "../Dashboard.css";
import SharedDialog from "../../../shared/SharedDialog";
import dayjs from "dayjs";
import { useTheme } from "@emotion/react";
import { Color } from "@grapecity/wijmo";
import PropTypes from "prop-types";
import utc from "dayjs/plugin/utc";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import useEvent from "react-use-event-hook";
import { PieChart, useDrawingArea } from "@mui/x-charts";
import { styled } from "@mui/material/styles";

dayjs.extend(utc);
dayjs.extend(CustomParseFormat);

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: "15px",
  lineBreak: "auto",
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();

  return (
    <>
      {children[1] === "" ? null : (
        <StyledText x={left + width / 2} y={top + height / 2 - 15}>
          {`${children[1]} : `}
        </StyledText>
      )}

      <StyledText x={left + width / 2} y={top + height / 2}>
        {children[0]}
      </StyledText>
    </>
  );
}

const PieCharts = React.forwardRef((props, ref) => {
  const { settings, settingsSID, editFunction, isMidDevice } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const authenticationContext = useAuthentication();
  const [dashboardSetting, setDashboardSetting] = React.useState({});
  const [criteriaType, setCriteriaType] = React.useState({
    x: "",
    y: "",
  });
  const [chartDatas, setChartDatas] = React.useState({});
  const [chartRequests, setChartRequests] = React.useState({});
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const [alertDialog, setAlertDialog] = React.useState({
    title: "",
    content: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => setShowAlertDialog(false),
      },
    ],
  });
  const [request, setRequest] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [lastLevelObj, setLastLevelObj] = React.useState();
  const [data, setData] = React.useState([]);
  const [lastSelectedSection, setLastSelectedSection] = React.useState({});
  const [canDrill, setCanDrill] = React.useState(false);
  const levelRef = React.useRef();
  const chartRef = React.useRef();

  const timezone = dayjs.tz.guess();

  const palette = (start, end, length) => {
    const startColor = parseInt("FF" + start, 16);
    const endColor = parseInt("FF" + end, 16);
    let colorArr = [];

    for (let count = 0; count < length; count++) {
      let color;
      if (count === 0) {
        color = "#" + start;
      } else {
        color =
          "#" +
          Math.round(startColor + (endColor - (startColor / length) * count))
            .toString(16)
            .slice(-6);
      }

      colorArr.push(color);
    }
    return colorArr;
  };

  const handleDrillDown = (p) => {
    if (
      levelRef.current + 1 <
      Object.keys(JSON.parse(settings.settings).x).length
    ) {
      setLoading(true);
      levelRef.current = levelRef.current + 1;
      let selectedFunction = JSON.parse(settings.settings).chartRequest
        .Function[0];
      let sectionObj = lastSelectedSection;
      sectionObj[levelRef.current] = data.find(
        (d) => d.id === p.dataIndex
      ).label;
      setLastSelectedSection(sectionObj);
      GenerateDashboardData(
        levelRef.current,
        sectionObj[levelRef.current],
        selectedFunction
      );
    }
  };

  const GenerateDashboardData = (level, selectedSection, selectedFunction) => {
    const url = urlJoin(resServerBaseUrl, "Report/GenerateDashboardData");
    const data = {
      DashboardSettingSID: settingsSID,
      Level: level ?? 0,
      SelectedSection: selectedSection ?? null,
      Function: selectedFunction,
    };

    // let chartData;
    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((response) => {
        if (!Object.keys(response).includes("message")) {
          GenerateChartData(response);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const GenerateChartData = (dataArr) => {
    let data = [];
    let sum = new Intl.NumberFormat(undefined, {
      maximumSignificantDigits: 3,
    }).format(dataArr[0]?.Total ?? 0);
    let functions = JSON.parse(settings.settings).chartRequest.Function;
    if (dataArr.length > 0) {
      data = dataArr[0].Data.map((a, i) => {
        return {
          id: i,
          value: a.value,
          label: dayjs(a.label, "MM/DD/YYYY HH:mm:ss", true).isValid()
            ? dayjs
                .utc(a.label, "MM/DD/YYYY HH:mm:ss")
                .tz(timezone)
                .format("YYYY-MM-DD HH:mm:ss")
            : a.label,
        };
      });
      if (data.length > 20) {
        data = data.sort((v1, v2) => v2.value - v1.value).slice(0, 20);
      }
    }
    setData(data);
    // if (Object.keys(chartData.data).length > 0) {
    //   dataArr = Object.keys(chartData.data[chartData.function[0]].Data).map(
    //     (key) => {
    //       // return { [Object.keys(chartData.x)[0]]: key };
    //       if (key.length > maxNameLength) {
    //         return {
    //           [Object.keys(chartData.x)[levelRef?.current ?? 0] + "(display)"]:
    //             key.slice(0, 3) + "..." + key.slice(-3),
    //           [Object.keys(chartData.x)[levelRef?.current ?? 0]]: key,
    //         };
    //       } else {
    //         return {
    //           [Object.keys(chartData.x)[levelRef?.current ?? 0] + "(display)"]:
    //             key,
    //           [Object.keys(chartData.x)[levelRef?.current ?? 0]]: key,
    //         };
    //       }
    //     }
    //   );

    //   chartData.function.forEach((f, i) => {
    //     data = chartData.data[f].Data;
    //     Object.values(data).forEach((value, index) => {
    //       dataArr[index] = {
    //         id: index,
    //         value: value,
    //         label: Object.keys(data)[index],
    //       };

    //       // if (chartData.function.length > 1) {
    //       //   dataArr[index][
    //       //     Object.keys(chartData.y)[0] + "(" + chartData.function[i] + ")"
    //       //   ] = value;
    //       // } else {
    //       //   dataArr[index][Object.keys(chartData.y)[0]] = value;
    //       // }
    //     });
    //     dataSum = chartData.data[f].Total;
    //   });
    // }
    let setting = {
      sid: settingsSID,
      chartName: JSON.parse(settings.settings).chartName,
      chartType: JSON.parse(settings.settings).chartType,
      chartRow: JSON.parse(settings.settings).chartRow,
      chartColumn: JSON.parse(settings.settings).chartColumn,
      chartOrder: JSON.parse(settings.settings).chartOrder,
      dataSum: sum,
      x: JSON.parse(settings.settings).x,
      y: JSON.parse(settings.settings).y,
      currency: JSON.parse(settings.settings).currency,
    };
    let request = {
      CaseTplUUID: JSON.parse(settings.settings).chartRequest.CaseTplUUID,
      TplSID: JSON.parse(settings.settings).chartRequest.TplSID,
      FilterCriteria: JSON.parse(settings.settings).chartRequest.FilterCriteria,
      IsPreviewReport: JSON.parse(settings.settings).chartRequest
        .IsPreviewReport,
      IsDownload: JSON.parse(settings.settings).chartRequest.IsDownload,
      X: JSON.parse(settings.settings).chartRequest.X,
      Y: JSON.parse(settings.settings).chartRequest.Y,
      Function: JSON.parse(settings.settings).chartRequest.Function,
      Level: JSON.parse(settings.settings).chartRequest.Level,
    };
    setChartDatas(setting);
    setChartRequests(request);
    setLoading(false);
    // dataArr.splice(dataIndex, 1, setting);
    // requestArr.splice(requestIndex, 1, request);
  };

  const handleDrillUp = (event) => {
    setLoading(true);
    let lastSelecSec;
    let lastSelecFun = JSON.parse(settings.settings).chartRequest.Function[0];

    if (levelRef.current - 1 > 0) {
      lastSelecSec = lastSelectedSection[levelRef.current - 1];
    } else if (levelRef.current == 0) {
      lastSelecSec = null;
    }
    levelRef.current = levelRef.current - 1;

    GenerateDashboardData(levelRef.current, lastSelecSec, lastSelecFun);
  };

  const CustomLegend = () => {
    let colors = palette("283d17", "3a6015", data.length);

    let label = (l) => {
      if (
        (JSON.parse(settings.settings)?.chartColumn ?? 1) <= 1 &&
        l.split("").length > 10
      ) {
        return l.slice(0, 3) + "..." + l.slice(-3);
      } else {
        return l;
      }
    };

    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        overflow={"auto"}
        maxHeight={200 * (JSON.parse(settings.settings)?.chartRow ?? 1)}
        height={35 * data.length}
        width={
          (JSON.parse(settings.settings)?.chartColumn ?? 1) > 1 ? 250 : 100
        }
        alignItems={"start"}
      >
        {data.map((d, i) => (
          <ButtonBase
            disabled={!canDrill}
            onClick={(e) => handleLegendDrillDown(e, d)}
          >
            <Grid
              container
              direction={"row"}
              key={d.label}
              sx={{ alignItems: "center", justifyContent: "start" }}
            >
              <Grid item>
                <Square sx={{ color: colors[i] }} />
              </Grid>
              <Grid item>{label(d.label)}</Grid>
            </Grid>
          </ButtonBase>
        ))}
      </Box>
    );
  };

  const boxWidth = (cols) => {
    switch (cols) {
      case 1:
        return 401;
      case 2:
        return 813;
      case 3:
        return 1227;
      case 4:
        return 1621;
      default:
        return 401;
    }
  };

  const handleRefresh = () => {
    setLoading(true);
    if (levelRef.current === 0) {
      GenerateDashboardData(0, null, null);
    } else {
      GenerateDashboardData(
        levelRef.current,
        lastSelectedSection[levelRef.current],
        JSON.parse(settings.settings).chartRequest.Function[0]
      );
    }
  };

  const handleLegendDrillDown = (event, item) => {
    let drillObj = {
      dataIndex: item.id,
    };
    handleDrillDown(drillObj);
  };

  React.useEffect(() => {
    setLoading(true);
    GenerateDashboardData(0, null, null);
    setDashboardSetting(JSON.parse(settings.settings));
    setRequest(JSON.parse(settings.settings).chartRequest);
    levelRef.current = 0;
  }, [settings]);

  React.useEffect(() => {
    if (
      Object.keys(JSON.parse(settings.settings).x).length > 1 &&
      levelRef.current < Object.keys(JSON.parse(settings.settings).x).length - 1
    ) {
      setCanDrill(true);
    } else {
      setCanDrill(false);
    }
  }, [levelRef.current]);

  return (
    <Grid container justifyContent={"center"}>
      <Grid
        container
        xs={12}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item sx={"auto"}>
          {levelRef.current === 0 ? null : (
            <IconButton
              disabled={loading}
              onClick={(e) => {
                //add function to handle setting with last level of criteria
                handleDrillUp(e);
              }}
            >
              <ArrowCircleUp />
            </IconButton>
          )}
        </Grid>
        <Grid item width={150} height={35} overflow={"auto"}>
          <Typography variant="h6">
            {JSON.parse(settings.settings).chartName.split("").length > 25
              ? JSON.parse(settings.settings).chartName.slice(0, 25) + "..."
              : JSON.parse(settings.settings).chartName}
          </Typography>
        </Grid>
        <Grid container sx={"auto"}>
          {JSON.parse(settings.settings)?.DynamicDate ??
          []
            .map((d) => d.range[1] === "now" || d.range[1] === 0)
            .includes(true) ? (
            <Grid item>
              <IconButton
                disabled={loading}
                onClick={() => {
                  handleRefresh();
                }}
              >
                <Refresh />
              </IconButton>
            </Grid>
          ) : null}

          <IconButton
            onClick={() =>
              editFunction(JSON.parse(settings.settings), settings.sid)
            }
            disabled={loading}
          >
            <Settings />
          </IconButton>
        </Grid>
      </Grid>

      {loading || data.length === 0 ? (
        <Box
          width={boxWidth(JSON.parse(settings.settings)?.chartColumn ?? 1)}
          height={350 * (JSON.parse(settings.settings)?.chartRow ?? 1)}
          alignContent={"center"}
        >
          {loading ? t("dashboard.loading") : t("dashboard.no_data")}
        </Box>
      ) : (
        <Box
          width={boxWidth(JSON.parse(settings.settings)?.chartColumn ?? 1)}
          display={"flex"}
          alignItems={"center"}
        >
          <PieChart
            colors={palette("283d17", "3a6015", data?.length ?? 0)}
            margin={{ top: 25, bottom: 25, right: 15 }}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
            loading={loading}
            series={[
              {
                innerRadius: 55,
                outerRadius: isMidDevice
                  ? 140
                  : (JSON.parse(settings.settings)?.chartColumn ?? 1) ===
                    (JSON.parse(settings.settings)?.chartRow ?? 1)
                  ? 120 * (JSON.parse(settings.settings)?.chartColumn ?? 1)
                  : 140,
                paddingAngle: 1,
                data: data,
                valueFormatter: (v, { dataIndex }) => {
                  return ` ${
                    Object.values(JSON.parse(settings.settings)?.y ?? {})[0]
                  }: ${v.value}`;
                },
                endAngle: 360,
              },
            ]}
            width={
              isMidDevice
                ? 200
                : 200 * (JSON.parse(settings.settings)?.chartColumn ?? 1)
            }
            height={350 * (JSON.parse(settings.settings)?.chartRow ?? 1)}
            onItemClick={(e, i) => handleDrillDown(i)}
            sx={{
              "& .MuiPieArc-root": {
                cursor: canDrill ? "pointer" : "default",
              },
            }}
          >
            {data.length === 0 || loading ? null : (
              <PieCenterLabel>
                {chartDatas.dataSum}
                {chartDatas?.currency ?? ""}
              </PieCenterLabel>
            )}
          </PieChart>
          {CustomLegend()}
        </Box>
      )}

      <SharedDialog
        isOpen={showAlertDialog}
        onClose={() => setShowAlertDialog(false)}
        title={alertDialog.title}
        content={alertDialog.content}
        buttons={alertDialog.buttons}
      />
    </Grid>
  );
});

PieCharts.propTypes = {
  settings: PropTypes.object,
  settingSID: PropTypes.string,
  editFunction: PropTypes.func,
  isMidDevice: PropTypes.bool,
};

export default PieCharts;
