import * as React from "react";
import {
  Autocomplete,
  AvatarGroup,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MUIDataTable from "mui-datatables";
import urlJoin from "url-join";
import { useTranslation } from "react-i18next";

import Avatar from "../../shared/FCAvatar";
import { HoverButtonIcon } from "../CaseList/ManageCase";
import { resServerBaseUrl } from "../../Config";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { CallApiWithContext } from "../../helpers/ApiHelper";

const ITEM_HEIGHT = 44;
const ITEM_PADDING_TOP = 8;
const ITEM_COUNT = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * ITEM_COUNT + ITEM_PADDING_TOP,
    },
  },
};

const UserTbl = (props) => {
  const {
    title,
    rows,
    onAdd,
    onDelete,
    fullScreen = false,
    parentWidth,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const actionColumnRender = (dataIndex) => {
    return (
      <>
        {onAdd && rows[dataIndex].manualAssign && (
          <Tooltip title={t("common.delete")}>
            <HoverButtonIcon>
              <DeleteIcon onClick={onDelete} />
            </HoverButtonIcon>
          </Tooltip>
        )}
      </>
    );
  };

  const columns = [
    {
      name: "userName",
      label: t("case_list.manage_case.column_names.user_name"),
      options: {
        searchable: true,
        customBodyRenderLite: (dataIndex) =>
          rows ? (
            (
              <Avatar
                userId={rows[dataIndex].userId}
                userName={rows[dataIndex].userName}
                isRestricted={rows[dataIndex].accountType === 2}
                withUserName
              />
            ) ?? "-"
          ) : (
            <Skeleton sx={{ width: "100px" }} />
          ),
      },
    },
    {
      name: "action",
      options: {
        searchable: false,
        sort: false,
        empty: true,
        customHeadLabelRender: (column) => "",
        customBodyRenderLite: (dataIndex) => actionColumnRender(dataIndex),
        setCellProps: () => ({
          style: {
            width: "10px",
            whiteSpace: "nowrap",
          },
        }),
      },
    },
  ];

  return (
    <div
      style={{
        textAlign: "left",
        maxWidth: `${theme.breakpoints.values.md}px`,
        width: fullScreen
          ? "100%"
          : `${
              Math.min(parentWidth * 0.5, theme.breakpoints.values.md) - 10 * 8
            }px`,
      }}
    >
      <MUIDataTable
        title={title}
        data={
          rows?.map((r) => ({
            ...r,
          })) ?? Array.from(new Array(3))
        }
        columns={columns}
        options={{
          selectableRows: "none",
          download: false,
          print: false,
          viewColumns: false,
          filter: false,
          search: true,
          rowsPerPage: 5,
          rowsPerPageOptions: [5, 10, 25, 100],
          sortOrder: { name: "userName", direction: "asc" },
          setTableProps: () => ({ size: "small" }),
          customToolbar: (displayData) => (
            <>
              {onAdd && (
                <Tooltip title={t("common.add")}>
                  <IconButton onClick={onAdd}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ),
          textLabels: {
            body: {
              noMatch: t("case_list.manage_case.toolbar.no_record_found"),
              toolTip: t("case_list.manage_case.toolbar.sort"),
            },
            pagination: {
              next: t("case_list.manage_case.toolbar.next_page"),
              previous: t("case_list.manage_case.toolbar.prev_page"),
              rowsPerPage: t("case_list.manage_case.toolbar.rows_per_page"),
              displayRows: t("case_list.manage_case.toolbar.display_rows"),
            },
            toolbar: {
              search: t("case_list.manage_case.toolbar.search"),
            },
          },
        }}
      />
    </div>
  );
};

const AccessibleUsers = (props) => {
  const {
    accessibleUsers,
    maxAvatarCount = 5,
    fullScreen = false,
    parentWidth = 300,
    allowAdd = false,
  } = props;
  const authenticationContext = useAuthentication();
  const { t } = useTranslation();

  const [openUserEditor, setOpenUserEditor] = React.useState(false);
  const [openAddUser, setOpenAddUser] = React.useState(false);
  const [userList, setUserList] = React.useState(null);
  const [roleList, setRoleList] = React.useState(null);
  const [addUser, setAddUser] = React.useState();
  const [sameAsUser, setSameAsUser] = React.useState();
  const [addUserRole, setAddUserRole] = React.useState();
  const [assignType, setAssignType] = React.useState("sameAs");

  const handleAddObserver = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (userList === null) {
      const url = urlJoin(resServerBaseUrl, "/User/GetUsersByPermissions");

      CallApiWithContext(url, authenticationContext)
        .then((response) => {
          if (response) {
            const getUserNameArr = Object.keys(response)
              .filter(
                (key) => !accessibleUsers.some((a) => a.id == response[key].id)
              )
              .map((key) => {
                return {
                  id: response[key].id,
                  name: response[key].name,
                  uuid: response[key].uuid,
                };
              });

            setUserList(getUserNameArr);
          } else {
            setUserList([]);
          }
        })
        .catch(console.log)
        .finally(() => {});
    }

    if (roleList === null) {
      const url = urlJoin(resServerBaseUrl, "/User/GetUsersByPermissions");

      CallApiWithContext(url, authenticationContext)
        .then((response) => {
          if (response) {
            const userRole = Array.from(new Array(3)).map((key, idx) => {
              return {
                id: idx,
                name: `Role ${idx + 1}`,
              };
            });

            setRoleList(userRole);
          } else {
            setRoleList([]);
          }
        })
        .catch(console.log)
        .finally(() => {});
    }

    setOpenAddUser(true);
    setAddUser(undefined);
    setAddUserRole(undefined);
  };
  const handleDeleteObserver = (e) => {
    e.preventDefault();
    e.stopPropagation();

    //   const url = urlJoin(resServerBaseUrl, "/User/GetUsersByPermissions");
    //   const data = { caseTplUUID: uuid, sid: rows[dataIndex].sid };
    //   CallApiWithDownloadFile(
    //     url,
    //     authenticationContext,
    //     JSON.stringify(data),
    //     `${rows[dataIndex].name}${
    //       rows[dataIndex].language ? `.${rows[dataIndex].language}` : ""
    //     }.json`
    //   )
    //     .then((res) => {})
    //     .catch((error) => console.log(error));
  };
  const handleMoreAvatarClick = (e) => {
    setOpenUserEditor(true);
  };
  const handleAddButtonClick = (e) => {
    setOpenAddUser(false);
  };

  return (
    <Box component={"span"} display={"inline-flex"} whiteSpace={"nowrap"}>
      <AvatarGroup total={accessibleUsers?.length}>
        {accessibleUsers?.map((usr, idx) =>
          idx < maxAvatarCount ? (
            <Avatar
              slot={{ photoAvatar: { bgcolor: "white" } }}
              userId={usr.id}
              userName={usr.name}
            />
          ) : null
        )}
      </AvatarGroup>

      <IconButton color="inherit" onClick={handleMoreAvatarClick}>
        <MoreHorizIcon />
      </IconButton>

      <Dialog
        maxWidth="md"
        open={openUserEditor}
        fullScreen={fullScreen}
        onClose={() => setOpenUserEditor(false)}
      >
        <DialogTitle />
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={() => setOpenUserEditor(false)}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ px: fullScreen ? 1 : 3 }}>
          <Stack gap={2}>
            <UserTbl
              rows={accessibleUsers
                ?.filter((u) => u.accessType === 0)
                .map((u) => ({
                  userName: u.name,
                  userId: u.id,
                  manualAssign: u.manualAssign,
                }))}
              title={t("formruntime.participants")}
              fullScreen={fullScreen}
              parentWidth={parentWidth}
            />
            <UserTbl
              rows={accessibleUsers
                .filter((u) => u.accessType === 2)
                .map((u) => ({
                  userName: u.name,
                  userId: u.id,
                  manualAssign: u.manualAssign,
                }))}
              title={t("formruntime.observers")}
              fullScreen={fullScreen}
              parentWidth={parentWidth}
              onAdd={allowAdd ? handleAddObserver : undefined}
              onDelete={allowAdd ? handleDeleteObserver : undefined}
            />
          </Stack>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth="md"
        open={openAddUser}
        fullScreen={fullScreen}
        onClose={() => setOpenAddUser(false)}
      >
        <DialogTitle>{t("formruntime.add_observer")}</DialogTitle>
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={() => setOpenAddUser(false)}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack gap={2}>
            <Autocomplete
              fullWidth
              sx={{ minWidth: "350px" }}
              id="observer-autocomplete"
              value={addUser}
              onChange={(e, v) => setAddUser(v)}
              getOptionLabel={(option) => option?.name}
              renderInput={(param) => (
                <TextField {...param} label={"Observer"}></TextField>
              )}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;

                return (
                  <MenuItem key={option.uuid} {...optionProps}>
                    <ListItem disableGutters disablePadding>
                      <ListItemAvatar>
                        <Avatar userName={option.name} userId={option.id} />
                      </ListItemAvatar>
                      <ListItemText>{option.name}</ListItemText>
                    </ListItem>
                  </MenuItem>
                );
              }}
              options={
                userList === null
                  ? [{ name: `${t("common.loading")}...` }]
                  : userList
              }
            />
            <Divider
              sx={{
                fontSize: "12px",
                color: (theme) => theme.palette.grey[500],
              }}
            >
              Security
            </Divider>
            <FormControl
              fullWidth
              sx={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Radio
                checked={assignType === "sameAs"}
                value={"sameAs"}
                onChange={() => {
                  setAssignType("sameAs");
                  setAddUserRole(undefined);
                }}
              />
              <Autocomplete
                fullWidth
                disabled={assignType !== "sameAs"}
                id="observer-same-as-select"
                inputValue={sameAsUser?.name || sameAsUser}
                onChange={(e, v) => setSameAsUser(v)}
                getOptionLabel={(option) => option?.name}
                renderInput={(param) => (
                  <TextField {...param} label={"Same as"}></TextField>
                )}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;

                  return (
                    <MenuItem key={option.uuid} {...optionProps}>
                      <ListItem disableGutters disablePadding>
                        <ListItemAvatar>
                          <Avatar userName={option.name} userId={option.id} />
                        </ListItemAvatar>
                        <ListItemText>{option.name}</ListItemText>
                      </ListItem>
                    </MenuItem>
                  );
                }}
                options={
                  accessibleUsers === null
                    ? [{ name: `${t("common.loading")}...` }]
                    : accessibleUsers
                }
              />
            </FormControl>
            <FormControl
              fullWith
              sx={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Radio
                checked={assignType === "selectRole"}
                value={"selectRole"}
                onChange={() => {
                  setAssignType("selectRole");
                  setSameAsUser(undefined);
                }}
              />
              <FormControl fullWidth disabled={assignType !== "selectRole"}>
                <InputLabel id="observer-role-select-label">Role</InputLabel>
                <Select
                  labelId="observer-role-select-label"
                  id="observer-role-select"
                  label="Role"
                  value={addUserRole}
                  onChange={(e) => setAddUserRole(e.target.value)}
                  maxRows={8}
                >
                  {roleList === null ? (
                    <MenuItem disable={true}>{t("common.loading")}</MenuItem>
                  ) : (
                    roleList.map((role) => (
                      <MenuItem value={role}>
                        <ListItem disableGutters disablePadding>
                          <ListItemText>{role.name}</ListItemText>
                        </ListItem>
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"contained"}
            disabled={
              !addUser ||
              (assignType === "selectRole" && !addUserRole) ||
              (assignType === "sameAs" && !sameAsUser)
            }
            onClick={handleAddButtonClick}
          >
            Add
          </Button>
          <Button
            variant={"outlined"}
            color="error"
            onClick={() => setOpenAddUser(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AccessibleUsers;
