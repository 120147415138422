import * as React from "react";
import {
  FormControl,
  Input,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  MenuList,
  useTheme,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

import Avatar from "../../shared/FCAvatar";

const UserSelectionList = (props) => {
  const { users, currentUserId, onUserSelected, onClose } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchUser, setSearchUser] = React.useState("");

  const handleUserSearchKeyDown = (e) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        e.currentTarget
          .closest(".MuiPaper-root")
          .querySelector(".flc-user-list li")
          ?.focus();
        return false;
      case "Escape":
        e.preventDefault();
        if (searchUser) {
          setSearchUser("");
        } else if (onClose) {
          onClose();
        }
        return false;
      default:
        console.log(e);
        break;
    }
  };
  const handleUserSearchChange = (e) => {
    setSearchUser(e.currentTarget.value);
  };
  const handleReassignUserClick = (e, user) => {
    if (onUserSelected) {
      onUserSelected(e, user);
    }
  };

  const filteredUsers = React.useMemo(() => {
    if (users) {
      return users.filter(
        (user) =>
          user.id !== currentUserId &&
          (!searchUser ||
            user.name.toUpperCase().includes(searchUser.toUpperCase()))
      );
    }

    return [];
  }, [users, currentUserId, searchUser]);

  return (
    <>
      <FormControl sx={{ p: 2, pb: 1 }}>
        <Input
          autoFocus
          startAdornment={
            <SearchIcon
              sx={{
                color: theme.palette.text.secondary,
              }}
            />
          }
          value={searchUser}
          onChange={handleUserSearchChange}
          onKeyDown={handleUserSearchKeyDown}
        />
      </FormControl>
      <div className="" style={{ flex: 1, overflow: "auto" }}>
        <MenuList
          className="flc-user-list"
          sx={{
            "& .MuiListItemButton-gutters": {
              backgroundColor: "white",
              color: "rgba(0, 0, 0, 0.87)",
            },
          }}
        >
          {users ? (
            filteredUsers?.length > 0 ? (
              filteredUsers.map((user) => (
                <MenuItem
                  key={user.uuid}
                  onClick={(e) => handleReassignUserClick(e, user)}
                >
                  <ListItemAvatar>
                    <Avatar userId={user.id} userName={user.name}></Avatar>
                  </ListItemAvatar>
                  <ListItemText>{user.name}</ListItemText>
                </MenuItem>
              ))
            ) : (
              <ListItemText sx={{ px: 2, userSelect: "none" }}>
                No matching users found
              </ListItemText>
            )
          ) : (
            <MenuItem disabled>
              <ListItemText>{`${t("common.loading")}...`}</ListItemText>
            </MenuItem>
          )}
        </MenuList>
      </div>
    </>
  );
};

export default UserSelectionList;
