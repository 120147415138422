import * as React from "react";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import RefreshIcon from "@mui/icons-material/Refresh";
import urlJoin from "url-join";
import { Stack, TablePagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { CallApiWithContext } from "../../helpers/ApiHelper";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { resServerBaseUrl } from "../../Config";
import SearchBar from "../SearchBar/SearchBar";
import "../../MailBox.css";
import SharedDialog from "../../shared/SharedDialog";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useThemeColor } from "../../providers/ThemeColorProvider";
import CustomToolbar from "../../shared/CustomToolbar";
import InOutboxList from "../../shared/InOutboxList";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, parseInt(page) - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, parseInt(page) + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Grid sx={{ flexShrink: 0 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Grid>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function Outbox(props) {
  const setTitle = props.settitle;
  const { pages } = useParams();
  const nav = useNavigate();
  const [totalRecordNum, setTotalRecordNum] = React.useState(0);
  const [page, setPage] = React.useState(pages && !isNaN(pages) ? pages : 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const { t } = useTranslation();
  const [outboxObjects, setOutboxObjects] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [searchData, setSearchData] = React.useState("");

  const [alertDialog, setAlertDialog] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [noPermission, setNoPermission] = React.useState(false);

  const prevDateRef = React.useRef(searchData);
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const themeColorContext = useThemeColor();

  const handleAlertDialogClose = () => {
    setAlertDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage > 0) {
      nav({
        pathname: `/outbox/${newPage + 1}`,
        search: searchParams.toString(),
      });
    } else {
      nav({ pathname: "/outbox", search: searchParams.toString() });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(Math.floor((page * rowsPerPage) / event.target.value));
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const GetOutboxObject = React.useCallback(async () => {
    setIsLoading(true);
    const url = urlJoin(resServerBaseUrl, "/Inbox/GetInbox_Completed");
    CallApiWithContext(
      url,
      authenticationContext,
      JSON.stringify({
        SearchCrtiria: searchData,
        StartIndex: rowsPerPage * page ? rowsPerPage * page : 0,
        PageSize: rowsPerPage,
      })
    )
      .then((respon) => {
        return respon ? respon : null;
      })
      .then((json) => {
        if (json != null && typeof json.message != "undefined") {
          if (json.message == "No permission to get outbox") {
            setNoPermission(true);
          } else if (json.message == "Cannot Get Outbox") {
            setAlertContent(t("inbox_outbox.cannot_get_outbox"));
          }
          setOutboxObjects([]);
          setTotalRecordNum(0);
        } else if (json != null && json != "unauthorized") {
          setNoPermission(false);
          var data = [];
          setTotalRecordNum(json.totalRecordNum);
          json.inbox_CompletedObjects.forEach((element) => {
            var outBoxObject = {
              initiatorName: element.initiatorName,
              initiatorID: element.initiatorID,
              planItemName: element.planItemName,
              planItemID: element.planItemID,
              planItemSID: element.planItemSID,
              caseSID: element.caseSID,
              caseNumber: element.caseNumber,
              endDate: element.endDate,
              milestone: element.milestone,
              state: element.state,
            };
            data.push(outBoxObject);
          });
          setOutboxObjects(
            data.sort((a, b) => {
              return new Date(b.endDate) - new Date(a.endDate);
            })
          );
        } else {
          setOutboxObjects([]);
          setTotalRecordNum(0);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        alert(error);
        setIsLoading(false);
        setOutboxObjects([]);
        setTotalRecordNum(0);
      });
  });

  React.useEffect(() => {
    prevDateRef.current = searchData;
  }, [searchData]);

  const dateHandle = (date) => {
    if (date != null && date != "undefined") return date.substring(0, 10);
  };

  const setSearchBoxItem = React.useCallback((item, page) => {
    // if (item.SearchReset == true) {
    //   item.SearchByFromDate = null;
    //   item.SearchByEndDate = null;
    //   setSearchData(JSON.stringify(item));
    //   window.localStorage.removeItem("outboxSearchData");
    //   setPage(0);
    //   return;
    // }
    // if (
    //   prevDateRef.current != null &&
    //   prevDateRef.current != "undefined" &&
    //   prevDateRef.current != ""
    // ) {
    //   var dePrevDate = JSON.parse(prevDateRef.current);
    //   var tmpStartDate = dateHandle(dePrevDate.SearchByFromDate);
    //   var tmpEndDate = dateHandle(dePrevDate.SearchByEndDate);
    //   if (
    //     item.SearchByFromDate != null &&
    //     item.SearchByFromDate != "undefined" &&
    //     item.SearchByEndDate != null &&
    //     item.SearchByEndDate != "undefined"
    //   ) {
    //     var startDate = dateHandle(item.SearchByFromDate.toJSON());
    //     var endDate = dateHandle(item.SearchByEndDate.toJSON());
    //   } else {
    //     setAlertDialog(true);
    //     setAlertTitle("Warning");
    //     setAlertContent("Please choose start day and end day!");
    //   }
    //   if (tmpStartDate == startDate && tmpEndDate == endDate) {
    //     setAlertDialog(true);
    //     setAlertTitle("Warning");
    //     setAlertContent("Same record!!");
    //     return;
    //   }
    // }

    item.SearchByFromDate = item.SearchByFromDate
      ? item.SearchByFromDate.toJSON()
      : null;
    item.SearchByEndDate = item.SearchByEndDate
      ? item.SearchByEndDate.toJSON()
      : null;

    setSearchData(JSON.stringify(item));
    setPage(page ? page - 1 : 0); //reset to first page and set page after page change
  }, []);

  // const accessTokenContext = useAccessToken();
  const authenticationContext = useAuthentication();

  React.useEffect(() => {
    GetOutboxObject(authenticationContext, outboxObjects);
  }, [searchData, rowsPerPage, page]);

  React.useEffect(() => {
    setTitle(t("left_menu.outbox"));
  }, []);

  return (
    <Stack spacing={1}>
      <CustomToolbar
        componentArr={[
          {
            size: "auto",
            component: (
              <>
                <Tooltip title={t("inbox_outbox.refresh")}>
                  <IconButton
                    onClick={() => {
                      GetOutboxObject(authenticationContext, outboxObjects);
                    }}
                  >
                    <RefreshIcon color="inherit" sx={{ display: "block" }} />
                  </IconButton>
                </Tooltip>
              </>
            ),
          },
          {
            size: isSmallDevice ? 12 : 7,
            component: (
              <>
                {noPermission ? null : (
                  <SearchBar ref={setSearchBoxItem} loading={isLoading} />
                )}
              </>
            ),
          },
          { size: "auto", component: <></> },
        ]}
      />
      <InOutboxList
        isInbox={false}
        objects={outboxObjects}
        isLoading={isLoading}
        noPermission={noPermission}
      />
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 15, 20, 25, 30]}
        labelRowsPerPage={t("inbox_outbox.display_row")}
        count={totalRecordNum}
        rowsPerPage={rowsPerPage}
        page={page}
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          overflow: "hidden",
          width: "100%",
          bgcolor: `mainContainer.${themeColorContext[0]}`,
          justifyContent: "right",
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
      <SharedDialog
        isOpen={alertDialog}
        onClose={handleAlertDialogClose}
        content={alertContent}
        buttons={[
          {
            text: t("administrative_console.report_page.confirm"),
            action: handleAlertDialogClose,
          },
        ]}
      ></SharedDialog>
    </Stack>
  );
}
