import * as React from "react";
import urlJoin from "url-join";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import PropTypes from "prop-types";
import SharedDialog from "../../../../shared/SharedDialog";
import Input from "@mui/material/Input";
import LoadingSpinner from "../../../../shared/LoadingSpinner";
import { useTheme } from "@mui/material/styles";
import {
  Collapse,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TablePagination,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  CallApiWithContext,
  CallApiWithUploadFile,
} from "../../../../helpers/ApiHelper";
import { useAuthentication } from "../../../../providers/AuthenticationProvider";
import { resServerBaseUrl } from "../../../../Config";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TitleWithIcon from "../../../../shared/TitleWithIcon";
import { forEach } from "min-dash";
import { color, Stack } from "@mui/system";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { CaseTplPermission } from "../../../../Constants";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Grid sx={{ flexShrink: 0 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        className="pagePagnation"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Grid>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function ReportSetting() {
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const [caseTemplates, setCaseTemplates] = React.useState(null);
  const [reports, setReports] = React.useState(null);
  const [templateColumn, setTemplateColumn] = React.useState([]);
  const [originColumn, setOriginColumn] = React.useState(null);
  const [column, setColumn] = React.useState(null);
  const [selectedColumn, setSelectedColumn] = React.useState([]);
  const [selectedReport, setSelectedReport] = React.useState(null);
  const [reportTemplate, setReportTemplate] = React.useState(null);
  const [reportTemplateName, setReportTemplateName] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);
  const [applyDialog, setSaveDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [columnName, setColumnName] = React.useState(null);
  const [columnLabel, setColumnLabel] = React.useState(null);
  const [reportName, setReportName] = React.useState(null);
  const [selectedCaseTpl, setSelectedCaseTpl] = React.useState(null);
  const [selected, setSelected] = React.useState([]);
  const [unSelected, setUnSelected] = React.useState([]);
  const [selectedDataType, setDataType] = React.useState(null);
  const [format, setFormat] = React.useState(null);
  const [update, SetUpdate] = React.useState(false);
  const [saveAs, SetSaveAs] = React.useState(false);
  const [allowDelete, SetAllowDelete] = React.useState(false);
  const [criteria, SetCiteria] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingSaveButton, setIsLoadingSaveButton] = React.useState(false);
  const [selectedPage, setSelectedPage] = React.useState(0);
  const [unSelectedPage, setUnSelectedPage] = React.useState(0);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = React.useState(10);
  const [unSelectedRowsPerPage, setUnSelectedRowsPerPage] = React.useState(10);
  const [decisionOption, setDecisionOption] = React.useState([]);
  const [milestoneOption, setMilestoneOption] = React.useState([]);
  const [parent, setParent] = React.useState();
  const [parentColumnOpen, setParentColumnOpen] = React.useState({});
  const dataType = [
    {
      label: t("administrative_console.report_page.data_type.string"),
      value: "String",
    },
    {
      label: t("administrative_console.report_page.data_type.number"),
      value: "Number",
    },
    {
      label: t("administrative_console.report_page.data_type.date"),
      value: "Date",
    },
    {
      label: t("administrative_console.report_page.data_type.dataGroup"),
      value: "DataGroup",
    },
    {
      label: t("administrative_console.report_page.data_type.dynamicList"),
      value: "DynamicList",
    },
  ];
  const systemColumn = [
    {
      label: t(
        "administrative_console.report_page.system_column.case_initiator"
      ),
      value: "case initiator",
      type: dataType[1],
    },
    {
      label: t("administrative_console.report_page.system_column.case_number"),
      value: "case number",
      type: dataType[0],
    },
    {
      label: t("administrative_console.report_page.system_column.case_status"),
      value: "case status",
      type: dataType[1],
    },
    {
      label: t(
        "administrative_console.report_page.system_column.case_create_date"
      ),
      value: "case create date",
      type: dataType[2],
    },
    {
      label: t(
        "administrative_console.report_page.system_column.case_start_date"
      ),
      value: "case start date",
      type: dataType[2],
    },
    {
      label: t(
        "administrative_console.report_page.system_column.case_complete_date"
      ),
      value: "case complete date",
      type: dataType[2],
    },
    {
      label: t(
        "administrative_console.report_page.system_column.case_terminate_date"
      ),
      value: "case terminate date",
      type: dataType[2],
    },
    {
      label: t(
        "administrative_console.report_page.system_column.case_close_date"
      ),
      value: "case close date",
      type: dataType[2],
    },
    {
      label: t("administrative_console.report_page.system_column.decision"),
      value: "decision",
    },
    {
      label: t("administrative_console.report_page.system_column.milestone"),
      value: "milestone",
    },
  ];
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  var test = [];
  const [alertContent, setAlertContent] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");

  React.useEffect(() => {
    setIsLoading(true);
    var url = urlJoin(resServerBaseUrl, "/Case/GetCaseTemplates");
    const data = JSON.stringify({ IsAdministrator: true });
    CallApiWithContext(url, authenticationContext, data)
      .then((response) => {
        console.log(response);
        if (typeof response.message !== "undefined") {
          setAlertDialog(true);
          setAlertContent(response.message);
          setAlertTitle("");
        } else if (response) {
          var url_caseTplpPrmission = urlJoin(
            resServerBaseUrl,
            "/Permission/GetCaseTemplatePermissions"
          );

          CallApiWithContext(url_caseTplpPrmission, authenticationContext).then(
            (permissionResponse) => {
              var filteredResponses = [];
              response.forEach((element) => {
                var filteredTemplates = element.templateInfos.filter((tInfo) =>
                  permissionResponse.some(
                    (permission) =>
                      permission.permissionType > CaseTplPermission.None
                  )
                );
                if (filteredTemplates && filteredTemplates.length > 0) {
                  var toAddResponse = element;
                  toAddResponse.templateInfos = filteredTemplates;
                  filteredResponses.push(toAddResponse);
                }
              });
              setCaseTemplates(filteredResponses);
            }
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [authenticationContext]);

  React.useEffect(() => {
    if (!dialog) {
      setColumnName(null);
      setColumnLabel(null);
      setDataType(null);
      setFormat(null);
      setParent(null);
    }
  }, [dialog]);

  React.useEffect(() => {
    // let max = document.getElementById("routingBox");
    // // setTableMaxWidth(max.offsetWidth * 0.96);
    // // setSmallTableWidth((max.offsetWidth * 0.96) / 3);
    // setTableMaxWidth(max.offsetWidth - max.offsetWidth * 0.015);
    // setSmallTableWidth((max.offsetWidth - max.offsetWidth * 0.015) / 3);
    // console.log(columnName.toLowerCase());
  }, []);
  //try set deleteButtonWidth
  React.useEffect(() => {
    // let deleteIcon = document.getElementById("DeleteIcon");
    // setDeleteIconButtonWidth(deleteIcon ? deleteIcon.offsetWidth : "0px");
  }, [allowDelete]);
  //
  const handleTemplateOnChange = (template) => {
    setTemplateColumn([]);
    setSelectedColumn([]);
    setReports(null);
    // try add back clear Report Template name for the confirm save dialog
    setSelectedReport(null);
    setReportName(null);
    setReportTemplate(null);
    // end trial
    SetAllowDelete(false);
    setReportTemplateName(null);
    setUnSelectedPage(0);
    setSelectedPage(0);
    setSelected([]);
    setUnSelected([]);
    if (template) {
      console.log(template.templateName);
      setIsLoading(true);
      var url = urlJoin(resServerBaseUrl, "/Report/GetReportColumn");
      var uuid =
        template.templateName !== "System"
          ? template.uuid
          : "00000000-0000-0000-0000-000000000000";
      var data = JSON.stringify({ CaseTemplateUUID: uuid });
      if (template.templateName !== "System") {
        CallApiWithContext(url, authenticationContext, data)
          .then((response) => {
            //original return ["column name"]
            // console.log(response);
            console.log(response);
            if (response.length > 0) {
              setTemplateColumn(systemColumn.concat(response));
              setColumn(systemColumn.concat(response));
              setOriginColumn(response);
              let obj = {};
              response.map((e) => {
                if (e?.child ?? false) {
                  obj[e.value] = false;
                }
              });
              console.log(obj);
              setParentColumnOpen(obj);
              // setTemplateColumn(
              //   systemColumn.concat(
              //     response.map((col) => {
              //       return { label: col, value: col };
              //     })
              //   )
              // );
              // setColumn(
              //   systemColumn.concat(
              //     response.map((col) => {
              //       return { label: col, value: col };
              //     })
              //   )
              // );
              // setOriginColumn(
              //   response.map((col) => {
              //     return { label: col, value: col };
              //   })
              // );
              setSelectedCaseTpl(template.uuid);
            }
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      } else {
        setSelectedCaseTpl("00000000-0000-0000-0000-000000000000");
        setTemplateColumn(systemColumn);
        setColumn(systemColumn);
        setOriginColumn(systemColumn);
      }
      url = urlJoin(resServerBaseUrl, "/Report/GetExistingReport");
      CallApiWithContext(url, authenticationContext, data)
        .then((response) => {
          console.log(response);
          if (response && response.message !== "Invaild JSON") {
            setReports(response.reports);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };
  const handleReportTemplateOnChange = (report) => {
    setIsLoading(true);
    if (report && reports) {
      const selectedReports = reports.find((r) => r.reportName === report);
      console.log(selectedReports);
      const data = JSON.parse(selectedReports.data);
      console.log(data);
      var templateArr = templateColumn;
      if (selectedReport) {
        templateArr = systemColumn.concat(originColumn);
      }
      console.log(templateArr);
      setSelectedColumn(data); //right table
      console.log(column);
      data.map((d) => {
        if (d.ChildColumn && column) {
          let childArr = column.find((e) => e.value === d.ColumnName).child; //original child arr
          let parentIndex = templateArr
            .map((p) => p.value)
            .indexOf(d.ColumnName);
          console.log(parentIndex);
          let newChildArr = childArr.filter(
            (e) =>
              d.ChildColumn.map((c) => c.ColumnName).indexOf(e.value) === -1
          );
          if (newChildArr.length === 0) {
            templateArr.splice(parentIndex, 1);
          } else {
            templateArr[parentIndex].child = newChildArr;
          }
          console.log(childArr);
        } else {
          if (templateArr.length > 0) {
            let arr = templateArr.map((col) => {
              if (col?.value ?? false) return col.value;
            });
            if (arr.indexOf(d.ColumnName) >= 0) {
              templateArr.splice(arr.indexOf(d.ColumnName), 1);
            }
          }
        }
      });
      setReportName(selectedReports.reportName);
      setReportTemplateName(selectedReports.reportTemplateName);
      setTemplateColumn((templateColumn) => [...templateArr]);
      setSelectedReport(selectedReports);
      SetUpdate(true);
      SetAllowDelete(true);
      setUnSelectedPage(0);
      setSelectedPage(0);
    } else {
      setSelectedReport(null);
      SetUpdate(false);
      SetAllowDelete(false);
      setReportName(null);
      setReportTemplateName(null);
      console.log(originColumn);
      if (originColumn && systemColumn) {
        setTemplateColumn((templateColumn) => [
          ...systemColumn.concat(
            originColumn.filter((item) => systemColumn.indexOf(item) < 0)
          ),
        ]);
      } else {
        setTemplateColumn(systemColumn);
      }
      setSelectedColumn([]);
    }
    setIsLoading(false);
  };
  const CaseTemplatesComboBox = (caseTemplates) => {
    if (caseTemplates) {
      test.push({
        firstLetter: "*",
        uuid: -1,
        categoryName: "*",
        templateName: "System",
      });
      caseTemplates.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return option.templateInfos.forEach((t) =>
          test.push({
            firstLetter: firstLetter,
            uuid: t.uuid,
            categoryName: option.name,
            templateName: t.name,
          })
        );
      });
      return (
        <Grid
          item
          container
          direction="column"
          style={{ textAlign: "left" }}
          spacing={1}
          md={"auto"}
        >
          <Grid item>
            <Typography variant="p">
              {t("administrative_console.report_page.select_case")}
            </Typography>
          </Grid>
          <Grid item>
            <Autocomplete
              id="CaseTemplates"
              disableClearable
              options={test.sort(
                (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
              )}
              isOptionEqualToValue={(option, value) =>
                option.templateName === value.templateName
              }
              groupBy={(test) => test.categoryName}
              getOptionLabel={(test) => test.templateName}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("administrative_console.report_page.case_template")}
                />
              )}
              onChange={(event, value) => handleTemplateOnChange(value)}
            />
          </Grid>
        </Grid>
      );
    }
  };
  const handleDialogOpen = (row, event, parentName) => {
    event.stopPropagation();
    // console.log(row.ColumnName.toLowerCase());
    console.log(parentName);
    console.log(row);
    setColumnName(row.ColumnName);
    setColumnLabel(row?.ColumnLabel ?? row.ColumnName);
    setDataType(row.DataType);
    if (parentName) {
      setParent(parentName);
    }
    if (
      (row.ColumnName === "decision" || row.ColumnName === "milestone") &&
      !row.Format
    ) {
      setFormat([]);
    } else {
      setFormat(row.Format);
    }
    SetCiteria(row.AllowCriteria);
    setDialog(true);

    if (row.ColumnName === "decision" || row.ColumnName === "milestone") {
      var url = urlJoin(resServerBaseUrl, "/Report/GetSystemColumnExtraData");
      var data = JSON.stringify({ CaseTemplateUUID: selectedCaseTpl });

      CallApiWithContext(url, authenticationContext, data)
        .then((response) => {
          setDecisionOption(response.Decision);
          setMilestoneOption(response.Milestone);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleDialogClose = () => {
    setDialog(false);
  };
  const handleApplyDialogClose = () => {
    setSaveDialog(false);
  };
  const handleDialogEdit = () => {
    const arr = selectedColumn;
    if (parent && selectedColumn) {
      let parentObj = selectedColumn.find((c) => c.ColumnName === parent);
      let parentObjIndex = selectedColumn
        .map((c) => c.ColumnName)
        .indexOf(parent);
      let childColArr = parentObj.ChildColumn;
      let childObj = childColArr.find((e) => e.ColumnName === columnName);
      let childIndex = childColArr
        .map((o) => o.ColumnName)
        .indexOf(childObj.ColumnName);
      childObj.ColumnLabel = columnLabel;
      childObj.DataType = selectedDataType;
      childObj.AllowCriteria = criteria;
      if (format) {
        childObj[i].Format = format;
      }
      childColArr.splice(childIndex, 1, childObj);
      parentObj.ChildColumn = childColArr;
      arr[parentObjIndex] = parentObj;
      setSelectedColumn((selectedColumn) => [...arr]);
    } else {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].ColumnName === columnName) {
          arr[i].ColumnLabel = columnLabel;
          arr[i].DataType = selectedDataType;
          arr[i].AllowCriteria = criteria;
          if (format) {
            arr[i].Format = format;
          }
          setSelectedColumn((selectedColumn) => [...arr]);
        }
      }
    }
    setDialog(false);
  };
  const handleDataTypeOnChange = (event) => {
    setDataType(
      dataType.find((type) => type.label === event.target.innerText).value
    );
  };
  const handleFormatOnChange = (event) => {
    setFormat(event.target.value);
  };
  const handleNameOnChange = (event) => {
    setReportName(event.target.value);
  };
  const RearrangeRow = (originalPosition, newPosition) => {
    // console.log(originalPosition);
    // console.log(newPosition);
    let arr = selectedColumn;

    arr.splice(newPosition, 0, arr.splice(originalPosition, 1)[0]);
    setSelectedColumn(() => [...arr]);
    console.log(arr);
  };
  const RearrangeChildRow = (originalPosition, newPosition, parentObj) => {
    let childArr = parentObj.ChildColumn;
    childArr.splice(newPosition, 0, childArr.splice(originalPosition, 1)[0]);
    if (
      !(
        (originalPosition === 0 && newPosition < 0) ||
        newPosition > childArr.length
      )
    ) {
    }
    parentObj.ChildColumn = childArr;
    let tempColArr = selectedColumn;
    tempColArr[
      tempColArr.map((c) => c.ColumnLabel).indexOf(parentObj.ColumnName)
    ] = parentObj;
    // arr.splice(newPosition, 0, arr.splice(originalPosition, 1)[0]);
    setSelectedColumn(() => [...tempColArr]);
    console.log(tempColArr);
  };

  const handleSave = () => {
    setSaveDialog(true);
  };
  const confirmSave = () => {
    let file = new FormData();
    if (reportTemplate) {
      file.append("File", reportTemplate.target.files[0]);
    }
    if (reportName) {
      file.append("ReportTplName", reportName);
    } else {
      setAlertDialog(true);
      setAlertContent(
        t("administrative_console.report_page.please_name_the_report_template")
      );
      setAlertTitle(t("profile.proxy_and_delegation.error"));
      return;
    }
    file.append("Data", JSON.stringify(selectedColumn));

    setIsLoading(true);
    setIsLoadingSaveButton(true);

    //if (reportTemplate != null) {
    if (!update || saveAs) {
      if (
        reports != null &&
        reports.filter((r) => r.reportName === reportName).length > 0
      ) {
        console.log("Duplicate Report Name");
        setAlertDialog(true);
        setIsLoading(false);
        setIsLoadingSaveButton(false);
        return;
      }
      file.append("CaseTemplateUUID", selectedCaseTpl);
      var url = urlJoin(resServerBaseUrl, "/Report/SaveReport");
      CallApiWithUploadFile(url, authenticationContext, file)
        .then((response) => {
          console.log(response);
          if (response) {
            setAlertDialog(true);
            setAlertContent(response.message);
            setAlertTitle(t("administrative_console.report_page.save"));
            setSaveDialog(false);
          }
          setIsLoading(false);
          setIsLoadingSaveButton(false);
        })
        .catch((error) => {
          console.log(error);
          setAlertDialog(true);
          setAlertContent(error);
          setAlertTitle(t("profile.proxy_and_delegation.error"));
          setIsLoading(false);
          setIsLoadingSaveButton(false);
        });
    } else {
      var url = urlJoin(resServerBaseUrl, "/Report/UpdateReport");
      if (selectedReport != null) {
        file.append("ReportTplSID", selectedReport.sid);
        CallApiWithUploadFile(url, authenticationContext, file)
          .then((response) => {
            console.log(response);
            if (response) {
              setAlertDialog(true);
              setAlertContent(response.message);
              setAlertTitle(t("administrative_console.report_page.save"));
              setSaveDialog(false);
            }
            setIsLoading(false);
            setIsLoadingSaveButton(false);
          })
          .catch((error) => {
            console.log(error);
            setAlertDialog(true);
            setAlertContent(error);
            setAlertTitle(t("profile.proxy_and_delegation.error"));
            setIsLoading(false);
            setIsLoadingSaveButton(false);
          });
      } else {
        setAlertDialog(true);
        setAlertContent(
          t(
            "administrative_console.report_page.No_such_existing_report_update_cannot_be_finish"
          )
        );
        setAlertTitle(t("profile.proxy_and_delegation.error"));
        setIsLoading(false);
        setIsLoadingSaveButton(false);
      }
    }
    // } else {
    //     //Now check if it has file first, no then alert him to upload
    //     alert("Not yet upload report template, please check!");
    //     setIsLoading(false);
    //     setIsLoadingSaveButton(false);
    // }
  };
  const SelectAllCheckBox = (props) => {
    const { onSelectAllClick, numSelected, rowCount } = props;
    return (
      <Checkbox
        color="primary"
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={onSelectAllClick}
        inputProps={{
          "aria-label": "select_all",
        }}
      />
    );
  };
  const handleSelectAllClick = (event, selectedTable) => {
    if (event.target.checked) {
      let newSelecteds = selectedTable ? templateColumn : selectedColumn;
      let parentCols = newSelecteds.filter((s) =>
        selectedTable ? s.child : s.ChildColumn
      );
      for (let c = 0; c < parentCols.length; c++) {
        let childColArr = selectedTable
          ? parentCols[c].child
          : parentCols[c].ChildColumn;
        if (selectedTable) {
          childColArr = childColArr.map((e) => {
            e["parent"] = parentCols[c].value;
            return e;
          });
        } else {
          childColArr = childColArr.map((e) => {
            e["Parent"] = parentCols[c].ColumnName;
            console.log(e);
            return e;
          });
        }
        newSelecteds = newSelecteds.concat(childColArr);
      }

      selectedTable ? setSelected(newSelecteds) : setUnSelected(newSelecteds);
      return;
    }
    selectedTable ? setSelected([]) : setUnSelected([]);
  };
  const isSelected = (obj, selectedTable) => {
    if (selectedTable) {
      return selected.indexOf(obj) !== -1;
    } else {
      return unSelected.indexOf(obj) !== -1;
    }
  };
  const handleChangeSelectedPage = (event, newPage) => {
    setSelectedPage(newPage);
  };
  const handleChangeSelectedRowsPerPage = (event) => {
    setSelectedRowsPerPage(parseInt(event.target.value, 10));
    setSelectedPage(0);
  };
  const handleChangeUnSelectedPage = (event, newPage) => {
    setUnSelectedPage(newPage);
  };
  const handleChangeUnSelectedRowsPerPage = (event) => {
    setUnSelectedRowsPerPage(parseInt(event.target.value, 10));
    setUnSelectedPage(0);
  };
  const arrEditor = (item, index, selectedTable) => {
    let newSelected = [];
    let arr = selectedTable ? selected : unSelected;
    if (index === -1) {
      //if item not exist
      arr.push(item);
      newSelected = arr;
    } else {
      arr.splice(index, 1);
      newSelected = arr;
    }
    return newSelected;
  };
  const handleLeftTableCheck = (event, obj) => {
    let selectedIndex = selected.indexOf(obj);
    let newSelected = [];
    //if child has parent
    if (obj.parent && templateColumn) {
      let parent = obj.parent;
      let parentObj = templateColumn.find((col) => col.value === parent);
      const parentIndex = selected.map((s) => s.value).indexOf(parent);

      if (parentIndex === -1) {
        newSelected = arrEditor(parentObj, parentIndex, true);
        newSelected = arrEditor(obj, selectedIndex, true);
      } else if (parentIndex >= 0) {
        //remove parent if all child is unchecked
        //find selected child
        newSelected = arrEditor(obj, selectedIndex, true);
        let selectedChild = newSelected.filter(
          (e) => e.parent && e.parent === parent
        );
        if (selectedChild.length === 0) {
          newSelected = arrEditor(parentObj, parentIndex, true);
        }
      }
    } else {
      newSelected = arrEditor(obj, selectedIndex, true);
    }

    setSelected((selected) => [...newSelected]);
  };
  const handleRightTableCheck = (event, obj) => {
    let objIndex = unSelected.indexOf(obj);
    let newSelected = [];
    console.log(objIndex);

    if (obj.Parent && selectedColumn) {
      let parent = obj.Parent;

      let parentObj = selectedColumn.find((col) => col.ColumnName === parent);
      const parentIndex = unSelected.map((s) => s.ColumnName).indexOf(parent);
      console.log(parentIndex);
      console.log(unSelected);

      if (parentIndex === -1) {
        newSelected = arrEditor(parentObj, parentIndex, false);
        newSelected = arrEditor(obj, objIndex, false);
      } else if (parentIndex >= 0) {
        //remove parent if all child is unchecked
        //find selected child
        newSelected = arrEditor(obj, objIndex, false);
        let selectedChild = newSelected.filter(
          (e) => e.Parent && e.Parent === parent
        );
        console.log(selectedChild);

        if (selectedChild.length === 0) {
          newSelected = arrEditor(parentObj, parentIndex, false);
        }
      }
    } else {
      newSelected = arrEditor(obj, objIndex, false);
      console.log(newSelected);
    }

    setUnSelected((unSelected) => [...newSelected]);
  };
  const handleLeftParentChecked = (event, obj) => {
    let parentIndex = selected.map((s) => s.value).indexOf(obj.value);
    let newSelectedArr = [];
    if (parentIndex === -1) {
      const childArr = obj.child;

      const alteredChildArr = childArr.filter((v) => {
        if (
          !selected.find(
            (s) => s.parent === obj.value && s.value === v.value
          ) &&
          v.parent === obj.value
        ) {
          return v;
        }
      });

      newSelectedArr = arrEditor(obj, parentIndex, true);
      if (childArr?.length ?? 0 > 0) {
        let a = [];
        alteredChildArr.map((o) => {
          const childIndex = -1;
          if (o.parent === obj.value) {
            a = arrEditor(o, childIndex, true);
          }
        });
        newSelectedArr.concat(a);
      }
    } else {
      newSelectedArr = selected;
      let isAllChildSelected = false;
      if (obj?.child?.length ?? 0 > 0) {
        let alteredChildArr = obj?.child?.filter((v) => {
          if (
            !selected.find(
              (s) => s.parent === obj.value && s.value === v.value
            ) &&
            v.parent === obj.value
          ) {
            return v;
          }
        });
        if (alteredChildArr.length === 0) {
          isAllChildSelected = true;
          alteredChildArr = obj?.child?.filter((v) => {
            if (v.parent === obj.value) {
              return v;
            }
          });
        }

        let a = [];
        console.log(selected);
        alteredChildArr.map((childObj) => {
          const childIndex = isAllChildSelected ? 0 : -1;
          if (childObj.parent === obj.value) {
            a = arrEditor(childObj, childIndex, true);
          }
        });
        console.log(a);

        newSelectedArr = a;
        console.log(newSelectedArr);

        //checked child length is same as those selected
        //remove parent
        if (
          Object.keys(obj.child).length > 0 &&
          alteredChildArr.length === Object.keys(obj.child).length
        ) {
          parentIndex = newSelectedArr.indexOf(obj);
          newSelectedArr.splice(parentIndex, 1);
        }
      }
    }
    setSelected((seletced) => [...newSelectedArr]);
  };
  const handleRightParentChecked = (event, obj) => {
    let parentIndex = unSelected
      .map((s) => s.ColumnName)
      .indexOf(obj.ColumnName);
    let newSelectedArr = [];
    if (parentIndex === -1) {
      newSelectedArr = arrEditor(obj, parentIndex, false);
      if (obj?.ChildColumn?.length ?? 0 > 0) {
        const alteredChildArr = obj.ChildColumn.filter((v) => {
          if (
            !unSelected.find(
              (s) =>
                s.Parent === obj.ColumnName && s.ColumnName === v.ColumnName
            )
          ) {
            return v;
          }
        });
        if (obj?.ChildColumn?.length ?? 0 > 0) {
          let a = [];
          alteredChildArr.map((o) => {
            const childIndex = -1;
            a = arrEditor(o, childIndex, false);
          });
          newSelectedArr.concat(a);
        }
      }
    } else {
      newSelectedArr = unSelected;
      let isAllChildSelected = false;
      if (obj?.ChildColumn?.length ?? 0 > 0) {
        let alteredChildArr = obj.ChildColumn.filter((v) => {
          if (
            !unSelected.find(
              (s) =>
                s.Parent === obj.ColumnName && s.ColumnName === v.ColumnName
            )
          ) {
            return v;
          }
        });
        if (alteredChildArr.length === 0) {
          isAllChildSelected = true;
          alteredChildArr = obj?.ChildColumn?.filter((v) => {
            if (
              unSelected.find(
                (s) =>
                  s.Parent === obj.ColumnName && s.ColumnName === v.ColumnName
              )
            ) {
              return v;
            }
          });
        }
        let a = [];
        alteredChildArr.map((childObj) => {
          const childIndex = isAllChildSelected ? 0 : -1;
          if (childObj.Parent === obj.ColumnName) {
            a = arrEditor(childObj, childIndex, false);
          }
        });
        newSelectedArr = a;

        //full child length is same as those selected
        //remove parent
        if (
          Object.keys(obj.ChildColumn).length > 0 &&
          alteredChildArr.length === Object.keys(obj.ChildColumn).length
        ) {
          parentIndex = newSelectedArr.indexOf(obj);
          newSelectedArr.splice(parentIndex, 1);
        }
      }
    }
    setUnSelected((unSeletced) => [...newSelectedArr]);
  };
  const handleSelect = () => {
    if (selected) {
      var selectedArr = selectedColumn;
      var templateArr = templateColumn;
      if (
        selected.some((s) => s.child) &&
        selectedColumn
          .map((u) => u.ColumnName)
          .indexOf(systemColumn[1].value) === -1
      ) {
        selected.splice(0, 0, systemColumn[1]);
      }
      selected.forEach((ele) => {
        if (Object.keys(ele).includes("parent")) {
          const parentName = ele.parent;
          let parentObj = selectedArr.find(
            (str) => str.ColumnName === parentName
          );
          let leftTableParentObj = originColumn.find(
            (a) => a.value === parentName
          );
          if (parentObj) {
            let childCol = parentObj.ChildColumn;
            childCol.push({
              ColumnName: ele.value,
              ColumnLabel: ele.label,
              DataType: dataType[0].value,
              Format: format,
              SystemColumn:
                systemColumn.map((col) => col.value).includes(ele) &&
                ele.toLowerCase() != "decision",
              AllowCriteria: false,
              Parent: ele.parent,
            });
            parentObj.ChildColumn = childCol;
          }
          //check is all child selected
          if (leftTableParentObj.child.length === 1) {
            templateArr = templateArr.filter(
              (element) => element.value !== parentName
            );
          }

          leftTableParentObj.child = leftTableParentObj.child.filter(
            (o) => o.value !== ele.value
          );
          const parentIndex = templateArr
            .map((a) => a.label)
            .indexOf(parentName);
          templateArr[parentIndex] = leftTableParentObj;
        } else {
          if (
            selectedColumn.map((s) => s.ColumnName).indexOf(ele.value) == -1
          ) {
            let obj = ele.child
              ? {
                  ColumnName: ele.value,
                  ColumnLabel: ele.label,
                  DataType: dataType[4].value,
                  Format: format,
                  SystemColumn: systemColumn
                    .map((col) => col.value)
                    .includes(ele.value),
                  AllowCriteria: false,
                  ChildColumn: [],
                }
              : {
                  ColumnName: ele.value,
                  ColumnLabel: ele.label,
                  DataType: ele.type ? ele.type.value : dataType[0].value,
                  Format: format,
                  SystemColumn: systemColumn
                    .map((col) => col.value)
                    .includes(ele.value),
                  AllowCriteria: false,
                };
            selectedArr.push(obj);
            //filter element which in right table out
            if (!ele?.child ?? false) {
              templateArr = templateArr.filter(
                (element) => element.value !== ele.value
              );
            }
          }
        }
      });
      console.log(selectedArr);
      setTemplateColumn((templateColumn) => [...templateArr]);
      setSelectedColumn(selectedArr);
      setSelected([]);
    }
  };
  const handleUnSelect = () => {
    console.log(selected);

    if (unSelected) {
      var rightTable = selectedColumn;
      var leftTable = templateColumn;
      unSelected.forEach((ele) => {
        console.log(ele);
        if (Object.keys(ele).includes("Parent")) {
          const parentName = ele.Parent;
          let leftTableParentObj = leftTable.find(
            (str) => str.value === parentName
          );
          let rightTableParentObj = rightTable.find(
            (a) => a.ColumnName === parentName
          );
          if (leftTableParentObj) {
            let childCol = leftTableParentObj.child;
            childCol.push({
              label: ele.ColumnLabel,
              value: ele.ColumnName,
            });
            leftTableParentObj.child = childCol;
          }
          //check is all child selected
          if (rightTableParentObj) {
            rightTableParentObj.ChildColumn =
              rightTableParentObj.ChildColumn.filter(
                (o) => o.ColumnName !== ele.ColumnName
              );
            if (rightTableParentObj.ChildColumn.length === 0) {
              rightTable = rightTable.filter(
                (element) => element.ColumnName !== parentName
              );
            }
            const parentIndex = rightTable
              .map((a) => a.label)
              .indexOf(parentName);
            rightTable[parentIndex] = rightTableParentObj;
          }
        } else {
          if (leftTable.map((s) => s.value).indexOf(ele.ColumnName) == -1) {
            let colObj = ele.SystemColumn
              ? systemColumn.find((c) => c.value === ele.ColumnName)
              : column.find((c) => c.value === ele.ColumnName);
            if (colObj) {
              let newObj =
                colObj?.child ?? false
                  ? {
                      label: colObj.label,
                      value: colObj.value,
                      child: colObj.child,
                    }
                  : {
                      label: colObj.label,
                      value: colObj.value,
                    };
              leftTable.push(newObj);
              if (!ele.ChildColumn) {
                rightTable = rightTable.filter(function (element) {
                  return element.ColumnName !== ele.ColumnName;
                });
              }
            }
          }
        }
      });
      setTemplateColumn((templateColumn) => [...leftTable]);
      setSelectedColumn((selectedColumn) => [...rightTable]);
      setUnSelected([]);
    }
  };
  const handleSaveAs = (event) => {
    SetUpdate(!event.target.checked);
    SetSaveAs(event.target.checked);
  };
  const handleCriteria = (event) => {
    SetCiteria(event.target.checked);
  };
  const handleDelete = () => {
    var url = urlJoin(resServerBaseUrl, "/Report/DeleteReport");
    const data = JSON.stringify({ TplID: selectedReport.id });
    CallApiWithContext(url, authenticationContext, data)
      .then((response) => {
        console.log(response);
        if (response) {
          setAlertDialog(true);
          setAlertContent(response.message);
          setAlertTitle("");
          var arr = reports;
          arr.splice(reports.indexOf(selectedReport), 1);
          setReports((reports) => [...arr]);
          handleReportTemplateOnChange(null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setDeleteDialog(false);
  };
  const handleDeleteDialogOpen = () => {
    setDeleteDialog(true);
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
  };
  const handleAlertDialogClose = () => {
    setAlertDialog(false);
  };
  const handleDecisionChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormat(value);
  };
  const handleMilestoneChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormat(value);
  };
  const handleColumnLabelOnChange = (event) => {
    setColumnLabel(event.target.value);
  };
  const GenerateLeftTableRow = (col, index) => {
    const labelId = `enhanced-table-checkbox-${index}`;
    let isItemSelected = isSelected(col, true);
    if (col.child) {
      return (
        <>
          <TableRow
            hover
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={col.value}
            selected={isItemSelected}
          >
            <TableCell
              sx={{ width: "10%" }}
              onClick={(event) => handleLeftParentChecked(event, col, true)}
            >
              <Checkbox
                color="primary"
                checked={isItemSelected}
                inputProps={{
                  "aria-labelledby": labelId,
                }}
              />
            </TableCell>
            <TableCell
              sx={{ width: "10%" }}
              onClick={(event) => handleLeftParentChecked(event, col, true)}
            >
              {col.label}
            </TableCell>
            <TableCell align="left">
              {Object.keys(parentColumnOpen).includes(col.value) ? (
                parentColumnOpen[col.value] ? (
                  <IconButton
                    onClick={() =>
                      setParentColumnOpen({
                        ...parentColumnOpen,
                        [col.value]: false,
                      })
                    }
                  >
                    <KeyboardArrowDown />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() =>
                      setParentColumnOpen({
                        ...parentColumnOpen,
                        [col.value]: true,
                      })
                    }
                  >
                    <KeyboardArrowUp />
                  </IconButton>
                )
              ) : null}
            </TableCell>
          </TableRow>

          {col.child.map((r, i) => {
            r["parent"] = col.value;
            return GenerateLeftTableRow(r, i);
          })}
        </>
      );
    } else if (col.parent) {
      if (Object.keys(parentColumnOpen).includes(col.parent)) {
        return (
          <TableRow
            hover
            onClick={(event) => handleLeftTableCheck(event, col)}
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={col.value}
            selected={isItemSelected}
          >
            {parentColumnOpen[col.parent] ? (
              <>
                <TableCell />
                <TableCell sx={{ width: "10%" }}>
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </TableCell>
                <TableCell>{col.label}</TableCell>
              </>
            ) : null}
          </TableRow>
        );
      }
    } else {
      return (
        <TableRow
          hover
          onClick={(event) => handleLeftTableCheck(event, col)}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={col.value}
          selected={isItemSelected}
        >
          <TableCell sx={{ width: "10%" }}>
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                "aria-labelledby": labelId,
              }}
            />
          </TableCell>
          <TableCell>{col.label}</TableCell>
          <TableCell />
        </TableRow>
      );
    }
  };
  const GenerateRightTableRow = (col, index, parent) => {
    const labelId = `enhanced-table-checkbox-${index}`;
    const isItemSelected = isSelected(col, false);
    const rightTableCellClickAction = (e, c) => {
      if (
        !(
          col.ColumnName === systemColumn[1].value &&
          selectedColumn.some((col) => col.ChildColumn)
        )
      ) {
        handleRightTableCheck(e, c);
      }
    };
    if (col?.ChildColumn?.length ?? 0 > 0) {
      return (
        <>
          <TableRow
            key={col.ColumnName}
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            hover
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
          >
            <TableCell sx={{ width: "10%" }}>
              <Checkbox
                color="primary"
                checked={isItemSelected}
                inputProps={{
                  "aria-labelledby": labelId,
                }}
                onClick={(event) => handleRightParentChecked(event, col)}
              />
            </TableCell>
            <TableCell
              onClick={(event) => handleRightParentChecked(event, col)}
              sx={{ width: "10%" }}
            >
              {index + 1 + unSelectedPage * unSelectedRowsPerPage}
            </TableCell>
            <TableCell
              onClick={(event) => handleRightParentChecked(event, col)}
            >
              {col?.ColumnLabel ?? col.ColumnName}
            </TableCell>
            <TableCell>
              {Object.keys(parentColumnOpen).includes(col.ColumnName) ? (
                parentColumnOpen[col.ColumnName] ? (
                  <IconButton
                    onClick={() =>
                      setParentColumnOpen({
                        ...parentColumnOpen,
                        [col.ColumnName]: false,
                      })
                    }
                  >
                    <KeyboardArrowDown />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() =>
                      setParentColumnOpen({
                        ...parentColumnOpen,
                        [col.ColumnName]: true,
                      })
                    }
                  >
                    <KeyboardArrowUp />
                  </IconButton>
                )
              ) : null}
            </TableCell>
            <TableCell align="right">
              <Grid container direction={"row"} spacing={2}>
                <Grid item>
                  <IconButton
                    id={index}
                    onClick={
                      () =>
                        RearrangeRow(
                          unSelectedPage * unSelectedRowsPerPage + index,
                          unSelectedPage * unSelectedRowsPerPage + index - 1
                        )
                      // handleRowAsc(event)
                    }
                    // startIcon={<ArrowUpwardIcon />}
                    disabled={
                      index + 1 + unSelectedPage * unSelectedRowsPerPage === 1
                    }
                    color="primary"
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    id={index}
                    onClick={
                      () =>
                        RearrangeRow(
                          unSelectedPage * unSelectedRowsPerPage + index,
                          unSelectedPage * unSelectedRowsPerPage + index + 1
                        )
                      // handleRowDesc(event)
                    }
                    // startIcon={<ArrowDownwardIcon />}
                    disabled={
                      index + 1 + unSelectedPage * unSelectedRowsPerPage ===
                      selectedColumn.length
                      // selectedColumn.length <= 1 ||
                      // selectedColumn.length ===
                      //   currentRowIndex + 1
                    }
                    color="primary"
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    id={index}
                    onClick={(event) => handleDialogOpen(col, event, null)}
                    // startIcon={<EditIcon />}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>

          {col.ChildColumn.map((c, i) => GenerateRightTableRow(c, i, col))}
        </>
      );
    } else if (parent) {
      if (Object.keys(parentColumnOpen).includes(col.Parent)) {
        return (
          <TableRow
            key={col.ColumnName}
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
          >
            {parentColumnOpen[col.Parent] ? (
              <>
                <TableCell sx={{ width: "10%" }}>
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                    onClick={(event) => handleRightTableCheck(event, col)}
                  />
                </TableCell>
                <TableCell />

                <TableCell
                  onClick={(event) => handleRightTableCheck(event, col)}
                >
                  {col?.ColumnLabel ?? col.ColumnName}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  <Grid container direction={"row"} spacing={2}>
                    <Grid item>
                      <IconButton
                        id={index}
                        onClick={() =>
                          RearrangeChildRow(index, index - 1, parent)
                        }
                        // startIcon={<ArrowUpwardIcon />}
                        disabled={
                          parent
                            ? index - 1 < 0
                            : index +
                                1 +
                                unSelectedPage * unSelectedRowsPerPage ===
                              1
                        }
                        color="primary"
                      >
                        <ArrowUpwardIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        id={index}
                        onClick={() =>
                          RearrangeChildRow(index, index + 1, parent)
                        }
                        // startIcon={<ArrowDownwardIcon />}
                        disabled={
                          parent
                            ? index + 1 === parent.ChildColumn.length
                            : index +
                                1 +
                                unSelectedPage * unSelectedRowsPerPage ===
                              selectedColumn.length
                          // selectedColumn.length <= 1 ||
                          // selectedColumn.length ===
                          //   currentRowIndex + 1
                        }
                        color="primary"
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        id={index}
                        onClick={(event) =>
                          handleDialogOpen(col, event, parent.ColumnName)
                        }
                        // startIcon={<EditIcon />}
                        disabled={!parent.AllowCriteria}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </TableCell>
              </>
            ) : null}
          </TableRow>
        );
      }
    } else {
      return (
        <TableRow
          key={col.ColumnName}
          sx={{
            "&:last-child td, &:last-child th": {
              border: 0,
            },
          }}
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          selected={isItemSelected}
        >
          <TableCell sx={{ width: "10%" }}>
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                "aria-labelledby": labelId,
              }}
              disabled={
                col.ColumnName === systemColumn[1].value &&
                selectedColumn.some((col) => col.ChildColumn)
              }
              onClick={(event) => rightTableCellClickAction(event, col)}
            />
          </TableCell>
          <TableCell
            aria-disabled={
              col.ColumnName === systemColumn[1].value &&
              selectedColumn.some((col) => col.ChildColumn)
            }
            onClick={(event) => rightTableCellClickAction(event, col)}
          >
            {index + 1 + unSelectedPage * unSelectedRowsPerPage}
          </TableCell>
          <TableCell
            aria-disabled={
              col.ColumnName === systemColumn[1].value &&
              selectedColumn.some((col) => col.ChildColumn)
            }
            onClick={(event) => rightTableCellClickAction(event, col)}
          >
            {col?.ColumnLabel ?? col.ColumnName}
          </TableCell>
          <TableCell />
          <TableCell align="right">
            <Grid container direction={"row"} spacing={2}>
              <Grid item>
                <IconButton
                  id={index}
                  onClick={
                    () =>
                      parent
                        ? RearrangeChildRow(index, index - 1, parent)
                        : RearrangeRow(
                            unSelectedPage * unSelectedRowsPerPage + index,
                            unSelectedPage * unSelectedRowsPerPage + index - 1
                          )
                    // handleRowAsc(event)
                  }
                  // startIcon={<ArrowUpwardIcon />}
                  disabled={
                    parent
                      ? index - 1 < 0
                      : index + 1 + unSelectedPage * unSelectedRowsPerPage === 1
                  }
                  color="primary"
                >
                  <ArrowUpwardIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  id={index}
                  onClick={
                    () =>
                      parent
                        ? RearrangeChildRow(index, index + 1, parent)
                        : RearrangeRow(
                            unSelectedPage * unSelectedRowsPerPage + index,
                            unSelectedPage * unSelectedRowsPerPage + index + 1
                          )
                    // handleRowDesc(event)
                  }
                  // startIcon={<ArrowDownwardIcon />}
                  disabled={
                    parent
                      ? index + 1 === parent.ChildColumn.length
                      : index + 1 + unSelectedPage * unSelectedRowsPerPage ===
                        selectedColumn.length
                    // selectedColumn.length <= 1 ||
                    // selectedColumn.length ===
                    //   currentRowIndex + 1
                  }
                  color="primary"
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  id={index}
                  onClick={(event) =>
                    handleDialogOpen(
                      col,
                      event,
                      parent ? parent.ColumnName : null
                    )
                  }
                  // startIcon={<EditIcon />}
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      );
    }
  };
  const rowCountCal = (colArr, selectedCount, leftRight) => {
    if (colArr !== null) {
      let childs = leftRight
        ? colArr.filter((c) => c.child).map((c) => c.child.length)
        : colArr.filter((c) => c.ChildColumn).map((c) => c.ChildColumn.length);
      let childLength =
        childs.length > 0 ? childs.reduce((prevC, curC) => prevC + curC) : 0;
      return colArr.length + childLength;
    }
  };

  return (
    <Grid
      container
      id="ReportSettingGrid"
      direction={"column"}
      p={1}
      justifyContent={"center"}
    >
      <Grid item container p={2}>
        <Grid item container direction="row">
          <TitleWithIcon
            icon={<AssessmentIcon />}
            title={{
              content: t("administrative_console.report_page.title"),
              variant: "h6",
              alignItems: "center",
              fontWeight: "bold",
            }}
          />
        </Grid>
        <Grid item container>
          <Typography variant="p" color={"gray"} fontSize={"14px"}>
            {t("administrative_console.report_page.description")}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems={"center"}
        direction={isSmUp ? "row" : "column"}
        style={{ display: "flex", flexWrap: "nonwrap" }}
        textAlign={"center"}
        spacing={2}
        p={2}
      >
        {/* Case Templates Combo Box */}
        {
          caseTemplates ? CaseTemplatesComboBox(caseTemplates) : null
          // <Grid container direction="column" style={{ textAlign: 'left', width: 'unset' }}>
          //     &nbsp;
          //     <Grid width={300}><Skeleton width= '100%'></Skeleton></Grid>
          //     <Grid width={300} ><Skeleton width= '100%' height='100px'></Skeleton></Grid>
          //     </Grid>
        }
        {/* Report Templates Combo Box */}
        {/* <Grid container xs="0" sm="1" md="0" lg="1"></Grid> */}
        {reports ? (
          <Grid
            item
            container
            style={{ textAlign: "left" }}
            spacing={1}
            alignItems={"center"}
            xs={"auto"}
          >
            <Grid item container direction="column" spacing={1} xs={"auto"}>
              <Grid item>
                <Typography variant="p">
                  {t("administrative_console.report_page.select_report_below")}
                </Typography>
              </Grid>
              <Grid item>
                <Autocomplete
                  id="CaseTemplates"
                  disabled={!(reports && reports.length > 0) && !templateColumn}
                  options={reports.map((r) => r.reportName)}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("administrative_console.report_page.report")}
                    />
                  )}
                  onChange={(event, value) =>
                    handleReportTemplateOnChange(value)
                  }
                />
              </Grid>
            </Grid>
            {/* Delete Button */}
            {allowDelete ? (
              <Grid item>
                <Grid item>
                  <Typography variant="p">
                    {t("administrative_console.report_page.mappingTitle")}
                  </Typography>
                </Grid>

                <Grid item style={{ textAlign: "left" }}>
                  <IconButton
                    id="Delete"
                    // style={{ width: deleteIconButtonWidth }}
                    onClick={handleDeleteDialogOpen}
                    // startIcon={<DeleteIcon id="DeleteIcon" />}
                    disabled={!allowDelete}
                    color={"primary"}
                  >
                    <DeleteIcon />
                  </IconButton>

                  {reportTemplateName ? (
                    <Typography
                      variant="p"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignContent: "center",
                        color: "gray",
                        fontSize: "14px",
                      }}
                    >
                      {reportTemplateName}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        ) : null}
        {/* Select mapping template */}
      </Grid>

      {selectedCaseTpl !== null ? (
        <Grid item container id="ReportSettingContentGridContainer" spacing={2}>
          <Grid
            item
            container
            sx={{
              marginTop: "20px",
              //width: tableMaxWidth,
              justifyContent: "center",
            }}
            spacing={2}
          >
            {/* Left Table (Template Column) */}
            <Grid
              item
              xs={12}
              sm={5}
              /*style={{ maxWidth: "100%" }}*/
            >
              <Grid item textAlign={"start"}>
                <Typography variant="p">
                  {t(
                    "administrative_console.report_page.selectable_report_attribute"
                  )}
                </Typography>
              </Grid>
              <Grid item container display={"flex"}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead sx={{ fontWeight: "600" }}>
                      <TableRow>
                        <TableCell sx={{ width: "10%" }}>
                          <SelectAllCheckBox
                            numSelected={selected ? selected.length : 0}
                            onSelectAllClick={(event) => {
                              handleSelectAllClick(event, true);
                            }}
                            rowCount={
                              rowCountCal(
                                templateColumn,
                                selected?.length ?? 0,
                                true
                              )
                              // templateColumn.length +
                              // templateColumn.map((c) => c.child)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          {t("administrative_console.report_page.column_name")}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {templateColumn
                        .slice(
                          selectedPage * selectedRowsPerPage,
                          selectedPage * selectedRowsPerPage +
                            selectedRowsPerPage
                        )
                        .map((row, rowIndex) =>
                          GenerateLeftTableRow(row, rowIndex)
                        )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    id="LeftTablePagination"
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    labelRowsPerPage={t("inbox_outbox.display_row")}
                    count={templateColumn.length}
                    rowsPerPage={selectedRowsPerPage}
                    page={selectedPage}
                    //sx={{ position: 'relative', bottom: '10px', right: 0, overflow: 'hidden' }}
                    onPageChange={handleChangeSelectedPage}
                    onRowsPerPageChange={handleChangeSelectedRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableContainer>
              </Grid>
            </Grid>

            {/* Middle Action Buttons */}
            <Grid
              item
              container={isSmUp ? false : true}
              id="MiddleActionButtonsGridContainer"
              sx={{
                justifyContent: "center",
                display: "flex",
                flexDirection: isMdUp ? "column" : isSmUp ? "column" : "row",
                //padding: "20px 0 20px",
              }}
              xs={12}
              sm={"auto"}
            >
              <Grid item>
                <IconButton
                  id={0}
                  onClick={(event) => handleSelect(event)}
                  // startIcon={
                  //   isSmUp ? <ArrowForwardIcon /> : <ArrowDownwardIcon />
                  // }
                  disabled={!templateColumn || templateColumn.length === 0}
                  color="primary"
                >
                  {isSmUp ? <ArrowForwardIcon /> : <ArrowDownwardIcon />}
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  id={1}
                  onClick={(event) => handleUnSelect(event)}
                  // startIcon={isSmUp ? <ArrowBackIcon /> : <ArrowUpwardIcon />}
                  disabled={!selectedColumn || selectedColumn.length === 0}
                  color="primary"
                >
                  {isSmUp ? <ArrowBackIcon /> : <ArrowUpwardIcon />}
                </IconButton>
              </Grid>
            </Grid>

            {/* Right Table (Selected Column) */}
            <Grid item id="RightTableGridContainer" xs={12} sm={6}>
              <Grid
                item
                textAlign={"start"}
                // style={{
                //   display: "flex",
                //   flexWrap: "wrap",
                //   justifyContent: "left",
                // }}
              >
                <Typography variant="p">
                  {t(
                    "administrative_console.report_page.selected_report_attribute"
                  )}
                </Typography>
              </Grid>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead sx={{ fontWeight: "600" }}>
                    <TableRow>
                      <TableCell sx={{ width: "10%" }}>
                        <SelectAllCheckBox
                          numSelected={unSelected ? unSelected.length : 0}
                          onSelectAllClick={(event) => {
                            handleSelectAllClick(event, false);
                          }}
                          rowCount={rowCountCal(
                            selectedColumn,
                            unSelected?.length ?? 0,
                            false
                          )}
                        />
                      </TableCell>
                      <TableCell sx={{ width: "10%" }}>
                        {t("administrative_console.report_page.order_num")}
                      </TableCell>
                      <TableCell>
                        {t("administrative_console.report_page.column_name")}
                      </TableCell>
                      <TableCell />
                      <TableCell>
                        {t("administrative_console.report_page.action")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedColumn !== null
                      ? selectedColumn
                          .slice(
                            unSelectedPage * unSelectedRowsPerPage,
                            unSelectedPage * unSelectedRowsPerPage +
                              unSelectedRowsPerPage
                          )
                          .map((row, rowIndex) => {
                            return GenerateRightTableRow(row, rowIndex, null);
                          })
                      : null}
                  </TableBody>
                </Table>
                <TablePagination
                  id="RightTablePagination"
                  //sx={{ position: 'relative', bottom: '10px', right: 0, overflow: 'hidden' }}
                  style={{
                    display: "flex",
                    placeContent: "center",
                  }}
                  rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                  labelRowsPerPage={t("inbox_outbox.display_row")}
                  count={selectedColumn ? selectedColumn.length : 0}
                  rowsPerPage={unSelectedRowsPerPage}
                  page={unSelectedPage}
                  onPageChange={handleChangeUnSelectedPage}
                  onRowsPerPageChange={handleChangeUnSelectedRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableContainer>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            // style={{ padding: "1.5%" }}
          >
            <Button
              type="btn1"
              variant="contained"
              fullWidth
              onClick={() => {
                handleSave();
              }}
              disabled={selectedColumn ? selectedColumn.length < 1 : true}
            >
              {t("administrative_console.report_page.save")}
            </Button>
          </Grid>
        </Grid>
      ) : null}
      {/* Edit Column Dialog */}
      <Dialog open={dialog} onClose={handleDialogClose} fullScreen={!isSmUp}>
        <DialogTitle>
          {t("administrative_console.report_page.dialog_title")}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2} pt={1}>
            <Grid item>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label={t("administrative_console.report_page.dialog_report")}
                defaultValue={columnName}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label={t(
                  "administrative_console.report_page.dialog_column_label"
                )}
                value={columnLabel}
                onChange={(e) => {
                  handleColumnLabelOnChange(e);
                }}
                fullWidth
              />
            </Grid>
            {systemColumn.find((col) => col.value === columnName) ? (
              <></>
            ) : (
              <Grid item>
                <Autocomplete
                  loadingText={"Loading..."}
                  id="combo-box-demo"
                  defaultValue={
                    selectedDataType
                      ? dataType.find((type) => type.value === selectedDataType)
                      : dataType[0].value
                  }
                  disableClearable
                  onChange={handleDataTypeOnChange}
                  options={dataType.filter(
                    (d) => d.value != "DataGroup" && d.value != "DynamicList"
                  )}
                  disabled={selectedDataType === dataType[4].value}
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t(
                        "administrative_console.report_page.dialog_data"
                      )}
                    />
                  )}
                  fullWidth
                />
              </Grid>
            )}

            {selectedDataType === "Date" ? (
              <Grid container item direction={"column"}>
                <Grid item xs={12} sm={4}>
                  <p style={{ paddingTop: "15px", color: "gray" }}>
                    {t("administrative_console.report_page.dialog_format")}
                  </p>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: 300 }}
                    defaultValue={format ? format : null}
                    onChange={handleFormatOnChange}
                  />
                </Grid>
              </Grid>
            ) : null}

            {columnName !== "DataGroup" ? (
              <Grid container item direction={"column"}>
                <Grid item xs={12} sm={8} sx={{ paddingTop: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox onChange={handleCriteria} checked={criteria} />
                    }
                    label={t("administrative_console.report_page.criteria")}
                  />
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={false}
            onClick={(event) => handleDialogEdit(event)}
          >
            {t("administrative_console.report_page.dialog_edit")}
          </LoadingButton>
          <Button onClick={handleDialogClose} color="error">
            {t("administrative_console.report_page.dialog_cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Save Template Dialog */}
      <Dialog
        open={applyDialog}
        onClose={handleApplyDialogClose}
        fullScreen={!isSmUp}
      >
        <DialogTitle>
          {t("administrative_console.report_page.confirm_save")}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid
              item
              xs={12}
              // sx={{
              //   xs: { justifyContent: "left", alignContent: "left" },
              //   sm: { justifyContent: "center", alignContent: "center" },
              // }}
            >
              <Typography color={"gray"} variant="p">
                {update && !saveAs
                  ? t("administrative_console.report_page.rename")
                  : t("administrative_console.report_page.set_name")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                defaultValue={
                  update
                    ? selectedReport != null
                      ? selectedReport.reportName
                      : null
                    : null
                }
                variant="outlined"
                sx={{ width: 300 }}
                onChange={handleNameOnChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p" color={"gray"}>
                {reportTemplateName
                  ? t("administrative_console.report_page.overwrite")
                  : t("administrative_console.report_page.upload")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Input
                type="file"
                id="avatar"
                name="avatar"
                onChange={(input) => setReportTemplate(input)}
                inputProps={{
                  accept:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={!reportName}
                    onChange={handleSaveAs}
                  />
                }
                label={t("administrative_console.report_page.save_as")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isLoadingSaveButton}
            onClick={(event) => confirmSave(event)}
            variant="contained"
          >
            {t("administrative_console.report_page.save")}
          </LoadingButton>
          <Button onClick={handleApplyDialogClose} color="error">
            {t("administrative_console.report_page.dialog_cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Confirm Delete Dialog */}
      <SharedDialog
        isOpen={deleteDialog}
        onClose={handleDeleteDialogClose}
        title={t("administrative_console.report_page.confirm_delete")}
        content={t("administrative_console.report_page.are_you_sure")}
        buttons={[
          {
            text: t("administrative_console.report_page.confirm"),
            action: handleDelete,
            variant: "contained",
          },
          {
            text: t("administrative_console.report_page.cancel"),
            action: handleDeleteDialogClose,
            variant: "outlined",
            color: "error",
          },
        ]}
      ></SharedDialog>
      {/* Confirm Error Dialog */}
      <SharedDialog
        isOpen={alertDialog}
        onClose={handleAlertDialogClose}
        title={alertTitle}
        content={alertContent}
        buttons={[
          {
            text: t("report.exist_dialog.close"),
            action: handleAlertDialogClose,
          },
        ]}
      ></SharedDialog>
      <LoadingSpinner isOpen={isLoading}></LoadingSpinner>
    </Grid>
  );
}
