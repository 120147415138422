import React from "react";
import { Backdrop, Box, CircularProgress, useTheme } from "@mui/material";
import "./LoadingSpinner.css";
import { DRAWER_WIDTH } from "../Constants";
import { useAuthentication } from "../providers/AuthenticationProvider";
import PropTypes from "prop-types";
import { useUISettings } from "../providers/UISettingsProvider";

export default function LoadingSpinner(props) {
  const { isOpen } = props;
  const [isAuthenticated] = useAuthentication();
  const theme = useTheme();
  const [uiSettings] = useUISettings();

  return (
    <Box>
      <Backdrop
        sx={
          isAuthenticated
            ? {
                //position: 'absolute',
                left: { lg: uiSettings.drawerOpen ? DRAWER_WIDTH : 0 },
                zIndex: theme.zIndex.drawer + 10,
              }
            : null
        }
        open={isOpen}
      >
        {isOpen && <CircularProgress />}
      </Backdrop>
    </Box>
  );
}

LoadingSpinner.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
