import * as React from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Select,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  Tooltip,
  Typography,
  ToggleButton,
  Tab,
  Tabs,
  useMediaQuery,
  createTheme,
  FormGroup,
  FormControlLabel,
  TextField,
  Skeleton,
} from "@mui/material";

import { LoadingButton, TabContext, TabPanel } from "@mui/lab";
import TitleWithIcon from "./TitleWithIcon";
import { useTranslation } from "react-i18next";
import {
  AccountBox,
  Add,
  BorderColor,
  Delete,
  Settings,
} from "@mui/icons-material";

const CustomPropertyEditor = React.forwardRef((props, ref) => {
  const { settings, submitFunction, isLoading, onValueChange } = props;
  const [isAddCustomProp, setIsAddCustomProp] = React.useState(true);
  const [tempCustomProp, setTempCustomProp] = React.useState({});
  let theme = createTheme();
  const { t } = useTranslation();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [openAddEditCustomPropDialog, setOpenAddEditCustomPropDialog] =
    React.useState(false);
  const [openDeleteCustomPropDialog, setOpenDeleteCustomPropDialog] =
    React.useState(false);
  const [isAddEditCustomPropNameError, setIsAddEditCustomPropNameError] =
    React.useState(false);
  const [isAddEditCustomPropValueError, setIsAddEditCustomPropValueError] =
    React.useState(false);
  const customPropHeaders = [
    t("profile.custom_property.property"),
    t("profile.custom_property.value"),
    t("profile.custom_property.action"),
  ];
  // eslint-disable-next-line
  const [customProp, setCustomProp] = React.useState({});
  const [isDisableAddEditCustomPropSave, setIsDisableAddEditCustomPropSave] =
    React.useState(true);
  const [rowIndex, setRowIndex] = React.useState();
  const [selectedCustProp, setSelectedCustProp] = React.useState();

  const [alertDialog, setAlertDialog] = React.useState({
    title: "",
    content: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => setShowAlertDialog(false),
      },
    ],
  });
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);

  const nonCustomProp = [
    "FIRSTNAME",
    "MIDDLENAME",
    "LASTNAME",
    "FULLNAME",
    "OTHERNAME",
    "NICKNAME",
    "MOBILE",
    "PHONE_HOME",
    "PHONE_OFFICE",
    "EMAIL",
    "DEPARTMENT",
    "JOBTITLE",
    "FAX",
    "DESCRIPTION",
    "TITLE",
    "WEBPAGE",
    "ADDRESS_HOME",
    "ADDRESS_OFFICE",
    "ADDRESS_CORRESPONDING",
    "ABOUTME",
    "LANGUAGE",
  ];

  const handleOpenAddCustomPropDialog = (event) => {
    setIsAddCustomProp(true);
    setTempCustomProp({});
    setOpenAddEditCustomPropDialog(true);
    setIsAddEditCustomPropNameError(false);
    setIsAddEditCustomPropValueError(false);
    setIsDisableAddEditCustomPropSave(true);
  };
  const handleOpenEditCustomPropDialog = (row, index) => {
    setIsAddEditCustomPropNameError(false);
    setIsAddEditCustomPropValueError(false);
    setIsAddCustomProp(false);
    setRowIndex(index);
    setTempCustomProp({
      propertyName: row.toLowerCase(),
      propertyValue: customProp[row],
    });
    setOpenAddEditCustomPropDialog(true);
  };
  const handleOpenDeleteCustomPropDialog = (row) => {
    setOpenDeleteCustomPropDialog(true);
    setSelectedCustProp(row);
  };
  const handleSaveCustomProp = () => {
    let propName = tempCustomProp.propertyName.toUpperCase();
    if (nonCustomProp.includes(propName)) {
      showAlertMessage({
        title: t("profile.proxy_and_delegation.error"),
        content:
          tempCustomProp.propertyName +
          t("profile.custom_property.please_do_modification_error"),
        buttons: [
          {
            text: t("administrative_console.group_page.dialog.close"),
            action: () => {
              closeAlertMessage();
            },
          },
        ],
      });
    } else if (isAddCustomProp && customProp.hasOwnProperty(propName)) {
      showAlertMessage({
        title: "",
        content:
          tempCustomProp.propertyName +
          t("profile.custom_property.is_already_exist"),
        buttons: [
          {
            text: t("administrative_console.group_page.dialog.close"),
            action: () => {
              closeAlertMessage();
            },
          },
        ],
      });
    } else {
      let obj = isAddCustomProp ? customProp : {};
      if (isAddCustomProp) {
        obj[tempCustomProp.propertyName.toUpperCase().split(" ").join("_")] =
          tempCustomProp.propertyValue;
      } else {
        let keys = Object.keys(customProp);
        let values = Object.values(customProp);
        keys[rowIndex] = tempCustomProp.propertyName
          .toUpperCase()
          .split(" ")
          .join("_");
        values[rowIndex] = tempCustomProp.propertyValue;
        keys.map((k, i) => {
          obj[k] = values[i];
        });
      }
      setCustomProp(obj);
      setOpenAddEditCustomPropDialog(false);
    }
    if (submitFunction) {
      submitFunction();
    }
    if (onValueChange) {
      onValueChange();
    }
  };
  const handleDeleteCustomProp = (row) => {
    let obj = customProp;
    delete obj[selectedCustProp];
    setCustomProp(obj);
    setOpenDeleteCustomPropDialog(false);
    if (submitFunction) {
      submitFunction();
    }
    if (onValueChange) {
      onValueChange();
    }
  };
  const handleCloseAddEditCustomPropDialog = (event) => {
    setOpenAddEditCustomPropDialog(false);
  };
  const handleCloseDeleteCustomPropDialog = (event) => {
    setOpenDeleteCustomPropDialog(false);
  };
  const showAlertMessage = (messageObject) => {
    setAlertDialog((prev) => ({
      ...prev,
      ...messageObject,
    }));
    setShowAlertDialog(true);
  };
  const closeAlertMessage = () => {
    setShowAlertDialog(false);
  };
  const handleCustomPropertyChange = (prop) => (event) => {
    setTempCustomProp((prev) => ({ ...prev, [prop]: event.target.value }));
  };

  React.useImperativeHandle(ref, () => ({
    getCustomProperty: () => {
      return customProp;
    },
  }));

  React.useEffect(() => {
    if (settings) {
      setCustomProp(settings);
      // Object.keys(customSetting).forEach((c) => {
      //   if (!nonCustomProp.includes(c)) {
      //     setCustomProp((prevState) => ({
      //       ...prevState,
      //       [c]: customSetting[c],
      //     }));
      //   }
      // });
    }
  }, [settings]);

  React.useEffect(() => {
    if (tempCustomProp.propertyName && tempCustomProp.propertyValue) {
      setIsDisableAddEditCustomPropSave(false);
    }
  }, [tempCustomProp]);

  return (
    <Grid container xs={12}>
      <Grid container style={{ justifyContent: "right", textAlign: "right" }}>
        <Grid
          container
          style={{
            justifyContent: "right",
          }}
        >
          <IconButton color="primary" onClick={handleOpenAddCustomPropDialog}>
            <Add fontSize="small" />
          </IconButton>
        </Grid>
        <Dialog
          open={openAddEditCustomPropDialog}
          onClose={handleCloseAddEditCustomPropDialog}
        >
          <DialogTitle>
            {isAddCustomProp
              ? t("profile.custom_property.add_custom_property")
              : t("profile.custom_property.edit_custom_property")}
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column">
              <Grid container direction="row">
                <Grid
                  container
                  item
                  xs={6}
                  sx={{
                    justifyContent: "left",
                    alignContent: "left",
                    p: "10px",
                  }}
                >
                  <Typography
                    variant="p"
                    style={{
                      color: "gray",
                      justifyContent: "left",
                      alignContent: "left",
                    }}
                  >
                    {t("profile.custom_property.custom_property")}
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  xs={6}
                  sx={{
                    justifyContent: "left",
                    alignContent: "left",
                    p: "10px",
                  }}
                >
                  <Typography variant="p" style={{ color: "gray" }}>
                    {t("profile.custom_property.dialog_value")}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container direction="row">
                <Grid container item xs={6}>
                  <TextField
                    value={tempCustomProp.propertyName}
                    error={isAddEditCustomPropNameError}
                    onChange={handleCustomPropertyChange("propertyName")}
                    sx={{ p: "10px" }}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    value={tempCustomProp.propertyValue}
                    error={isAddEditCustomPropValueError}
                    onChange={handleCustomPropertyChange("propertyValue")}
                    sx={{ p: "10px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant="contained"
              disabled={isDisableAddEditCustomPropSave}
              onClick={() => handleSaveCustomProp()}
            >
              {t("profile.proxy_and_delegation.save")}
            </LoadingButton>
            <Button onClick={handleCloseAddEditCustomPropDialog} color="error">
              {t("profile.proxy_and_delegation.cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>

      <Grid container>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    padding: isSmallDevice ? "10px" : "16px",
                  },
                }}
              >
                {customPropHeaders.map((title, index) => (
                  <TableCell key={`header_cell_${index}`}>{title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Skeleton
                      variant="text"
                      sx={{ width: "50%", height: "30px" }}
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton
                      variant="text"
                      sx={{ width: "50%", height: "30px" }}
                    />
                  </TableCell>
                  <TableCell>
                    <Grid container direction="row">
                      <Grid>
                        <Skeleton
                          variant="circular"
                          sx={{ width: "25px", height: "25px" }}
                        />
                      </Grid>
                      <Grid sx={{ width: "20px" }}></Grid>
                      <Grid>
                        <Skeleton
                          variant="circular"
                          sx={{ width: "25px", height: "25px" }}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {Object.keys(customProp).length == 0 ? (
                  <TableRow>
                    <TableCell>
                      {t("profile.custom_property.no_custom_property")}
                    </TableCell>
                  </TableRow>
                ) : (
                  Object.keys(customProp).map((row, index) => {
                    if (!nonCustomProp.includes(row)) {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "& .MuiTableCell-root": {
                              padding: isSmallDevice ? "10px" : "16px",
                            },
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ wordBreak: "break-word" }}
                          >
                            {row.toLowerCase().replace(/_/g, " ")}
                          </TableCell>
                          <TableCell>{customProp[row]}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() =>
                                handleOpenEditCustomPropDialog(row, index)
                              }
                              color="primary"
                            >
                              <BorderColor fontSize="small" />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                handleOpenDeleteCustomPropDialog(row)
                              }
                              color="primary"
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </TableCell>

                          <Dialog
                            open={openDeleteCustomPropDialog}
                            onClose={handleCloseDeleteCustomPropDialog}
                          >
                            <Grid>
                              <DialogTitle>
                                {t(
                                  "profile.proxy_and_delegation.confirm_delete"
                                )}
                              </DialogTitle>
                            </Grid>
                            <Grid>
                              <DialogActions>
                                <LoadingButton
                                  variant="contained"
                                  onClick={() => handleDeleteCustomProp()}
                                >
                                  {t("profile.proxy_and_delegation.delete")}
                                </LoadingButton>
                                <Button
                                  onClick={handleCloseDeleteCustomPropDialog}
                                  color="error"
                                >
                                  {t("profile.proxy_and_delegation.cancel")}
                                </Button>
                              </DialogActions>
                            </Grid>
                          </Dialog>
                        </TableRow>
                      );
                    }
                  })
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
});

export default CustomPropertyEditor;
