import * as React from "react";
import {
  Box,
  ClickAwayListener,
  Collapse,
  Grid,
  Grow,
  IconButton,
  lighten,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popper,
  Select,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EmailIcon from "@mui/icons-material/Email";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import urlJoin from "url-join";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use-size";

import { CallApiWithContext } from "../../helpers/ApiHelper";
import { ELEMENT_STATUS, IsTerminalState } from "../../Constants";
import { resServerBaseUrl } from "../../Config";
import { useThemeColor } from "../../providers/ThemeColorProvider";
import { useProfile } from "../../providers/ProfileProvider";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import Avatar from "../../shared/FCAvatar";
import UserSelectionList from "./UserSelectionList";
import { Stack } from "@mui/system";

/* Check user timezone */
dayjs.extend(utc);
dayjs.extend(tz);
const userTimezone = dayjs.tz.guess();

const CollapsiableRow = (props) => {
  const {
    row,
    logType,
    caseState,
    setAnchorEl,
    setSelectedRow,
    defaultOpen = true,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [open, setOpen] = React.useState(defaultOpen);

  const handleMoreVertClick = (e, row) => {
    setAnchorEl(e.currentTarget);
    setSelectedRow(row);
  };

  const getPlanItemDefinitionType = (type) => {
    const DefinitionType = {
      Stage: 1,
      Task: 2,
      Milestone: 3,
      EventListener: 4,
      Case: 5,
    };
    const StyledTag = styled(Typography)(({ theme }) => ({
      borderRadius: "5px",
      padding: "2px 5px",
      textAlign: "center",
      maxWidth: "100px",
      minWidth: "70px",
    }));

    switch (type) {
      case DefinitionType.Task:
        return (
          <StyledTag
            variant="body2"
            sx={{
              bgcolor: "#85FFC8",
              color: "black",
            }}
          >
            {t("formruntime.activity_log.task")}
          </StyledTag>
        );
      case DefinitionType.Stage:
        return (
          <StyledTag
            variant="body2"
            sx={{ bgcolor: "#5BD6FF", color: "black" }}
          >
            {t("formruntime.activity_log.stage")}
          </StyledTag>
        );
      case DefinitionType.Milestone:
        return (
          <StyledTag
            variant="body2"
            sx={{ bgcolor: "#42F58A", color: "black" }}
          >
            {t("formruntime.activity_log.milestone")}
          </StyledTag>
        );
      case DefinitionType.EventListener:
        return (
          <StyledTag
            variant="body2"
            sx={{ bgcolor: "#C8FD79", color: "black" }}
          >
            {t("formruntime.activity_log.event")}
          </StyledTag>
        );
      case DefinitionType.Case:
        return (
          <StyledTag
            variant="body2"
            sx={{
              bgcolor: lighten(theme.palette.error.main, 0.75),
              color: theme.palette.common.black,
            }}
          >
            Case
          </StyledTag>
        );
      default:
        break;
    }

    return t("formruntime.activity_log.unspecified");
  };

  React.useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  const hasChildRow = React.useMemo(() => {
    return !isSmUp || (row.status === "Reassign" && row.childLogs?.length > 0);
  }, [row]);
  const hasDetailRow = React.useMemo(() => {
    return row.status === "Reassign" && row.childLogs?.length > 0;
  }, [row]);

  return (
    <>
      <TableRow
        sx={{
          "& > *:first-child": {
            borderBottom: hasChildRow ? "unset" : undefined,
          },
          "& > *:not(:first-child)": {
            borderBottom: hasDetailRow ? "unset" : undefined,
          },
        }}
      >
        <TableCell
          sx={{
            whiteSpace: {
              xs: "nowrap",
            },
            borderBottomWidth: isSmUp ? 1 : 0,
            paddingBottom: isSmUp ? null : 0.5,
            verticalAlign: isSmUp ? "center" : "bottom",
          }}
        >
          {dayjs
            .utc(row.viewLogType === 1 ? row.startDate : row.logDate)
            .tz(userTimezone)
            .format("YYYY-MM-DD HH:mm")}
        </TableCell>
        {isSmUp ? (
          <TableCell
            sx={{
              whiteSpace: {
                xs: "nowrap",
              },
            }}
          >
            {row.completeDate
              ? dayjs
                  .utc(row.completeDate)
                  .tz(userTimezone)
                  .format("YYYY-MM-DD HH:mm")
              : "--"}
          </TableCell>
        ) : null}
        {/* {logType === "trace" ? (
          <TableCell component={"th"} scope="row" rowSpan={isSmUp ? 1 : 2}>
            {getPlanItemDefinitionType(row.planItemDefinitionType)}
          </TableCell>
        ) : null} */}
        <TableCell component="th" scope="row" rowSpan={isSmUp ? 1 : 1}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            {logType === "trace" ? (
              <span>
                {getPlanItemDefinitionType(row.planItemDefinitionType)}
              </span>
            ) : null}
            {row.taskName}
          </Stack>
        </TableCell>
        {isSmUp && (
          <TableCell rowSpan={isSmUp ? 1 : 2}>
            {row.logUserID > 0 && (
              <Avatar
                slot={{
                  stringAvatar: {
                    width: "24px",
                    height: "24px",
                  },
                  photoAvatar: {
                    width: "24px",
                    height: "24px",
                  },
                }}
                userId={row.logUserID}
                userName={row.logUserName}
                withUserName
              />
            )}
          </TableCell>
        )}
        <TableCell rowSpan={isSmUp ? 1 : 2}>{row.action}</TableCell>
        {logType === "trace" ? (
          <TableCell rowSpan={isSmUp ? 1 : 2} sx={{ textAlign: "right", p: 0 }}>
            {row.action === "Send" ||
            (row.action === "Start" &&
              row.planItemDefinitionType === 2 &&
              caseState === ELEMENT_STATUS.Active) ? (
              <IconButton onClick={(e) => handleMoreVertClick(e, row)}>
                <MoreVertIcon />
              </IconButton>
            ) : row.action === "Reassign" ? (
              <IconButton onClick={() => setOpen((prev) => !prev)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            ) : null}
          </TableCell>
        ) : null}
      </TableRow>
      {!isSmUp ? (
        <TableRow
          sx={{ "& > *": { borderBottom: hasDetailRow ? "unset" : undefined } }}
        >
          <TableCell
            sx={{
              whiteSpace: {
                xs: "nowrap",
              },
              paddingTop: isSmUp ? null : 0.5,
              verticalAlign: isSmUp ? "center" : "top",
            }}
          >
            {row.completeDate
              ? dayjs
                  .utc(row.completeDate)
                  .tz(userTimezone)
                  .format("YYYY-MM-DD HH:mm")
              : "--"}
          </TableCell>
          <TableCell>
            {row.logUserID > 0 && (
              <Avatar
                slot={{
                  stringAvatar: {
                    width: "24px",
                    height: "24px",
                  },
                  photoAvatar: {
                    width: "24px",
                    height: "24px",
                  },
                }}
                userId={row.logUserID}
                userName={row.logUserName}
                withUserName
              />
            )}
          </TableCell>
        </TableRow>
      ) : null}
      {row.status === "Reassign" && row.childLogs?.length > 0 && (
        <TableRow sx={{ bgcolor: theme.palette.grey[200] }}>
          <TableCell
            colSpan={isSmUp ? 3 : 1}
            sx={{
              py: open ? 2 : 0,
              transition: `${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut} 0ms`,
            }}
          ></TableCell>
          <TableCell
            colSpan={isSmUp ? 3 : 5}
            sx={{
              py: open ? 2 : 0,
              transition: `${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut} 0ms`,
            }}
          >
            <Collapse
              sx={{ width: "100%" }}
              in={open}
              timeout={"auto"}
              unmountOnExit
            >
              <Stack
                direction={"row"}
                justifyContent={"left"}
                alignItems={"center"}
                gap={2}
              >
                <div style={{ minWidth: "130px", maxWidth: "130px" }}>
                  <Avatar
                    slot={{
                      stringAvatar: {
                        width: "24px",
                        height: "24px",
                      },
                      photoAvatar: {
                        width: "24px",
                        height: "24px",
                      },
                    }}
                    userId={row.childLogs[0].originalUserId}
                    userName={`${row.childLogs[0].originalUserName}`}
                    withUserName
                  />
                </div>
                <KeyboardDoubleArrowRightIcon />
                <Avatar
                  slot={{
                    stringAvatar: {
                      width: "24px",
                      height: "24px",
                    },
                    photoAvatar: {
                      width: "24px",
                      height: "24px",
                    },
                  }}
                  userId={row.childLogs[0].assignToUserId}
                  userName={`${row.childLogs[0].assignToUserName}`}
                  withUserName
                />
              </Stack>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const ActivityLog = React.forwardRef((props, ref) => {
  const {
    open = false,
    caseInfo,
    caseSID,
    activityLog,
    isLoadingHistoryLog,
    currentStageLog,
    isLoadingCurrentStageLog,
    showAlertMessage,
    closeAlertMessage,
    onLogTypeChange,
    onActivityLogUpdate,
    onCaseChange,
  } = props;
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const themeColorContext = useThemeColor();
  const { height: windowHeight } = useWindowSize();
  const [profile] = useProfile();

  const [openAll, setOpenAll] = React.useState(true);
  const [reassignUsers, setReassignUsers] = React.useState();
  const [isOpenActivityLog, setIsOpenActivityLog] = React.useState(open);
  const [historyLogType, setHistoryLogType] = React.useState("info");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentLogAnchorEl, setCurrentLogAnchorEl] = React.useState(null);
  const [userListAnchorEl, setUserListAnchorEl] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [currentLogSelectedRow, setCurrentLogSelectedRow] =
    React.useState(null);
  const openVertMenu = Boolean(anchorEl);
  const openCurrentLogVertMenu = Boolean(currentLogAnchorEl);
  const openUserListMenu = Boolean(userListAnchorEl);
  const loadingReassignUsers = React.useRef(false);

  const handleHistoryLogTypeChange = (e) => {
    setHistoryLogType(e.target.value);
  };
  const handleResendEmailClick = (e, row) => {
    const dTitle = e.enabledEmailApproval
      ? t("formruntime.activity_log.resent_approval_email")
      : t("formruntime.activity_log.resent_email");
    if (e.planItemSID) {
      const data = {
        PlanItemSID: e.planItemSID,
      };
      const url = urlJoin(resServerBaseUrl, "/Email/RetryEmailByPlanItemSID");
      CallApiWithContext(url, authenticationContext, JSON.stringify(data))
        .then((r) => {
          if (r.success) {
            showAlertMessage({
              title: dTitle,
              content: t("formruntime.activity_log.resent_mail_success"),
              buttons: [
                {
                  text: t("administrative_console.group_page.dialog.close"),
                  action: closeAlertMessage,
                },
              ],
            });
          } else {
            showAlertMessage({
              title: dTitle,
              content: t("formruntime.activity_log.resent_mail_failed"),
              buttons: [
                {
                  text: t("administrative_console.group_page.dialog.close"),
                  action: closeAlertMessage,
                },
              ],
            });
          }
        })
        .catch((err) => {
          showAlertMessage({
            title: dTitle,
            content: err,
            buttons: [
              {
                text: t("administrative_console.group_page.dialog.close"),
                action: closeAlertMessage,
              },
            ],
          });
        })
        .finally(() => {
          setAnchorEl(null);
        });
    } else {
      setAnchorEl(null);
      showAlertMessage({
        title: dTitle,
        content: t("formruntime.activity_log.resent_mail_failed"),
        buttons: [
          {
            text: t("administrative_console.group_page.dialog.close"),
            action: closeAlertMessage,
          },
        ],
      });
    }
  };
  const handleReassignToClick = (e, row) => {
    setUserListAnchorEl((prev) => (prev ? null : currentLogAnchorEl));
    setCurrentLogAnchorEl(null);
  };
  const handleCurrentLogMoreVertClick = (e, row) => {
    setCurrentLogAnchorEl(e.currentTarget);
    setCurrentLogSelectedRow(row);
  };
  const handleReassignUserClick = (e, user) => {
    showAlertMessage({
      title: t("formruntime.activity_log.task_reassign.title"),
      content: t("formruntime.activity_log.task_reassign.content", {
        user: user,
      }),
      buttons: [
        {
          text: t("common.confirm"),
          action: () => {
            handleCloseCurrentLogMenu();
            closeAlertMessage();

            // 500ms wait for close user list animation
            window.setTimeout(() => {
              taskReassignment(
                currentLogSelectedRow.planItemSID,
                currentLogSelectedRow.logUserUUID,
                user
              );
            }, 500);
          },
        },
        {
          text: t("common.cancel"),
          action: closeAlertMessage,
          variant: "outlined",
          color: `error`,
        },
      ],
    });
  };
  const handleCloseCurrentLogMenu = () => {
    setUserListAnchorEl(null);
    setCurrentLogAnchorEl(null);
  };
  const handleRestartPlanItemClick = (e, row) => {
    setAnchorEl(null);
    showAlertMessage({
      title: t("formruntime.activity_log.restart_task.title"),
      content: t("formruntime.activity_log.restart_task.content", { row: row }),
      buttons: [
        {
          text: t("common.yes"),
          action: () => {
            closeAlertMessage();
            restartPlanItem(row.planItemSID);
          },
        },
        {
          text: t("common.no"),
          variant: "outlined",
          color: "error",
          action: closeAlertMessage,
        },
      ],
    });
  };

  const getReassignUsers = () => {
    if (!reassignUsers && !loadingReassignUsers.current) {
      const url = urlJoin(resServerBaseUrl, "/User/GetUsersByPermissions");

      loadingReassignUsers.current = true;

      CallApiWithContext(url, authenticationContext, null)
        .then((response) => {
          if (response) {
            const userNameArr = Object.keys(response).map((key) => ({
              id: response[key].id,
              name: response[key].name,
              uuid: response[key].uuid,
            }));
            userNameArr.sort((a, b) => {
              const labelA = a.label?.toUpperCase();
              const labelB = b.label?.toUpperCase();
              if (labelA < labelB) {
                return -1;
              }
              if (labelA > labelB) {
                return 1;
              }
              return 0;
            });
            setReassignUsers(userNameArr);
          }
        })
        .catch(console.log)
        .finally(() => {
          loadingReassignUsers.current = false;
        });
    }

    return reassignUsers;
  };
  const restartPlanItem = (planItemSID) => {
    const url = urlJoin(resServerBaseUrl, "/Case/RestartPlanItem");
    const data = { planItemSid: planItemSID };

    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((res) => {
        showAlertMessage({
          title: t("formruntime.activity_log.restart_task_success.title"),
          content: t("formruntime.activity_log.restart_task_success.content"),
          buttons: [
            {
              text: t("common.ok"),
              action: () => {
                if (onCaseChange) {
                  onCaseChange();
                }
                closeAlertMessage();
              },
            },
          ],
        });
      })
      .catch((err) => {
        if (err.status === 403) {
          showAlertMessage({
            title: t("formruntime.activity_log.restart_task_fail.title"),
            content: t("formruntime.activity_log.restart_task_fail.content"),
            buttons: [
              {
                text: t("common.ok"),
                action: closeAlertMessage,
              },
            ],
          });
        }
      });
  };
  const taskReassignment = (planItemSID, originalUserUUID, assignToUser) => {
    const url = urlJoin(resServerBaseUrl, "/Case/TaskReassignment");
    const data = {
      planItemSID: planItemSID,
      originalUserUUID: originalUserUUID,
      assignToUserUUID: assignToUser.uuid,
    };

    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((res) => {
        showAlertMessage({
          title: t("formruntime.activity_log.task_reassign_success.title"),
          content: t("formruntime.activity_log.task_reassign_success.content", {
            assignToUser: assignToUser,
          }),
          buttons: [
            {
              text: t("common.ok"),
              action: () => {
                if (onCaseChange) {
                  onCaseChange();
                }
                closeAlertMessage();
              },
            },
          ],
        });
      })
      .catch((err) => {
        if (err.status === 403) {
          showAlertMessage({
            title: t("formruntime.activity_log.task_reassign_fail.title"),
            content: t("formruntime.activity_log.task_reassign_fail.content"),
            buttons: [
              {
                text: t("common.ok"),
                action: closeAlertMessage,
              },
            ],
          });
        }
      });
  };

  React.useImperativeHandle(ref, () => ({}));

  React.useEffect(() => {
    if (isOpenActivityLog && onLogTypeChange) {
      onLogTypeChange(historyLogType);
    }
  }, [windowHeight, historyLogType]);
  /* get activity log */
  React.useEffect(() => {
    setIsOpenActivityLog(open);
  }, [open]);
  React.useEffect(() => {
    setHistoryLogType("info");
  }, [caseInfo]);

  return (
    <Grid id="logContent">
      <Box
        sx={{
          display: isOpenActivityLog ? "block" : "none",
          height: "100%",
          bgcolor: `secondaryContainer.${themeColorContext[0]}`,
          p: 1,
        }}
      >
        {/* current stage log */}
        {!IsTerminalState(caseInfo?.state) ? (
          <Grid
            container
            direction={"column"}
            sx={{ p: isSmUp ? "10px" : "10px 0px" }}
          >
            <Grid xs={"auto"} textAlign={"left"}>
              <h3>{t("formruntime.gcpdf_viewer.current_stage_log")}</h3>
            </Grid>
            <Grid xs>
              <Box sx={{ overflowX: "auto", width: "100%" }}>
                <Table>
                  <TableHead
                    sx={{
                      fontWeight: "600",
                      // bgcolor: "lightgray"
                    }}
                  >
                    <TableRow>
                      <TableCell
                        rowSpan={isSmUp ? 1 : 2}
                        sx={{
                          width: isSmUp ? "290px" : "145px",
                        }}
                      >
                        {t("formruntime.activity_log.arrival_date")}
                      </TableCell>
                      <TableCell sx={{ minWidth: "180px" }}>
                        {t("formruntime.activity_log.task_name")}
                      </TableCell>
                      {isSmUp ? (
                        <TableCell>
                          {t("formruntime.activity_log.current_user")}
                        </TableCell>
                      ) : null}
                      {/* <TableCell rowSpan={isSmUp ? 1 : 2}>
                        {t("formruntime.activity_log.status")}
                      </TableCell> */}
                      {profile?.accountType === 1 && (
                        <TableCell
                          sx={{ width: "0px" }}
                          rowSpan={isSmUp ? 1 : 2}
                        ></TableCell>
                      )}
                    </TableRow>
                    {!isSmUp && (
                      <TableRow>
                        <TableCell>
                          {t("formruntime.activity_log.current_user")}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                  <TableBody>
                    {isLoadingCurrentStageLog ? (
                      <TableRow>
                        {Array.from(new Array(4)).map((_, i) => (
                          <TableCell key={`skeleton_cell_${i}`}>
                            <Skeleton
                              variant="text"
                              sx={{
                                width: "50%",
                                height: "30px",
                              }}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ) : currentStageLog?.length > 0 ? (
                      currentStageLog.map((row, rowindex) => (
                        <React.Fragment key={`current_stage_${rowindex}`}>
                          <TableRow>
                            <TableCell
                              sx={{
                                whiteSpace: {
                                  xs: "nowrap",
                                },
                              }}
                              rowSpan={isSmUp ? 1 : 2}
                            >
                              {dayjs
                                .utc(row.logDate)
                                .tz(userTimezone)
                                .format("YYYY-MM-DD HH:mm")}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.taskName}
                            </TableCell>
                            {isSmUp ? (
                              <TableCell>
                                <Avatar
                                  slot={{
                                    photoAvatar: {
                                      width: "24px",
                                      height: "24px",
                                    },
                                  }}
                                  userId={row.logUserID}
                                  userName={row.logUserName}
                                  withUserName
                                />
                              </TableCell>
                            ) : null}
                            {/* <TableCell
                              sx={{
                                whiteSpace: {
                                  xs: "nowrap",
                                },
                              }}
                              rowSpan={isSmUp ? 1 : 2}
                            >
                              {row.status}
                            </TableCell> */}
                            {profile?.accountType === 1 &&
                              row.status === "Not Yet Completed" && (
                                <TableCell
                                  sx={{ p: 0 }}
                                  rowSpan={isSmUp ? 1 : 2}
                                >
                                  <IconButton
                                    onClick={(e) =>
                                      handleCurrentLogMoreVertClick(e, row)
                                    }
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                </TableCell>
                              )}
                          </TableRow>
                          {!isSmUp && (
                            <TableRow>
                              <TableCell>
                                <Avatar
                                  slot={{
                                    photoAvatar: {
                                      width: "24px",
                                      height: "24px",
                                    },
                                  }}
                                  userId={row.logUserID}
                                  userName={row.logUserName}
                                  withUserName
                                />
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5}>
                          {t("formruntime.gcpdf_viewer.no_current_stage_log")}
                        </TableCell>
                      </TableRow>
                    )}
                    <Menu
                      open={openCurrentLogVertMenu}
                      anchorEl={currentLogAnchorEl}
                      onClose={handleCloseCurrentLogMenu}
                      slotProps={{ paper: { sx: { minWidth: "180px" } } }}
                    >
                      <MenuItem
                        onClick={(e) =>
                          handleReassignToClick(e, currentLogSelectedRow)
                        }
                        sx={{ alignItems: "center" }}
                      >
                        <Typography component={"div"} sx={{ flex: 1 }}>
                          {t("formruntime.activity_log.reassign_to")}
                        </Typography>
                        <ChevronRightIcon
                          sx={{ color: "text.secondary", opacity: "0.5" }}
                        />
                      </MenuItem>
                      {currentLogSelectedRow?.enabledEmailApproval && (
                        <MenuItem
                          onClick={() => handleCloseCurrentLogMenu()}
                          sx={{ color: theme.palette.error.main }}
                        >
                          {t("formruntime.activity_log.resent_approval_email")}
                          <ListItemIcon />
                        </MenuItem>
                      )}
                    </Menu>
                    <Popper
                      open={openUserListMenu}
                      anchorEl={userListAnchorEl}
                      role={undefined}
                      placement="left-end"
                      transition
                      sx={{ zIndex: 1300 }}
                    >
                      {({ TransitionProps, placement }) => (
                        <ClickAwayListener
                          onClickAway={handleCloseCurrentLogMenu}
                        >
                          <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: "right bottom" }}
                          >
                            <Paper
                              elevation="4"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                maxHeight: "300px",
                                overflow: "hidden",
                              }}
                            >
                              <UserSelectionList
                                users={getReassignUsers()}
                                currentUserId={currentLogSelectedRow?.logUserID}
                                onUserSelected={handleReassignUserClick}
                                onClose={handleCloseCurrentLogMenu}
                              />
                            </Paper>
                          </Grow>
                        </ClickAwayListener>
                      )}
                    </Popper>
                  </TableBody>
                </Table>
              </Box>
            </Grid>
          </Grid>
        ) : null}
        {/* activity log */}
        <Grid
          container
          direction={"column"}
          sx={{ p: isSmUp ? "10px" : "10px 0px" }}
        >
          <Grid
            container
            item
            xs={"auto"}
            textAlign={"left"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <h3 style={{ display: "inline-block" }}>
              {t("formruntime.gcpdf_viewer.history_log")}
            </h3>
            {profile?.accountType === 1 ? (
              <Select
                value={historyLogType}
                onChange={handleHistoryLogTypeChange}
                sx={{ width: "150px", height: "40px" }}
              >
                <MenuItem value="info">
                  {t("formruntime.activity_log.info")}
                </MenuItem>
                <MenuItem value="trace">
                  {t("formruntime.activity_log.trace")}
                </MenuItem>
              </Select>
            ) : null}
          </Grid>
          <Grid xs>
            <Box sx={{ overflowX: "auto", width: "100%" }}>
              <Table>
                <TableHead
                  sx={{
                    fontWeight: "600",
                    // bgcolor: "lightgray"
                  }}
                >
                  {isSmUp ? (
                    <TableRow>
                      <TableCell sx={{ width: "145px", minWidth: "145px" }}>
                        {historyLogType === "info"
                          ? t("formruntime.activity_log.arrival_date")
                          : t("formruntime.activity_log.log_date")}
                      </TableCell>
                      <TableCell sx={{ width: "145px", minWidth: "145px" }}>
                        {t("formruntime.activity_log.complete_date")}
                      </TableCell>
                      <TableCell>
                        {historyLogType === "info"
                          ? t("formruntime.activity_log.task_name")
                          : t("formruntime.activity_log.name")}
                      </TableCell>
                      <TableCell>
                        {t("formruntime.activity_log.current_user")}
                      </TableCell>
                      <TableCell sx={{ minWidth: "80px" }}>
                        {t("formruntime.activity_log.action")}
                      </TableCell>
                      {historyLogType === "trace" ? (
                        <TableCell sx={{ textAlign: "right", p: 0 }}>
                          {activityLog?.some((l) => l.childLogs?.length > 0) ? (
                            <IconButton
                              onClick={() => setOpenAll((prev) => !prev)}
                            >
                              {openAll ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          ) : null}
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell sx={{ width: "145px", minWidth: "145px" }}>
                          {historyLogType === "info"
                            ? t("formruntime.activity_log.arrival_date")
                            : t("formruntime.activity_log.log_date")}
                        </TableCell>
                        {/* {historyLogType === "trace" ? (
                          <TableCell rowSpan={2}>
                            {t("formruntime.activity_log.type")}
                          </TableCell>
                        ) : null} */}
                        <TableCell
                          sx={{
                            minWidth:
                              historyLogType === "trace" ? "240px" : "180px",
                          }}
                        >
                          {historyLogType === "info"
                            ? t("formruntime.activity_log.task_name")
                            : t("formruntime.activity_log.name")}
                        </TableCell>
                        <TableCell rowSpan={2}>
                          {t("formruntime.activity_log.action")}
                        </TableCell>
                        {historyLogType === "trace" ? (
                          <TableCell
                            rowSpan={2}
                            sx={{ textAlign: "right", p: 0 }}
                          >
                            {activityLog?.some(
                              (l) => l.childLogs?.length > 0
                            ) ? (
                              <IconButton
                                onClick={() => setOpenAll((prev) => !prev)}
                              >
                                {openAll ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            ) : null}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: "145px" }}>
                          {t("formruntime.activity_log.complete_date")}
                        </TableCell>
                        <TableCell>
                          {t("formruntime.activity_log.current_user")}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableHead>
                <TableBody>
                  {isLoadingHistoryLog ? (
                    <TableRow>
                      {Array.from(
                        new Array(
                          isSmUp ? 5 : 4 + historyLogType === "info" ? 0 : 1
                        )
                      ).map((_, index) => (
                        <TableCell key={`history_col_${index}`}>
                          <Skeleton
                            variant="text"
                            sx={{
                              width: "50%",
                              height: "30px",
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ) : activityLog?.length > 0 ? (
                    activityLog
                      .filter(
                        (l) => historyLogType !== "info" || l.viewLogType === 1
                      )
                      .map((row, rowindex) => (
                        <React.Fragment key={`history_row_${rowindex}`}>
                          <CollapsiableRow
                            key={`hisotory_row_${rowindex}`}
                            row={row}
                            logType={historyLogType}
                            caseState={caseInfo?.state}
                            setAnchorEl={setAnchorEl}
                            setSelectedRow={setSelectedRow}
                            defaultOpen={openAll}
                          />
                        </React.Fragment>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        {t("formruntime.gcpdf_viewer.no_history_log")}
                      </TableCell>
                    </TableRow>
                  )}
                  <Menu
                    open={openVertMenu}
                    anchorEl={anchorEl}
                    slotProps={{ paper: { style: { minWidth: "150px" } } }}
                    sx={{
                      "& .MuiTypography-body1": {
                        fontSize: "16px",
                      },
                      "& .MuiMenuItem-root > .MuiListItemIcon-root": {
                        minWidth: "auto",
                      },
                    }}
                    onClose={() => setAnchorEl(null)}
                  >
                    {selectedRow?.action === "Send" ? (
                      <MenuItem
                        onClick={(e) => handleResendEmailClick(e, selectedRow)}
                        sx={{ color: theme.palette.error.main }}
                      >
                        <ListItemIcon>
                          <EmailIcon />
                        </ListItemIcon>
                        <ListItemText>
                          {t("formruntime.activity_log.resent_email")}
                        </ListItemText>
                      </MenuItem>
                    ) : (
                      selectedRow?.action === "Start" && (
                        <>
                          <MenuItem
                            onClick={(e) =>
                              handleRestartPlanItemClick(e, selectedRow)
                            }
                          >
                            <ListItemIcon>
                              <RestartAltIcon />
                            </ListItemIcon>
                            <ListItemText>
                              {t("formruntime.activity_log.restart")}
                            </ListItemText>
                          </MenuItem>
                          {selectedRow?.enabledEmailApproval && (
                            <MenuItem
                              onClick={() => {
                                handleResendEmailClick(selectedRow);
                                setAnchorEl(null);
                              }}
                              sx={{ color: theme.palette.error.main }}
                            >
                              <ListItemIcon>
                                <MarkEmailReadIcon />
                              </ListItemIcon>
                              <ListItemText>
                                {t(
                                  "formruntime.activity_log.resent_approval_email"
                                )}
                              </ListItemText>
                            </MenuItem>
                          )}
                        </>
                      )
                    )}
                  </Menu>
                </TableBody>
              </Table>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
});

export default ActivityLog;
